import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../../../config/index";

const BASE_URL = SERVER_URL;

const initialState = {
  total: 0,
  HelpSupportService: [],
  details: {},
};
export const HelpSupportServiceListingsReducer = createAsyncThunk(
  "HelpSupportServiceListingsReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/admin/getAllRequest`, {
        params: {
          search: obj.search,
          page: obj.page,
          limit: obj.limit,
        },
        headers: {
          token: token,
        },
      });
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const HelpSupportServiceDetailReducer = createAsyncThunk(
  "HelpSupportServiceDetailReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/admin/getRequestDetails`, {
        params: {
          request_id: obj.id,
        },
        headers: {
          token: token,
        },
      });
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);
export const HelpSupportServicelistingSlice = createSlice({
  name: "HelpSupportServicelistings",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [HelpSupportServiceListingsReducer.fulfilled]: (state, action) => {
      // console.log(action)
      state.HelpSupportService = action?.payload?.data?.request;
      state.total = action?.payload?.data?.total;
    },
    [HelpSupportServiceDetailReducer.fulfilled]: (state, action) => {
      //console.log(action)
      state.details = action?.payload?.data;
    },
  },
});

export const { reducerName } = HelpSupportServicelistingSlice.actions;

export default HelpSupportServicelistingSlice.reducer;
