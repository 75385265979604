import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "../../component/ConfirmationModal";
import { BookingDetailReducer, RFDBookingReducer } from "./BookingSlice";
import moment from "moment";
import currencies from "currency-formatter";
import { togglesuccess } from "../login/LoginSlice";

function BookingDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const success = useSelector((store) => store.login.success);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleShow = () => {
    setShow(!show);
  };

  const detail = useSelector((store) => store.Booking.details);
  //console.log(detail);

  const getdetail = useCallback(() => {
    dispatch(
      BookingDetailReducer({
        id: id,
      })
    );
  }, [dispatch, id]);

  const confirmRFD = () => {
    dispatch(RFDBookingReducer({ deal_id: id, status: "1" })).then((res) => {
      if (res?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(res?.payload?.message);
        //getlist();
        setTimeout(() => {
          navigate("/bookings", {
            replace: true,
          });
          setError("");
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(res?.payload?.message);
        //getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h2 className="head-title">Booking Detail</h2>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <ul className="nav nav-tabs group-nav" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#vehicle-details"
                  >
                    vehicle details
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#buyer-details"
                  >
                    buyer details
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#seller-details"
                  >
                    seller details
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-12">
              <div className="tab-content mt-4">
                <div id="vehicle-details" className="tab-pane active">
                  <h2 className="mb-2">Regn Details</h2>
                  <div className="view-detail mt-4 pt-3 text-capitalize">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>reg number</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.regn_number ||
                            "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>vehicle location city</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.regnCityDetails
                            ?.city_name || "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>owner serial</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.owner_serial
                            ? detail?.deal_details?.car_details?.owner_serial +
                              `st owner`
                            : "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>comprehensive insurance validity</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details
                            ?.comprehensive_insurance_validity ===
                            "0000-00-00" &&
                          detail?.deal_details?.car_details
                            ?.comprehensive_insurance_validity === ""
                            ? moment(
                                detail?.deal_details?.car_details
                                  ?.comprehensive_insurance_validity
                              ).format("DD MMM YYYY")
                            : "Not Valid"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>number plate</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.number_plate ||
                            "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>regd owners name</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.regd_owner_name ||
                            "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>my relation with title owner of this car?</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details
                            ?.relation_with_title_owner || "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>loan HP exists on RC</h5>
                        <p className="mb-0">
                          <img
                            src={
                              detail?.deal_details?.car_details
                                ?.loan_hp_status === "yes"
                                ? "assets/images/yes.png"
                                : detail?.deal_details?.car_details
                                    ?.loan_hp_status === "no"
                                ? "assets/images/no.png"
                                : "assets/images/na-img.png"
                            }
                            width="20px"
                            alt=""
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <h2 className="mb-2">Modal Details</h2>
                  <div className="view-detail mt-4 pt-3 text-capitalize">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>month/year of regn</h5>
                        <p className="mb-0">
                          {moment(
                            detail?.deal_details?.car_details?.reg_year,
                            "MM/YYYY"
                          ).format("MMM YYYY") || "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>month/year of mfg</h5>
                        <p className="mb-0">
                          {moment(
                            detail?.deal_details?.car_details?.mfg_year,
                            "MM/YYYY"
                          ).format("MMM YYYY") || "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>OEM brand</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.brandDetails
                            ?.name || "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>modal</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.modelDetails
                            ?.name || "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>variant</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.variantDetails
                            ?.name || "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>fuel </h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.fuel_type || "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>transmission type</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.transmission ||
                            "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>colour</h5>
                        <p className="mb-0">
                          <span
                            title={
                              detail?.deal_details?.car_details?.colourDetails
                                ?.name
                                ? detail?.deal_details?.car_details
                                    ?.colourDetails?.name
                                : "White"
                            }
                            style={{
                              backgroundColor: `${
                                detail?.deal_details?.car_details?.colourDetails
                                  ?.code
                                  ? detail?.deal_details?.car_details
                                      ?.colourDetails?.code
                                  : "#fff"
                              }`,
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              display: " block",
                              boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <h2 className="mb-2">Listing Details</h2>
                  <div className="view-detail mt-4 pt-3 text-capitalize">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>price</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.price_type ||
                            "NA"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <h2 className="mb-2">Service History</h2>
                  <div className="view-detail mt-4 pt-3 text-capitalize">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>km reading</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.km_meter_reading
                            ? detail?.deal_details?.car_details
                                ?.km_meter_reading + `Km`
                            : "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>kilometer reading genuine?</h5>
                        <p className="mb-0">
                          <img
                            src={
                              detail?.deal_details?.car_details
                                ?.is_reading_genuine === "yes"
                                ? "assets/images/yes.png"
                                : detail?.deal_details?.car_details
                                    ?.is_reading_genuine === "no"
                                ? "assets/images/no.png"
                                : "assets/images/na-img.png"
                            }
                            width="20px"
                            alt=""
                          />
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>last service done at KM</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details
                            ?.service_done_at_what_km
                            ? detail?.deal_details?.car_details
                                ?.service_done_at_what_km + `Km`
                            : "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>accidental details</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details
                            ?.accidental_status || "NA"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <h2 className="mb-4 theme-color">
                    Vehicle UID:{" "}
                    {detail?.deal_details?.car_details?.vehicle_UID || "NA"}
                  </h2>
                </div>
                <div id="buyer-details" className="tab-pane fade">
                  <h2 className="mb-4 theme-color">Buyer Details</h2>
                  <h2 className="mb-2">Contact Details</h2>
                  <div className="view-detail mt-4 pt-3 text-capitalize">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>mobile no.</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.buyer_details?.mobile
                            ? `${detail?.deal_details?.buyer_details?.countryCode} ${detail?.deal_details?.buyer_details?.mobile}`
                            : "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>personal email ID</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.buyer_details
                            ?.personal_email || "NA"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <h2 className="mb-2">KYC Detail</h2>
                  <div className="view-detail mt-4 pt-3 text-capitalize">
                    {detail?.deal_details?.buyer_details?.userKYCDetails?.map(
                      (item, i) => (
                        <div className="row" key={i}>
                          <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <h5>current resi address with landmark</h5>
                            <p className="mb-0">
                              <img
                                src={
                                  item?.current_resi_address_file
                                    ? item?.current_resi_address_file
                                    : "assets/images/employe.png"
                                }
                                alt=""
                                className="img-fluid"
                                width="50px"
                              />
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <h5>vehicle registered in the name of</h5>
                            <p className="mb-0">
                              {detail?.deal_details?.buyer_details?.first_name
                                ? `${detail?.deal_details?.buyer_details?.first_name} ${detail?.deal_details?.buyer_details?.last_name}`
                                : "NA"}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <h5>PAN no.</h5>
                            <p className="mb-0">
                              <img
                                src={
                                  item?.pan_no_file
                                    ? item?.pan_no_file
                                    : "assets/images/employe.png"
                                }
                                alt=""
                                className="img-fluid"
                                width="50px"
                              />
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <h5>adhaar no.</h5>
                            <p className="mb-0">
                              <img
                                src={
                                  item?.adhaar_no_file
                                    ? item?.adhaar_no_file
                                    : "assets/images/employe.png"
                                }
                                alt=""
                                className="img-fluid"
                                width="50px"
                              />
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <h5>signature proof</h5>
                            <p className="mb-0">
                              <img
                                src={
                                  item?.signature_proof_file
                                    ? item?.signature_proof_file
                                    : "assets/images/employe.png"
                                }
                                alt=""
                                className="img-fluid"
                                width="50px"
                              />
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div id="seller-details" className="tab-pane fade">
                  <h2 className="mb-4 theme-color">Seller Details</h2>
                  <h2 className="mb-2">Contact Details</h2>
                  <div className="view-detail mt-4 pt-3 text-capitalize">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>mobile no.</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.userDetails
                            ?.mobile
                            ? `${detail?.deal_details?.car_details?.userDetails?.countryCode} ${detail?.deal_details?.car_details?.userDetails?.mobile}`
                            : "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <h5>personal email ID</h5>
                        <p className="mb-0">
                          {detail?.deal_details?.car_details?.userDetails
                            ?.personal_email || "NA"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <h2 className="mb-2">KYC Detail</h2>
                  <div className="view-detail mt-4 pt-3 text-capitalize">
                    {detail?.deal_details?.car_details?.userDetails?.userKYCDetails?.map(
                      (item, i) => (
                        <div className="row" key={i}>
                          <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <h5>current resi address with landmark</h5>
                            <p className="mb-0">
                              <img
                                src={
                                  item?.current_resi_address_file
                                    ? item?.current_resi_address_file
                                    : "assets/images/employe.png"
                                }
                                alt=""
                                className="img-fluid"
                                width="50px"
                              />
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <h5>vehicle registered in the name of</h5>
                            <p className="mb-0">
                              {detail?.deal_details?.car_details?.userDetails
                                ?.first_name
                                ? `${detail?.deal_details?.car_details?.userDetails?.first_name} ${detail?.deal_details?.car_details?.userDetails?.last_name}`
                                : "NA"}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <h5>PAN no.</h5>
                            <p className="mb-0">
                              <img
                                src={
                                  item?.pan_no_file
                                    ? item?.pan_no_file
                                    : "assets/images/employe.png"
                                }
                                alt=""
                                className="img-fluid"
                                width="50px"
                              />
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <h5>adhaar no.</h5>
                            <p className="mb-0">
                              <img
                                src={
                                  item?.adhaar_no_file
                                    ? item?.adhaar_no_file
                                    : "assets/images/employe.png"
                                }
                                alt=""
                                className="img-fluid"
                                width="50px"
                              />
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                            <h5>signature proof</h5>
                            <p className="mb-0">
                              <img
                                src={
                                  item?.signature_proof_file
                                    ? item?.signature_proof_file
                                    : "assets/images/employe.png"
                                }
                                alt=""
                                className="img-fluid"
                                width="50px"
                              />
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <h2 className="mb-4">Vehicle PBI/PDI Checklist</h2>
              <ul
                className="nav nav-tabs group-nav loosePhysical"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#loose-items-docx"
                  >
                    loose items & docx
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#physical-appraisal-details"
                  >
                    physical appraisal details
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-12">
              <div className="tab-content mt-4">
                <div id="loose-items-docx" className="tab-pane active">
                  <div className="bg-white mt-4 p-3 rounded text-capitalize">
                    <h2>Items</h2>
                    <div className="table-responsive mb-3">
                      <table className="table storetable">
                        <thead className="bgColorthead">
                          <tr>
                            <th scope="col">check points</th>
                            <th scope="col">listing day</th>
                            <th scope="col">booking day</th>
                            <th scope="col">delivery day</th>
                          </tr>
                        </thead>
                        <tbody className="tbodystyle">
                          <tr>
                            <td>original RC available</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.original_rc_available_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.original_rc_available_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.original_rc_available_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.original_rc_available_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.original_rc_available_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.original_rc_available_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.original_rc_available_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.original_rc_available_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.original_rc_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.original_rc_available_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.original_rc_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.original_rc_available_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>valid insurance available</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.valid_insurance_available_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.valid_insurance_available_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.valid_insurance_available_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.valid_insurance_available_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.valid_insurance_available_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.valid_insurance_available_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.valid_insurance_available_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.valid_insurance_available_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.valid_insurance_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.valid_insurance_available_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.valid_insurance_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.valid_insurance_available_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>bank NOC available</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.bank_noc_available_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.bank_noc_available_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.bank_noc_available_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.bank_noc_available_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.bank_noc_available_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.bank_noc_available_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.bank_noc_available_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.bank_noc_available_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.bank_noc_available_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.bank_noc_available_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.bank_noc_available_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.bank_noc_available_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>extended warranty</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.extended_warrenty_valid_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.extended_warrenty_valid_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.extended_warrenty_valid_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.extended_warrenty_valid_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.extended_warrenty_valid_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.extended_warrenty_valid_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.extended_warrenty_valid_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.extended_warrenty_valid_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.extended_warrenty_valid_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.extended_warrenty_valid_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.extended_warrenty_valid_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.extended_warrenty_valid_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>second key available</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.second_key_available_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.second_key_available_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.second_key_available_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.second_key_available_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.second_key_available_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.second_key_available_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.second_key_available_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.second_key_available_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.second_key_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.second_key_available_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.second_key_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.second_key_available_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>remote for central locking available</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.remote_for_central_locking_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.remote_for_central_locking_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.remote_for_central_locking_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.remote_for_central_locking_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.remote_for_central_locking_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.remote_for_central_locking_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.remote_for_central_locking_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.remote_for_central_locking_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.remote_for_central_locking_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.remote_for_central_locking_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.remote_for_central_locking_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.remote_for_central_locking_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>spare type available</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.spare_type_available_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.spare_type_available_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.spare_type_available_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.spare_type_available_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.spare_type_available_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.spare_type_available_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.spare_type_available_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.spare_type_available_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.spare_type_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.spare_type_available_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.spare_type_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.spare_type_available_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>jack & tool kit available</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.jack_and_tool_kit_available_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.jack_and_tool_kit_available_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.jack_and_tool_kit_available_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.jack_and_tool_kit_available_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.jack_and_tool_kit_available_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.jack_and_tool_kit_available_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.jack_and_tool_kit_available_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.jack_and_tool_kit_available_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.jack_and_tool_kit_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.jack_and_tool_kit_available_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.jack_and_tool_kit_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.jack_and_tool_kit_available_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>stereo remote</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.stereo_remote_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.stereo_remote_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.stereo_remote_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.stereo_remote_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.stereo_remote_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.stereo_remote_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.stereo_remote_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.stereo_remote_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.stereo_remote_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.stereo_remote_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.stereo_remote_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.stereo_remote_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>antenna</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.antenna_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.antenna_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.antenna_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.antenna_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.antenna_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.antenna_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.antenna_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.antenna_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.antenna_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.antenna_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.antenna_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.antenna_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>amplifier</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.amplifier_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.amplifier_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.amplifier_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.amplifier_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.amplifier_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.amplifier_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.amplifier_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.amplifier_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.amplifier_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.loose_items
                                        ?.amplifier_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.loose_items
                                    ?.amplifier_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.loose_items
                                        ?.amplifier_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div id="physical-appraisal-details" className="tab-pane">
                  <div className="bg-white mt-4 p-3 rounded text-capitalize">
                    <h2>Exterior</h2>
                    <div className="table-responsive mb-3">
                      <table className="table storetable">
                        <thead className="bgColorthead">
                          <tr>
                            <th scope="col">check points</th>
                            <th scope="col">listing day</th>
                            <th scope="col">booking day</th>
                            <th scope="col">delivery day</th>
                          </tr>
                        </thead>
                        <tbody className="tbodystyle">
                          <tr>
                            <td>all tyre same brand</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_tyre_same_brand_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_tyre_same_brand_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_tyre_same_brand_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_tyre_same_brand_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_tyre_same_brand_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_tyre_same_brand_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_tyre_same_brand_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_tyre_same_brand_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_tyre_same_brand_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_tyre_same_brand_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_tyre_same_brand_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_tyre_same_brand_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>chasis / engine tracing match with RC</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_tracing_match_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_tracing_match_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_tracing_match_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_tracing_match_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_tracing_match_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_tracing_match_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_tracing_match_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_tracing_match_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_tracing_match_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_tracing_match_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_tracing_match_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_tracing_match_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>dent/paint Required</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.paint_required_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.paint_required_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.paint_required_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.paint_required_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.paint_required_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.paint_required_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.paint_required_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.paint_required_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.paint_required_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.paint_required_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.paint_required_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.paint_required_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>rusting on any panel</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.rusting_on_any_panel_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.rusting_on_any_panel_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.rusting_on_any_panel_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.rusting_on_any_panel_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.rusting_on_any_panel_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.rusting_on_any_panel_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.rusting_on_any_panel_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.rusting_on_any_panel_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.rusting_on_any_panel_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.rusting_on_any_panel_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.rusting_on_any_panel_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.rusting_on_any_panel_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>accidental history</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.accidental_history_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.accidental_history_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.accidental_history_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.accidental_history_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.accidental_history_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.accidental_history_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.accidental_history_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.accidental_history_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.accidental_history_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.accidental_history_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.accidental_history_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.accidental_history_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h2>interior/cockpit</h2>
                    <div className="table-responsive mb-3">
                      <table className="table storetable">
                        <thead className="bgColorthead">
                          <tr>
                            <th scope="col">check points</th>
                            <th scope="col">listing day</th>
                            <th scope="col">booking day</th>
                            <th scope="col">delivery day</th>
                          </tr>
                        </thead>
                        <tbody className="tbodystyle">
                          <tr>
                            <td>AC+heater working fine</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.ac_heater_working_fine_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.ac_heater_working_fine_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.ac_heater_working_fine_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.ac_heater_working_fine_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.ac_heater_working_fine_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.ac_heater_working_fine_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.ac_heater_working_fine_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.ac_heater_working_fine_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.ac_heater_working_fine_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.ac_heater_working_fine_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.ac_heater_working_fine_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.ac_heater_working_fine_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>seat covers available</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.seat_covers_available_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.seat_covers_available_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.seat_covers_available_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.seat_covers_available_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.seat_covers_available_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.seat_covers_available_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.seat_covers_available_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.seat_covers_available_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.seat_covers_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.seat_covers_available_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.seat_covers_available_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.seat_covers_available_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>airbag ever opened</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.airebag_ever_opened_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.airebag_ever_opened_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.airebag_ever_opened_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.airebag_ever_opened_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.airebag_ever_opened_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.airebag_ever_opened_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.airebag_ever_opened_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.airebag_ever_opened_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.airebag_ever_opened_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.airebag_ever_opened_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.airebag_ever_opened_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.airebag_ever_opened_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Dashboard warranty lights any</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.dashboard_warning_lights_any_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.dashboard_warning_lights_any_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.dashboard_warning_lights_any_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.dashboard_warning_lights_any_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.dashboard_warning_lights_any_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.dashboard_warning_lights_any_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.dashboard_warning_lights_any_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.dashboard_warning_lights_any_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.dashboard_warning_lights_any_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.dashboard_warning_lights_any_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.dashboard_warning_lights_any_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.dashboard_warning_lights_any_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Stereo + Speaker Working Fine</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>horn working fine</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.horn_working_fine_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.horn_working_fine_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.horn_working_fine_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.horn_working_fine_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.horn_working_fine_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.horn_working_fine_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.horn_working_fine_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.horn_working_fine_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.horn_working_fine_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.horn_working_fine_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.horn_working_fine_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.horn_working_fine_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h2>Electrical/Electronic</h2>
                    <div className="table-responsive mb-3">
                      <table className="table storetable">
                        <thead className="bgColorthead">
                          <tr>
                            <th scope="col">check points</th>
                            <th scope="col">listing day</th>
                            <th scope="col">booking day</th>
                            <th scope="col">delivery day</th>
                          </tr>
                        </thead>
                        <tbody className="tbodystyle">
                          <tr>
                            <td>power windows working fine</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.power_windows_working_fine_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.power_windows_working_fine_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.power_windows_working_fine_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.power_windows_working_fine_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.power_windows_working_fine_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.power_windows_working_fine_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.power_windows_working_fine_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.power_windows_working_fine_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.power_windows_working_fine_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.power_windows_working_fine_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.power_windows_working_fine_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.power_windows_working_fine_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>ORVM working fine</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.orvm_working_fine_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.orvm_working_fine_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.orvm_working_fine_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.orvm_working_fine_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.orvm_working_fine_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.orvm_working_fine_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.orvm_working_fine_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.orvm_working_fine_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.orvm_working_fine_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.orvm_working_fine_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.orvm_working_fine_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.orvm_working_fine_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>wipers working fine</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wipers_working_fine_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wipers_working_fine_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wipers_working_fine_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wipers_working_fine_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wipers_working_fine_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wipers_working_fine_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wipers_working_fine_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wipers_working_fine_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wipers_working_fine_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wipers_working_fine_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wipers_working_fine_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wipers_working_fine_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Battery last changed date</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.last_date_of_battery_changed_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.last_date_of_battery_changed_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.last_date_of_battery_changed_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.last_date_of_battery_changed_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.last_date_of_battery_changed_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.last_date_of_battery_changed_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.last_date_of_battery_changed_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.last_date_of_battery_changed_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.last_date_of_battery_changed_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.last_date_of_battery_changed_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.last_date_of_battery_changed_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.last_date_of_battery_changed_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>all lights working fine</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_lights_working_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_lights_working_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_lights_working_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_lights_working_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_lights_working_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_lights_working_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_lights_working_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_lights_working_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_lights_working_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_lights_working_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.all_lights_working_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.all_lights_working_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>stereo + speaker working fine</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.stereo_speaker_working_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.stereo_speaker_working_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h2>Engine/Gear Box Related</h2>
                    <div className="table-responsive mb-3">
                      <table className="table storetable">
                        <thead className="bgColorthead">
                          <tr>
                            <th scope="col">check points</th>
                            <th scope="col">listing day</th>
                            <th scope="col">booking day</th>
                            <th scope="col">delivery day</th>
                          </tr>
                        </thead>
                        <tbody className="tbodystyle">
                          <tr>
                            <td>abnormal noise any</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_noise_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_noise_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_noise_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_noise_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_noise_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_noise_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_noise_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_noise_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_noise_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_noise_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_noise_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_noise_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>engine repaired ever</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_repaired_ever_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_repaired_ever_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_repaired_ever_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_repaired_ever_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_repaired_ever_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_repaired_ever_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_repaired_ever_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_repaired_ever_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_repaired_ever_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_repaired_ever_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_repaired_ever_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_repaired_ever_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>gear shift smooth</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.gear_shift_smooth_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.gear_shift_smooth_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.gear_shift_smooth_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.gear_shift_smooth_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.gear_shift_smooth_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.gear_shift_smooth_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.gear_shift_smooth_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.gear_shift_smooth_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.gear_shift_smooth_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.gear_shift_smooth_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.gear_shift_smooth_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.gear_shift_smooth_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>any engine oil leakage</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_leakage_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_leakage_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_leakage_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_leakage_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_leakage_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_leakage_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_leakage_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_leakage_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_leakage_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_leakage_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_leakage_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_leakage_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>any oil back throw</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_back_throw_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_back_throw_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_back_throw_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_back_throw_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_back_throw_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_back_throw_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_back_throw_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_back_throw_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_back_throw_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_back_throw_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.any_oil_back_throw_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.any_oil_back_throw_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>exhaust smoke</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.exhaust_smoke_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.exhaust_smoke_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.exhaust_smoke_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.exhaust_smoke_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.exhaust_smoke_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.exhaust_smoke_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.exhaust_smoke_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.exhaust_smoke_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.exhaust_smoke_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.exhaust_smoke_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.exhaust_smoke_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.exhaust_smoke_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h2>Road Test Result</h2>
                    <div className="table-responsive mb-3">
                      <table className="table storetable">
                        <thead className="bgColorthead">
                          <tr>
                            <th scope="col">check points</th>
                            <th scope="col">listing day</th>
                            <th scope="col">booking day</th>
                            <th scope="col">delivery day</th>
                          </tr>
                        </thead>
                        <tbody className="tbodystyle">
                          <tr>
                            <td>Starting Noise/delay</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.starting_noise_delay_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.starting_noise_delay_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.starting_noise_delay_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.starting_noise_delay_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.starting_noise_delay_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.starting_noise_delay_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.starting_noise_delay_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.starting_noise_delay_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.starting_noise_delay_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.starting_noise_delay_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.starting_noise_delay_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.starting_noise_delay_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>engine missing</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_missing_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_missing_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_missing_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_missing_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_missing_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_missing_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_missing_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_missing_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_missing_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_missing_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.engine_missing_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.engine_missing_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>fanBelt noise</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.fanbelt_noise_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.fanbelt_noise_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.fanbelt_noise_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.fanbelt_noise_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.fanbelt_noise_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.fanbelt_noise_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.fanbelt_noise_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.fanbelt_noise_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.fanbelt_noise_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.fanbelt_noise_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.fanbelt_noise_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.fanbelt_noise_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>suspension noise</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.spension_noise_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.spension_noise_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.spension_noise_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.spension_noise_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.suspension_noise_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.suspension_noise_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.suspension_noise_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.suspension_noise_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.suspension_noise_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.suspension_noise_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.suspension_noise_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.suspension_noise_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          {/* <tr>
                            <td>steering noise</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.steering_noise_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.steering_noise_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.steering_noise_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.steering_noise_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.steering_noise_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.steering_noise_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.steering_noise_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.steering_noise_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.steering_noise_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.steering_noise_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.steering_noise_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.steering_noise_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr> */}
                          <tr>
                            <td>wheel noise / wobbling</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wheel_noise_wobbiling_listing_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wheel_noise_wobbiling_listing_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wheel_noise_wobbiling_listing_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wheel_noise_wobbiling_listing_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wheel_noise_wobbiling_booking_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wheel_noise_wobbiling_booking_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wheel_noise_wobbiling_booking_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wheel_noise_wobbiling_booking_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wheel_noise_wobbiling_delivery_day ===
                                  "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wheel_noise_wobbiling_delivery_day ===
                                      "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.wheel_noise_wobbiling_delivery_day ===
                                  "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.wheel_noise_wobbiling_delivery_day ===
                                      "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>clutch spongy</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.clutch_spongy_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.clutch_spongy_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.clutch_spongy_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.clutch_spongy_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.clutch_spongy_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.clutch_spongy_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.clutch_spongy_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.clutch_spongy_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.clutch_spongy_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.clutch_spongy_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.clutch_spongy_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.clutch_spongy_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>breaking fine</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.breaking_fine_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.breaking_fine_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.breaking_fine_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.breaking_fine_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.breaking_fine_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.breaking_fine_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.breaking_fine_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.breaking_fine_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.breaking_fine_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.breaking_fine_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.breaking_fine_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.breaking_fine_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>abnormal heating</td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_heating_listing_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_heating_listing_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_heating_listing_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_heating_listing_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_heating_booking_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_heating_booking_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_heating_booking_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_heating_booking_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_heating_delivery_day === "yes"
                                    ? "assets/images/yes.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_heating_delivery_day === "no"
                                    ? "assets/images/greyYes.png"
                                    : "assets/images/greyYes.png"
                                }
                                width="20px"
                                alt=""
                              />{" "}
                              <img
                                src={
                                  detail?.deal_details?.physical_items
                                    ?.abnormal_heating_delivery_day === "yes"
                                    ? "assets/images/greyNo.png"
                                    : detail?.deal_details?.physical_items
                                        ?.abnormal_heating_delivery_day === "no"
                                    ? "assets/images/no.png"
                                    : "assets/images/greyNo.png"
                                }
                                width="20px"
                                alt=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <h2 className="my-3">Deal Structure</h2>

              <div className="view-detail mt-4 pt-3 text-capitalize">
                <h2 className="mb-3 theme-color ">Deal Condition</h2>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>payment condition</h5>
                    <p className="mb-0">
                      {detail?.deal_details?.payment_condition || "NA"}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>loan Required</h5>
                    <p className="mb-0">
                      {detail?.deal_details?.loan_required || "NA"}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>loan processed by</h5>
                    <p className="mb-0">
                      {detail?.deal_details?.loan_required === "yes"
                        ? "P2P Carz"
                        : detail?.deal_details?.loan_processed_by}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>lock the booking</h5>
                    <p className="mb-0">
                      {detail?.deal_details?.lock_the_booking || "NA"}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>previous HP on RC</h5>
                    <p className="mb-0">
                      {detail?.deal_details?.previous_hp_on_rc || "NA"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="view-detail mt-4 pt-3 text-capitalize">
                <h2 className="mb-3 theme-color ">Deal contents</h2>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>comprehensive insurance</h5>
                    <p className="mb-0">
                      {detail?.deal_details?.comprehensive_insurance || "NA"}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>ins will be transferred to buyed</h5>
                    <p className="mb-0">
                      {detail?.deal_details?.ins_will_be_transferred_to_buyer ||
                        "NA"}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>RC transfer to be done by</h5>
                    <p className="mb-0">
                      {detail?.deal_details?.rc_transfer_to_be_done_by ||
                        "P2P Carz"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="view-detail mt-4 pt-3 text-capitalize">
                <h2 className="mb-3 theme-color ">payment breakup</h2>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>A. listed price of the vehicle </h5>
                    <p className="mb-0">
                      {currencies.format(
                        detail?.deal_details?.car_details?.price,
                        {
                          code: "INR",
                          symbol: "₹ ",
                        }
                      )}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>B. negotiated cost of vehicle</h5>
                    <p className="mb-0">
                      {currencies.format(
                        detail?.deal_details?.negotiated_cost_of_vehicle,
                        {
                          code: "INR",
                          symbol: "₹ ",
                        }
                      )}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>C. success fee (1% of A)</h5>
                    <p className="mb-0">
                      {currencies.format(detail?.deal_details?.success_fee, {
                        code: "INR",
                        symbol: "₹ ",
                      })}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>total for buyer to pay (B) + (C)</h5>
                    <p className="mb-0">
                      {/* {detail?.deal_details?.negotiated_cost_of_vehicle &&
                      detail?.deal_details?.success_fee
                        ? parseFloat(
                            detail?.deal_details?.negotiated_cost_of_vehicle
                          ) + parseInt(detail?.deal_details?.success_fee)
                        : "NA"} */}

                      {currencies.format(
                        parseFloat(
                          detail?.deal_details?.negotiated_cost_of_vehicle
                        ) + parseInt(detail?.deal_details?.success_fee),
                        {
                          code: "INR",
                          symbol: "₹ ",
                        }
                      ) || "NA"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="view-detail mt-4 pt-3 text-capitalize">
                <h2 className="mb-3 theme-color ">payment steps</h2>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                    <h5>token amount</h5>
                    <p className="mb-0">
                      {currencies.format(detail?.deal_details?.tokenAmount, {
                        code: "INR",
                        symbol: "₹ ",
                      }) || "NA"}
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                    <h5>date & reference No.</h5>
                    <p className="mb-0">
                      {moment(
                        detail?.payment_data?.createdAt,
                        "YYYY-MM-DD"
                      ).format("DD MMM YYYY") || "NA"}{" "}
                      | {detail?.payment_data?.transactionId || "NA"}
                    </p>
                  </div>
                </div>
                {detail?.deal_details?.payment_steps?.map((payStep) => {
                  return (
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                        <h5>remaining amount</h5>
                        <p className="mb-0">
                          {currencies.format(payStep?.amount, {
                            code: "INR",
                            symbol: "₹ ",
                          }) || "NA"}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                        <h5>date & reference No.</h5>
                        <p className="mb-0">
                          {moment(payStep?.date, "DD/MM/YYYY").format(
                            "DD MMM YYYY"
                          ) || "NA"}{" "}
                          | {payStep?.reference_no || "NA"}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="view-detail mt-4 pt-3 text-capitalize">
                <h2 className="mb-3 theme-color ">Doc Sing</h2>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>buyer sign</h5>
                    <p className="mb-0">
                      <img
                        src={
                          detail?.deal_details?.buyer_signature
                            ? detail?.deal_details?.buyer_signature
                            : "assets/images/na-img.png"
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                    <h5>seller sign</h5>
                    <p className="mb-0">
                      <img
                        src={
                          detail?.deal_details?.seller_signature
                            ? detail?.deal_details?.seller_signature
                            : "assets/images/na-img.png"
                        }
                        className="img-fluid"
                        alt=""
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {error && (
              <div className="d-flex justify-content-end w-100">
                <div
                  className={
                    success ? "alert alert-success" : "alert alert-danger"
                  }
                >
                  {error}
                </div>
              </div>
            )}
            <div className="col-sm-12">
              <div className="view-btns">
                {/* <button
                className="btn btn-cancel mt-2"
                onClick={() => {
                  handleShow();
                }}
              >
                <img
                  src="assets/images/delete-button.png"
                  alt=""
                  width="53px"
                />
              </button>
              <button
                className="btn btn-reject btn-view mt-2"
                onClick={() => {
                  approvedReject({
                    status: 2,
                    offer_id: detail?.id,
                  });
                }}
              >
                Reject
              </button> */}

                {/* {detail?.deal_details?.admin_response === "1" ? (
                  ""
                ) : detail?.deal_details?.seller_signature === "" &&
                  detail?.is_paid === "false" ? (
                  <button
                    className="btn btn-accept btn-view mt-2"
                    onClick={() => {
                      handleShow();
                    }}
                  >
                    Confirm (RFD)
                  </button>
                ) : (
                  <button
                    className="btn btn-disable btn-view mt-2 disable"
                    onClick={() => {
                      handleShow();
                    }}
                  >
                    Confirm (RFD)
                  </button>
                )} */}

                {/* {detail?.deal_details?.seller_signature === "" &&
                detail?.is_paid === false ? (
                  <button
                    className=""
                    onClick={() => {
                      handleShow();
                    }}
                  >
                    Confirm (RFD)
                  </button>
                ) : (
                  <button
                    className=" "
                    onClick={() => {
                      handleShow();
                    }}
                  >
                    Confirm (RFD)
                  </button>
                )} */}

                {detail?.deal_details?.admin_response === "1" ? null : (
                  <button
                    className={
                      (detail?.deal_details?.seller_signature === null &&
                        detail?.is_paid === false) ||
                      (detail?.deal_details?.seller_signature &&
                        detail?.is_paid === false) ||
                      (detail?.deal_details?.seller_signature === null &&
                        detail?.is_paid === true)
                        ? "btn btn-disable btn-view mt-2 disable"
                        : "btn btn-accept btn-view mt-2"
                    }
                    onClick={() => {
                      handleShow();
                    }}
                  >
                    Confirm (RFD)
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal
          page="Booking"
          show={show}
          handleShow={handleShow}
          confirmRFD={confirmRFD}
        />
      </article>
    </>
  );
}

export default BookingDetail;
