import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginreducer, togglesuccess } from "./LoginSlice";
import Loader from "../../component/Loader";

function Home() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  let success = useSelector((state) => state.login.success);

  return (
    <section className="">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-5 col-md-5 col-lg-6 text-center">
            <div className="logo-bg">
              <div className="mt-2">
                <img
                  src="assets/images/logo.png"
                  className="img-fluid lft-img"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-7 col-md-7 col-lg-6 mr-0 pr-0">
            <div className="form-bg">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  toggle: false,
                  error: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Email must be valid email id.")
                    .required("Required"),
                  password: Yup.string().required("Required"),
                })}
                onSubmit={(values, { isSubmitting, resetForm }) => {
                  setLoading(true);
                  //console.log(values);
                  dispatch(
                    loginreducer({
                      email: values.email,
                      password: values.password,
                    })
                  ).then((data) => {
                    // console.log(data);
                    if (data?.payload?.code === 1) {
                      dispatch(togglesuccess(true));
                      setTimeout(() => {
                        navigate("/car-listings", {
                          replace: true,
                        });
                      }, 3000);
                    } else {
                      dispatch(togglesuccess(false));
                      setError(data?.payload?.message);
                      setTimeout(() => {
                        setError("");
                      }, 1000);
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFormikState,
                }) => (
                  <form
                    className="form-style"
                    onSubmit={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSubmit();
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-12 text-center mb-5">
                        <h2 className="mdl-ttl">Login</h2>
                      </div>
                      <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              className="form-control pl-5"
                              placeholder="Email Address"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <img
                              src="assets/images/Email-Address.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                        {errors.email && touched.email && (
                          <div className="text-red"> {errors.email}</div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              id="password-field"
                              type={values.toggle ? "text" : "password"}
                              name="password"
                              className="form-control pl-5"
                              placeholder="Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <img
                              src="assets/images/password.png"
                              className="input-img"
                              alt=""
                            />
                            <span
                              className={
                                values.toggle
                                  ? "pass-hide field-icon toggle-password"
                                  : "pass-view field-icon toggle-password"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      toggle: !prevState.values.toggle,
                                    },
                                  };
                                });
                              }}
                            ></span>
                          </div>
                          {errors.password && touched.password && (
                            <div className="text-red"> {errors.password}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 text-center mb-4">
                        <Link to="/forgot-password" className="forgot-pass">
                          Forgot Password?
                        </Link>
                      </div>
                      <div className="col-sm-12 col-md-12 text-center">
                        <button type="submit" className="btn btn-save">
                          {loading ? <Loader /> : "Log In"}
                        </button>
                      </div>
                      {error && (
                        <div
                          className={
                            success
                              ? "col-sm-12 col-md-12 alert alert-success text-center mt-2 text-capitalize"
                              : "col-sm-12 col-md-12 alert alert-danger text-center mt-2 text-capitalize"
                          }
                        >
                          {error}
                        </div>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
