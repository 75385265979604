import React from "react";
import { Button, Modal } from "react-bootstrap";

function DeleteModal({ page, show, handleShow, confirmDelete }) {
  return (
    <Modal show={show} onHide={handleShow}>
      <Modal.Header>
        <Modal.Title>Delete {page}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Do you want to delete this {page} ?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleShow}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            confirmDelete();
            handleShow();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
