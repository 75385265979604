import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../config/index";

const BASE_URL = SERVER_URL;

const initialState = {};
export const UpdateStatusReducer = createAsyncThunk(
  "UpdateStatusReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      // let formData = new FormData();
      // for (let key in obj) {
      //   formData.append(key, obj[key]);
      // }

      let status = obj.status.toString();
      let request_id = obj.request_id.toString();

      let data = {status, request_id};
      const response = await axios.patch(
        `${BASE_URL}/alliedService/updateRequestStatus`,
        data,
        {
          headers: {
            token: token,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const UpdateStatusSlice = createSlice({
  name: "UpdateStatus",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
});

export const { reducerName } = UpdateStatusSlice.actions;

export default UpdateStatusSlice.reducer;
