import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  OTPVerificationdreducer,
  ResendOTPReducer,
  togglesuccess,
} from "./LoginSlice";

function OTPVerification() {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  let success = useSelector((state) => state.login.success);

  const [seconds, setSeconds] = useState(10);
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
  }, [seconds]);

  const resendCode = () => {
    dispatch(ResendOTPReducer()).then((res) => {
      if (res?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  return (
    <section className="">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-6 text-center">
            <div className="logo-bg">
              <div className="mt-2">
                <img
                  src="assets/images/logo.png"
                  className="img-fluid lft-img"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 mr-0 pr-0">
            <div className="form-bg">
              <Formik
                initialValues={{ otp: "" }}
                validationSchema={Yup.object({
                  otp: Yup.string()
                    .required("Required")
                    .matches(/^[0-9]+$/, "Must be only digits")
                    .min(6, "Kindly enter valid otp.")
                    .max(6, "Kindly enter valid otp."),
                })}
                onSubmit={(values, { isSubmitting, resetForm }) => {
                  console.log(values);
                  dispatch(
                    OTPVerificationdreducer({
                      code: values.otp,
                    })
                  ).then((data) => {
                    // console.log(data);
                    if (data?.payload?.code === 1) {
                      dispatch(togglesuccess(true));
                      setError(data?.payload?.message);
                      setTimeout(() => {
                        setError("");
                        navigate("/reset-password", {
                          replace: true,
                        });
                      }, 3000);
                    } else {
                      dispatch(togglesuccess(false));
                      setError(data?.payload?.message);
                      setTimeout(() => {
                        setError("");
                      }, 3000);
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="row form-style" onSubmit={handleSubmit}>
                    <div className="col-sm-12 text-center">
                      <h2 className="mdl-ttl">OTP Verification</h2>
                      <img
                        src="assets/images/otp.png"
                        className="img-fluid my-3"
                        alt=""
                      />
                      <p className="mdl-sbttl">
                        Please enter 6 digit code, which has been sent on your
                        registered email address
                      </p>
                    </div>
                    <div className="form-group col-sm-12">
                      <OtpInput
                        containerStyle="passcode-wrapper"
                        value={values.otp}
                        onChange={(val) => {
                          setFieldValue("otp", val);
                        }}
                        inputStyle={"otpInputs"}
                        numInputs={6}
                        separator={<span> </span>}
                      />

                      {errors.otp && touched.otp ? errors.otp : null}
                    </div>
                    <input
                      type="hidden"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <div className="col-sm-12 text-center">
                      <span>00:{seconds}</span>
                    </div>
                    <div className="col-sm-12 mt-4 text-center">
                      <button type="submit" className="btn btn-save">
                        Submit
                      </button>
                    </div>
                    {error && (
                      <div
                        className={
                          success
                            ? "col-sm-12 col-md-12 alert alert-success text-center mt-2 text-capitalize"
                            : "col-sm-12 col-md-12 alert alert-danger text-center mt-2 text-capitalize"
                        }
                      >
                        {error}
                      </div>
                    )}
                    <div className="col-sm-12 text-center mt-4">
                      <div>
                        <span
                          className={
                            seconds === 0
                              ? "forgot-pass p-0"
                              : "forgot-pass disable p-0"
                          }
                          onClick={() => {
                            resendCode();
                          }}
                        >
                          Resend Code
                        </span>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OTPVerification;
