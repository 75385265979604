import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../../../config/index";

const BASE_URL = SERVER_URL;

const initialState = {
  total: 0,
  EscrowService: [],
  details: {},
};
export const EscrowServiceListingsReducer = createAsyncThunk(
  "EscrowServiceListingsReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/alliedService/getAllAlliedServiceRequest`,
        {
          params: {
            search: obj.search,
            page: obj.page,
            limit: obj.limit,
            service_id: obj.service_id,
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const EscrowServiceDetailReducer = createAsyncThunk(
  "EscrowServiceDetailReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/alliedService/getAlliedServiceRequestDetails`,
        {
          params: {
            request_id: obj.id,
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);
export const EscrowServicelistingSlice = createSlice({
  name: "EscrowServicelistings",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [EscrowServiceListingsReducer.fulfilled]: (state, action) => {
      // console.log(action)
      state.EscrowService = action?.payload?.data?.items;
      state.total = action?.payload?.data?.total;
    },
    [EscrowServiceDetailReducer.fulfilled]: (state, action) => {
      //console.log(action)
      state.details = action?.payload?.data?.request;
    },
  },
});

export const { reducerName } = EscrowServicelistingSlice.actions;

export default EscrowServicelistingSlice.reducer;
