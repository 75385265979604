import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../../config/index";

const BASE_URL = SERVER_URL;

const initialState = {
  total: 0,
  booking: [],
  details: {},
};
export const BookingReducer = createAsyncThunk(
  "BookingReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/booking/getAllCarDealList`,
        {
          params: {
            search: obj.search,
            page: obj.page,
            limit: obj.limit,
            user_type: obj.user_type,
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("Slice Data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const BookingDetailReducer = createAsyncThunk(
  "BookingDetailReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/booking/getCarDealDetails`,
        {
          params: {
            deal_id: obj.id,
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const UpdateBookingReducer = createAsyncThunk(
  "UpdateBookingReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      const response = await axios.patch(
        `${BASE_URL}/booking/approvedRejectBookingByAdmin`,
        formData,
        {
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const RFDBookingReducer = createAsyncThunk(
  "RFDBookingReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      const response = await axios.put(
        `${BASE_URL}/booking/approveRejectBooking`,
        formData,
        {
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const BookingDeleteReducer = createAsyncThunk(
  "BookingDeleteReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      let formData = new FormData();
      formData.append("deal_id", obj.deal_id);
      const response = await axios.delete(
        `${BASE_URL}/booking/removeCarDeal`,

        {
          data: formData,
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const BookingSlice = createSlice({
  name: "Booking",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [BookingReducer.fulfilled]: (state, action) => {
      //console.log(action)
      state.booking = action?.payload?.data?.deal_list;
      state.total = action?.payload?.data?.total_deal;
    },
    [BookingDetailReducer.fulfilled]: (state, action) => {
      //console.log(action)
      state.details = action?.payload?.data;
    },
  },
});

export const { reducerName } = BookingSlice.actions;

export default BookingSlice.reducer;
