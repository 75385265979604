import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddQuotation from "../../../component/AddQuotation";
import { DueDiligenceDetailReducer } from "./DueDiligenceServicesSlice";
import moment from "moment";
import ViewImageModal from "../../../component/ViewImageModal";

function DueDiligenceRequests() {
  const [ReqId, setReqId] = useState("");
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [imgSRC, setimgSRC] = useState("");
  const [img, setimg] = useState({});
  const [imgName, setimgName] = useState("");
  const handleShow = () => {
    setShow(!show);
  };
  const { id } = useParams();
  const dispatch = useDispatch();

  const detail = useSelector((store) => store.DueDiligence.details);
  //console.log(detail);

  const getdetail = useCallback(() => {
    dispatch(
      DueDiligenceDetailReducer({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h2 className="head-title">Due Diligence</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-4 text-center">
              <img
                src={
                  detail?.serviceDetails?.image || "assets/images/employe.png"
                }
                className="img-fluid srvsIcon"
                alt=""
              />
            </div>
            <div className="col-sm-12 mt-4">
              <h2>User Detail</h2>
            </div>

            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="table-responsive pt-4">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/Full-Name.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          First Name
                        </td>
                        <td>
                          <img
                            src="assets/images/Full-Name.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Last Name
                        </td>
                        <td>
                          <img
                            src="assets/images/mobile-nu.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Mobile Number{" "}
                        </td>
                        <td>
                          <img
                            src="assets/images/Email-Address.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          personal Email
                        </td>
                        <td>
                          <img
                            src="assets/images/Email-Address.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          official Email
                        </td>
                      </tr>
                      <tr>
                        <th>{detail?.userDetails?.first_name || "N/A"}</th>
                        <th>{detail?.userDetails?.last_name || "N/A"}</th>
                        <th>
                          {detail?.userDetails?.mobile
                            ? `${detail?.userDetails?.countryCode} ${detail?.userDetails?.mobile}`
                            : "NA"}
                        </th>
                        <th>{detail?.userDetails?.personal_email || "N/A"}</th>
                        <th>{detail?.userDetails?.official_email || "N/A"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-4">
              <h2>Vehicle Detail</h2>
            </div>
            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="table-responsive pt-4">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/REGN-No-Initials.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Vehicle Regn No
                        </td>
                        <td>
                          <img
                            src="assets/images/OEM-Make.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Make{" "}
                        </td>
                        <td>
                          <img
                            src="assets/images/Model.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Modal
                        </td>
                        <td>
                          <img
                            src="assets/images/KM-Reading.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          KM
                        </td>
                        <td>
                          <img
                            src="assets/images/Month-Year-of-Regn.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Regn Year
                        </td>
                        <td>
                          <img
                            src="assets/images/Current-Car-Location.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          location
                        </td>
                      </tr>
                      <tr>
                        <th>{detail?.vehicle_regn_no || "N/A"}</th>
                        <th>{detail?.make || "N/A"}</th>
                        <th>{detail?.model || "N/A"}</th>
                        <th>{detail?.km_reading || "N/A"}</th>
                        <th>{detail?.regn_year || "N/A"}</th>
                        <th>{detail?.vehicle_location || "N/A"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-sm-12 mt-4">
              <h2>Applicant Details</h2>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <div className="view-detail mt-0">
                <div className="table-responsive pt-4">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/Full-Name.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          First Name
                        </td>
                        <td>
                          <img
                            src="assets/images/Full-Name.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Last Name
                        </td>
                        <td>
                          <img
                            src="assets/images/mobile-nu.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Mobile Number
                        </td>
                        <td>
                          <img
                            src="assets/images/Email-Address.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Email
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {detail?.dueDiligenceDetails?.first_name || "N/A"}
                        </th>
                        <th>
                          {detail?.dueDiligenceDetails?.last_name || "N/A"}
                        </th>
                        <th>
                          {detail?.dueDiligenceDetails?.country_code || "N/A"}{" "}
                          {detail?.dueDiligenceDetails?.mobile_number || "N/A"}
                        </th>
                        <th>{detail?.dueDiligenceDetails?.email || "N/A"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-sm-12 mb-4">
              <h2>Required Images</h2>
            </div>
            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">RC Front Image</h3>
                    <img
                      src={
                        detail?.documentDetails?.rc_front_image ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        setimgSRC("documentDetails.rc_front_image");
                        setimgName(detail?.documentDetails?.rc_front_image_name);
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">RC Back Image</h3>
                    <img
                      src={
                        detail?.documentDetails?.rc_back_image ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        setimgSRC("documentDetails.rc_back_image");
                        setimgName(detail?.documentDetails?.rc_back_image_name );
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 mt-4">
                    <h2>Vehicle Regn Owner ID & Address </h2>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">
                      Vehicle Regn Owner ID
                    </h3>
                    <img
                      src={
                        detail?.dueDiligenceDetails
                          ?.vehicle_regd_owner_id_file_path ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        setimgSRC(
                          "dueDiligenceDetails.vehicle_regd_owner_id_file_path"
                        );
                        setimgName(detail?.dueDiligenceDetails?.vehicle_regd_owner_id_file_name);
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">
                      Vehicle Regn Owner Address
                    </h3>
                    <img
                      src={
                        detail?.dueDiligenceDetails
                          ?.vehicle_regd_owner_address_file_path ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        setimgSRC(
                          "dueDiligenceDetails.vehicle_regd_owner_address_file_path"
                        );
                        setimgName(detail?.dueDiligenceDetails?.vehicle_regd_owner_address_file_name );
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 mt-4">
                    <h2>Insurance Details</h2>
                  </div>
                  <div className="col-sm-12 mb-4">
                    <div className="table-responsive pt-4">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              <img
                                src="assets/images/extended-warranty.png"
                                className="detail-icon"
                                alt=""
                              />{" "}
                              ins Co. name
                            </td>
                            <td>
                              <img
                                src="assets/images/extended-warranty.png"
                                className="detail-icon"
                                alt=""
                              />{" "}
                              policy no.
                            </td>
                            <td>
                              <img
                                src="assets/images/Date-of-Birth.png"
                                className="detail-icon"
                                alt=""
                              />{" "}
                              expiry date{" "}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              {detail?.dueDiligenceDetails?.ins_co_name ||
                                "N/A"}
                            </th>
                            <th>
                              {detail?.dueDiligenceDetails?.policy_no || "N/A"}
                            </th>
                            <th>
                              {" "}
                              {moment(
                                detail?.dueDiligenceDetails?.exp_date,
                                "YYYY DD MM"
                              ).format("DD MMM YYYY")}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">Insurance Copy</h3>
                    <img
                      src={
                        detail?.dueDiligenceDetails?.insurance_copy_file_path ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        setimgSRC(
                          "dueDiligenceDetails.insurance_copy_file_path"
                        );
                        setimgName(detail?.dueDiligenceDetails?.insurance_copy_file_name );
                        handleShow();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-4">
              <h2>Payment Detail</h2>
            </div>
            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="table-responsive pt-4">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/REGN-No-Initials.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          amount
                        </td>
                        <td>
                          <img
                            src="assets/images/Model.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          transaction Id
                        </td>
                        <td>
                          <img
                            src="assets/images/OEM-Make.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          order Id{" "}
                        </td>
                      </tr>
                      <tr>
                        <th>{detail?.paymentDetails?.amount || "N/A"}</th>
                        <th>
                          {detail?.paymentDetails?.transactionId || "N/A"}
                        </th>
                        <th>{detail?.paymentDetails?.orderId || "N/A"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="view-btns">
                {/* {detail?.quotation_pdf === null ? (
                  <button
                    className="btn btn-reject btn-view mt-2"
                    onClick={() => {
                      setReqId(detail?.id);
                      setModalName("AddQuotation");
                      handleShow();
                    }}
                  >
                    Add Quotation
                  </button>
                ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </div>
        {show && modalName === "AddQuotation" && (
          <AddQuotation
            show={show}
            handleShow={handleShow}
            ReqId={ReqId}
            getList={getdetail}
          />
        )}
        {detail[imgSRC.split(".")[0]] &&
          imgSRC &&
          modalName === "ViewImage" && (
            <ViewImageModal
              show={show}
              handleShow={handleShow}
              imgSRC={imgSRC}
              image={detail}
              setitem={setimg}
              setimgSRC={setimgSRC}
              imageName={imgName}
            />
          )}
      </article>
    </>
  );
}

export default DueDiligenceRequests;
