import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddQuotation from "../../../component/AddQuotation";
import ViewImageModal from "../../../component/ViewImageModal";
import { HSRPServiceDetailReducer } from "./HSRPServiceSlice";

function HSRPServiceRequests() {
  const [ReqId, setReqId] = useState("");
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [imgSRC, setimgSRC] = useState("");
  const [img, setimg] = useState({});
  const [imgName, setimgName] = useState("");
  const handleShow = () => {
    setShow(!show);
  };
  const { id } = useParams();
  const dispatch = useDispatch();

  const detail = useSelector((store) => store.HSRPService.details);
  //console.log(detail);

  const getdetail = useCallback(() => {
    dispatch(
      HSRPServiceDetailReducer({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h2 className="head-title">HSRP</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-4 text-center">
              <img
                src={
                  detail?.serviceDetails?.image || "assets/images/employe.png"
                }
                className="img-fluid srvsIcon"
                alt=""
              />
            </div>
            <div className="col-sm-12 mt-4">
              <h2>User Detail</h2>
            </div>

            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="table-responsive pt-4">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/Full-Name.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          First Name
                        </td>
                        <td>
                          <img
                            src="assets/images/Full-Name.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Last Name
                        </td>
                        <td>
                          <img
                            src="assets/images/mobile-nu.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Mobile Number{" "}
                        </td>
                        <td>
                          <img
                            src="assets/images/Email-Address.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          personal Email
                        </td>
                        <td>
                          <img
                            src="assets/images/Email-Address.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          official Email
                        </td>
                      </tr>
                      <tr>
                        <th>{detail?.userDetails?.first_name || "NA"}</th>
                        <th>{detail?.userDetails?.last_name || "NA"}</th>
                        <th>
                          {detail?.userDetails?.mobile
                            ? `${detail?.userDetails?.countryCode} ${detail?.userDetails?.mobile}`
                            : "NA"}
                        </th>
                        <th>{detail?.userDetails?.personal_email || "NA"}</th>
                        <th>{detail?.userDetails?.official_email || "NA"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-4">
              <h2>Vehicle Detail</h2>
            </div>
            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0 pt-3">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-2">
                    <div className="pl-3">
                      <img
                        src="assets/images/REGN-No-Initials.png"
                        className="detail-icon"
                        alt=""
                      />{" "}
                      <span>Vehicle Regn No</span>
                      <p>
                        <b>{detail?.vehicle_regn_no || "NA"}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-2">
                    <div className="pl-3">
                      <img
                        src="assets/images/OEM-Make.png"
                        className="detail-icon"
                        alt=""
                      />{" "}
                      <span>Make</span>
                      <p>
                        <b>{detail?.make || "NA"}</b>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-2">
                    <div className="pl-3">
                      <img
                        src="assets/images/Model.png"
                        className="detail-icon"
                        alt=""
                      />{" "}
                      <span>Modal</span>
                      <p>
                        <b>{detail?.model || "NA"}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 mb-4">
              <h2>Required Images</h2>
            </div>
            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">RC Front Image</h3>
                    <img
                      src={
                        detail?.documentDetails?.rc_front_image ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        setimgSRC("documentDetails.rc_front_image");
                        setimgName(detail?.documentDetails?.rc_front_image_name);
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">RC Back Image</h3>
                    <img
                      src={
                        detail?.documentDetails?.rc_back_image ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        setimgSRC("documentDetails.rc_back_image");
                        setimgName(detail?.documentDetails?.rc_back_image_name );
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">Regn Owner ID</h3>
                    <img
                      src={
                        detail?.documentDetails?.regn_owner_id ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        setimgSRC("documentDetails.regn_owner_id");
                        setimgName(detail?.documentDetails?.regn_owner_id_name);
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">
                      Regn Owner Address
                    </h3>
                    <img
                      src={
                        detail?.documentDetails?.regn_owner_address ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        setimgSRC("documentDetails.regn_owner_address");
                        setimgName(detail?.documentDetails?.regn_owner_address_name);
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">Need HSRP</h3>

                    <p>
                      {detail?.hsrpDetails?.hsrp === "only hsrp"
                        ? "Only HSRP"
                        : detail?.hsrpDetails?.hsrp === "hsrp with sticker"
                        ? "Need Sticker only "
                        : "NA"}
                      <img
                        src={
                          detail?.hsrpDetails?.hsrp === "hsrp with sticker"
                            ? "assets/images/atm_cardpng"
                            : ""
                        }
                        className={
                          detail?.hsrpDetails?.hsrp === "only hsrp"
                            ? "d-none"
                            : detail?.hsrpDetails?.hsrp === "hsrp with sticker"
                            ? "img-fluid"
                            : "d-none"
                        }
                        alt=""
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-4">
              <h2>Payment Detail</h2>
            </div>
            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="table-responsive pt-4">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/REGN-No-Initials.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          amount
                        </td>
                        <td>
                          <img
                            src="assets/images/Model.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          transaction Id
                        </td>
                        <td>
                          <img
                            src="assets/images/OEM-Make.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          order Id{" "}
                        </td>
                      </tr>
                      <tr>
                        <th>{detail?.paymentDetails?.amount || "NA"}</th>
                        <th>{detail?.paymentDetails?.transactionId || "NA"}</th>
                        <th>{detail?.paymentDetails?.orderId || "NA"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="view-btns">
                {/* {detail?.quotation_pdf === null ? (
                  <button
                    className="btn btn-reject btn-view mt-2"
                    onClick={() => {
                      setReqId(detail?.id);
                      setModalName("AddQuotation");
                      handleShow();
                    }}
                  >
                    Add Quotation
                  </button>
                ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </div>
        {show && modalName === "AddQuotation" && (
          <AddQuotation
            show={show}
            handleShow={handleShow}
            ReqId={ReqId}
            getList={getdetail}
          />
        )}
        {detail[imgSRC.split(".")[0]] &&
          imgSRC &&
          modalName === "ViewImage" && (
            <ViewImageModal
              show={show}
              handleShow={handleShow}
              imgSRC={imgSRC}
              image={detail}
              setitem={setimg}
              setimgSRC={setimgSRC}
              imageName={imgName}
            />
          )}
      </article>
    </>
  );
}

export default HSRPServiceRequests;
