import React from "react";
import { Modal } from "react-bootstrap";

import DownloadButton from "./DownloadButton";
function ViewImageModal({
  show,
  handleShow,
  image,
  imgSRC,
  setImgId,
  setitem,
  setimgSRC,
  imageName
}) {
  const arr = imgSRC.split(".");


  return (
    <Modal
      show={show}
      onHide={() => {
        handleShow();
        setimgSRC("");
      }}
    >
      <Modal.Body>
        <div
          className="modal-dialog modal-lg  modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setImgId("");
                  setitem({});
                  handleShow();
                }}
              >
                <span className="i30px iclose"></span>
              </button>
              <div className="green-part text-center">
                <h4 className="">View Image</h4>
              </div>
              <div className="add-data py-5 px-lg-5">
                <div className="row justify-content-center">
                  <div className="col-12">
                    <img
                      className="img-fluid"
                      src={
                        image[arr[0]][arr[1]]
                          ? `${image[arr[0]][arr[1]]}`
                          : "assets/images/employe.png"
                      }
                      alt="img"
                    />
                      <DownloadButton imageUrl={image[arr[0]][arr[1]]} imageName={imageName} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ViewImageModal;
