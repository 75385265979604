import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ChangePasswordreducer, togglesuccess } from "./LoginSlice";

export default function ChangePassword() {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let success = useSelector((state) => state.login.success);

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <h2 className="head-title">Change Password</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                changePassword: "",
              }}
              validationSchema={Yup.object({
                oldPassword: Yup.string().required("Required"),
                newPassword: Yup.string().required("Required"),
                changePassword: Yup.string().oneOf(
                  [Yup.ref("newPassword"), null],
                  "Passwords must match"
                ),
              })}
              onSubmit={(values) => {
                console.log(values);
                dispatch(
                  ChangePasswordreducer({
                    current_password: values.oldPassword,
                    new_password: values.newPassword,
                  })
                ).then((data) => {
                  // console.log(data);
                  if (data?.payload?.code === 1) {
                    dispatch(togglesuccess(true));
                    setError(data?.payload?.message);
                    setTimeout(() => {
                      setError("");
                      navigate("/", {
                        replace: true,
                      });
                    }, 3000);
                  } else {
                    dispatch(togglesuccess(false));
                    setError(data?.payload?.message);
                    setTimeout(() => {
                      setError("");
                    }, 3000);
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFormikState,
              }) => (
                <form className="form-style chng-pass" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 text-center mb-3">
                      <img
                        src="assets/images/reset.png"
                        className="img-fluid"
                        alt=""
                      />
                      <p>Please Change Password your password</p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="password"
                            className="form-control pl-5"
                            placeholder="Enter current password"
                            name="oldPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.oldPassword}
                          />
                          <img
                            src="assets/images/password.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                        {errors.oldPassword && touched.oldPassword && (
                          <div className="text-red"> {errors.oldPassword}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 text-center mb-4">
                      <Link to="/forgot-password" className="forgot-pass">
                        Forgot Password?
                      </Link>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="password"
                            className="form-control pl-5"
                            placeholder="New password"
                            name="newPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                          />
                          <img
                            src="assets/images/password.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                        {errors.newPassword && touched.newPassword && (
                          <div className="text-red"> {errors.newPassword}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="password"
                            className="form-control pl-5"
                            placeholder="Confirm New password"
                            name="changePassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.changePassword}
                          />
                          <img
                            src="assets/images/password.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                        {errors.changePassword && touched.changePassword && (
                          <div className="text-red">
                            {" "}
                            {errors.changePassword}
                          </div>
                        )}
                      </div>
                    </div>
                    {error && (
                      <div
                        className={
                          success
                            ? "col-sm-12 col-md-12 alert alert-success text-center mt-2 text-capitalize"
                            : "col-sm-12 col-md-12 alert alert-danger text-center mt-2 text-capitalize"
                        }
                      >
                        {error}
                      </div>
                    )}
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="text-center">
                        <button type="submit" className="btn btn-save">
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </article>
  );
}
