import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserDetailReducer } from "./UserSlice";
import ViewImageModal from "../../component/ViewImageModal";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";

function Userdetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [imgSRC, setimgSRC] = useState("");
  const [imgName, setimgName] = useState("");
  const [img, setimg] = useState({});

  const handleShow = () => {
    setShow(!show);
  };

  const detail = useSelector((store) => store.User.details);

  //console.log(detail?.[imgSRC?.split(".")[0]], imgSRC, modalName);

  const getdetail = useCallback(() => {
    dispatch(
      UserDetailReducer({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);

  const percentage = 66;

  const approvedReject = (data) => {
    //console.log(data);
    // dispatch(UpdateOfferReducer(data)).then((res) => {
    //   if (res?.payload?.code === 1) {
    //     dispatch(togglesuccess(true));
    //     setError(res?.payload?.message);
    //     setTimeout(() => {
    //       navigate("/make-offer", {
    //         replace: true,
    //       });
    //       setError("");
    //     }, 2000);
    //   } else {
    //     dispatch(togglesuccess(false));
    //     setError(res?.payload?.message);
    //     setTimeout(() => {
    //       setError("");
    //     }, 2000);
    //   }
    // });
  };

  const confirmDelete = () => {
    //console.log(id);
    // dispatch(DeleteOfferReducer({ offer_id: id })).then((res) => {
    //   if (res?.payload?.code === 1) {
    //     dispatch(togglesuccess(true));
    //     setError(res?.payload?.message);
    //     setTimeout(() => {
    //       navigate("/make-offer", {
    //         replace: true,
    //       });
    //       setError("");
    //     }, 2000);
    //   } else {
    //     dispatch(togglesuccess(false));
    //     setError(res?.payload?.message);
    //     setTimeout(() => {
    //       setError("");
    //     }, 2000);
    //   }
    // });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <h2 className="head-title">User Detail</h2>
          </div>
        </div>
        <div className="view-detail mt-4">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 mb-4 text-center mt-4">
              <h6>Profile Image</h6>
              <img
                src={
                  detail?.professional_social_details?.profile_pic?.includes(
                    "http"
                  )
                    ? detail?.professional_social_details?.profile_pic
                    : "assets/images/employe.png"
                }
                className="profileImg cursor-pointer"
                alt={detail?.professional_social_details?.profile_pic_file_name}
                onClick={() => {
                  setModalName("ViewImage");
                  setimgSRC("professional_social_details.profile_pic");
                  setimgName(detail?.professional_social_details?.profile_pic_file_name);
                  handleShow();
                }}
              />
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 mb-4 text-center mt-4">
              <h6>Profile Percentage</h6>
              <CircularProgressbar
                value={
                  detail?.personal_contact_details?.profile_complete_percentage
                }
                text={`${detail?.personal_contact_details?.profile_complete_percentage}%`}
                styles={buildStyles({
                  textSize: "14px",
                  pathColor: "#fec00f",
                  textColor: "#fec00f",
                  //trailColor: "gold",
                })}
              />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4 text-capitalize">
              <h6>First Name</h6>
              <p>
                {detail?.personal_contact_details?.first_name || (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4 text-capitalize">
              <h6>Last Name</h6>
              <p>
                {detail?.personal_contact_details?.last_name || (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4 text-capitalize">
              <h6>Gender</h6>
              <p>
                {detail?.personal_contact_details?.gender || (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4 text-capitalize">
              <h6>S/O, W/O, D/O</h6>
              <p>
                {detail?.personal_contact_details?.parent || (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4 text-capitalize">
              <h6>Mobile no.</h6>
              <p>
                {detail?.personal_contact_details?.mobile ? (
                  `${detail?.personal_contact_details?.countryCode} ${detail?.personal_contact_details?.mobile}`
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4 text-capitalize">
              <h6>WhatsApp No.</h6>
              <p>
                {detail?.personal_contact_details?.whatsapp_no ? (
                  `${detail?.personal_contact_details?.whatsapp_countryCode} ${detail?.personal_contact_details?.whatsapp_no}`
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Personal Email ID</h6>
              <p>
                {detail?.personal_contact_details?.personal_email || (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Official Email ID</h6>
              <p>
                {detail?.personal_contact_details?.official_email || (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>User Type</h6>
              <p>
                {detail?.personal_contact_details?.user_type || (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Location</h6>
              <p>
                {detail?.personal_contact_details?.address || (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Create At</h6>
              <p>
                {detail?.personal_contact_details?.createdAt ? (
                  moment(detail?.personal_contact_details?.createdAt).format(
                    "DD/MM/YYYY"
                  )
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-4">
              <h3>KYC Detail</h3>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Current Resi Address with Landmak</h6>
              <p>
                {detail?.kyc_details?.current_resi_landmark ? (
                  detail?.kyc_details?.current_resi_landmark
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Postal Address</h6>
              <p>
                {detail?.kyc_details?.postal_address ? (
                  detail?.kyc_details?.postal_address
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>
                My Vehicle Registered (OR to be Registered) in the name of
              </h6>
              <p>
                {detail?.kyc_details?.vehicle_registed_in_the_name_of ? (
                  detail?.kyc_details?.vehicle_registed_in_the_name_of
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Address of Vehicle Registration</h6>
              <p>
                {detail?.kyc_details?.vehicle_regn_address_landmark ? (
                  detail?.kyc_details?.vehicle_regn_address_landmark
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>PAN No.</h6>
              <p>
                {detail?.kyc_details?.pan_no ? (
                  detail?.kyc_details?.pan_no
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Adhaar No.</h6>
              <p>
                {detail?.kyc_details?.adhar_no ? (
                  detail?.kyc_details?.adhar_no
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Signature Proof</h6>
              <p>
                {detail?.kyc_details?.signature_proof_file ? (
                  <img
                    src={detail?.kyc_details?.signature_proof_file}
                    className="img-fluid idImages1"
                    alt={detail?.kyc_details?.signature_proof_file_name}
                    onClick={() => {
                      setModalName("ViewImage");
                      setimgSRC("kyc_details.signature_proof_file");
                      setimgName(detail?.kyc_details?.signature_proof_file_name);
                      handleShow();
                    }}
                  />
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>GSTIN (If Available)</h6>
              <p>
                {detail?.kyc_details?.gstin_no ? (
                  detail?.kyc_details?.gstin_no
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-4">
              <h3>Professional & Social Details</h3>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4 text-capitalize">
              <h6>Occupation</h6>
              <p>
                {detail?.professional_social_details?.occupation ? (
                  detail?.professional_social_details?.occupation
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Company Name</h6>
              <p>
                {detail?.professional_social_details?.company_name ? (
                  detail?.professional_social_details?.company_name
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Town Reference</h6>
              <p>
                {detail?.professional_social_details?.town_reference ? (
                  detail?.professional_social_details?.town_reference
                ) : (
                  <img src="assets/images/no.png" width="25px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Social A/Cs Integration</h6>
              <p>
                {detail?.professional_social_details?.fb_url ? (
                  <a
                    href={detail?.professional_social_details?.fb_url}
                    target="_blank"
                  >
                    <img src="assets/images/facebook.png" width="25px" alt="" />
                  </a>
                ) : null}{" "}
                {detail?.professional_social_details?.instagram_url ? (
                  <a
                    href={detail?.professional_social_details?.instagram_url}
                    target="_blank"
                  >
                    <img
                      src="assets/images/instagram.png"
                      width="25px"
                      alt=""
                    />
                  </a>
                ) : null}{" "}
                {detail?.professional_social_details?.linkedIn_url ? (
                  <a
                    href={detail?.professional_social_details?.linkedIn_url}
                    target="_blank"
                  >
                    <img src="assets/images/linkedin.png" width="25px" alt="" />
                  </a>
                ) : null}{" "}
                {detail?.professional_social_details?.twitter_url ? (
                  <a
                    href={detail?.professional_social_details?.twitter_url}
                    target="_blank"
                  >
                    <img src="assets/images/twitter.png" width="25px" alt="" />
                  </a>
                ) : null}
                {!detail?.professional_social_details?.fb_url &&
                !detail?.professional_social_details?.instagram_url &&
                !detail?.professional_social_details?.linkedIn_url &&
                !detail?.professional_social_details?.twitter_url ? (
                  <img src="assets/images/no.png" width="20px" alt="" />
                ) : null}
              </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-4">
              <h3>Account Details</h3>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Account Holder's Name</h6>
              <p>
                {detail?.account_details?.account_holder_name ? (
                  detail?.account_details?.account_holder_name
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>Account Number</h6>
              <p>
                {detail?.account_details?.account_no ? (
                  detail?.account_details?.account_no
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
              <h6>IFSC Code</h6>
              <p>
                {detail?.account_details?.ifsc_code ? (
                  detail?.account_details?.ifsc_code
                ) : (
                  <img src="assets/images/no.png" width="20px" alt="" />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {detail[imgSRC.split(".")[0]] && imgSRC && modalName === "ViewImage" && (
        <ViewImageModal
          show={show}
          handleShow={handleShow}
          imgSRC={imgSRC}
          imageName={imgName}
          image={detail}
          setitem={setimg}
          setimgSRC={setimgSRC}
        />
      )}
    </article>
  );
}

export default Userdetail;
