import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DeleteModal from "../../component/DeleteModal";
import Paginations from "../../component/Pagination";
import { togglesuccess } from "../login/LoginSlice";
import { BookingDeleteReducer, BookingReducer } from "./BookingSlice";

function Booking() {
  const dispatch = useDispatch();
  const success = useSelector((store) => store.login.success);
  const [page, setPage] = useState(1);
  const total = useSelector((state) => state.Booking.total);
  const data = useSelector((state) => state.Booking.booking);
  const [status, setStatus] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [delId, setDelId] = useState("");
  const handleShow = () => {
    setShow(!show);
  };

  const handlePage = (value) => {
    setPage(value);
  };

  const getlist = useCallback(async () => {
    dispatch(
      BookingReducer({
        search: searchValue,
        page: page,
        limit: 10,
        status: status,
        user_type: "admin",
      })
    );
  }, [dispatch, searchValue, page, status]);

  const approvedReject = (data) => {
    //console.log(data);
    // dispatch(UpdateOfferReducer(data)).then((res) => {
    //   if (res?.payload?.code === 1) {
    //     dispatch(togglesuccess(true));
    //     setError(res?.payload?.message);
    //     getlist();
    //     setTimeout(() => {
    //       setError("");
    //     }, 2000);
    //   } else {
    //     dispatch(togglesuccess(false));
    //     setError(res?.payload?.message);
    //     getlist();
    //     setTimeout(() => {
    //       setError("");
    //     }, 2000);
    //   }
    // });
  };

  const confirmDelete = () => {
    dispatch(BookingDeleteReducer({ deal_id: delId })).then((res) => {
      if (res?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlist();
    }
    return () => {
      mounted = false;
    };
  }, [getlist]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="link-active">
                <img src="assets/images/makeOffer-selected.png" alt="" />
                <span>
                  <p>{total && total}</p>
                  <p>Total Bookings</p>
                </span>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Booking.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      setPage("1");
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
          <hr />

          <div className="col-sm-12">
            {error && (
              <div
                className={
                  success ? "alert alert-success" : "alert alert-danger"
                }
              >
                {error}
              </div>
            )}
            <div className="tab-content mt-4">
              <div className="table-responsive text-center">
                <table className="table storetable">
                  <thead className="text-center">
                    <tr>
                      <th scope="col">Regn Number</th>
                      <th scope="col">vehicle location</th>
                      <th scope="col">owner serial</th>
                      <th scope="col">number plate</th>
                      <th scope="col">regn owner name</th>
                      <th scope="col">action</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length > 0 ? (
                      data?.map((item, i) => (
                        <tr key={i}>
                          <td>
                            {item?.car_details?.regn_number || "NA"}
                            {/* {item?.car_details?.regn_number?.replace(
                              /.(?!.{6})/g,
                              "X"
                            ) || "NA"} */}
                          </td>
                          <td>
                            <div className="ellip-1">
                              {item?.car_details?.address || "NA"}
                            </div>
                          </td>
                          <td>
                            {item?.car_details?.owner_serial
                              ? item?.car_details?.owner_serial + `st owner`
                              : "NA"}
                          </td>
                          <td>{item?.car_details?.number_plate || "NA"}</td>
                          <td>{item?.car_details?.regd_owner_name || "NA"}</td>
                          <td>
                            <div className="d-flex justify-content-center action">
                              <button className="btn" title="View">
                                <Link to={`/booking-detail/${item?.id}`}>
                                  <img src="assets/images/view.png" alt="" />
                                </Link>
                              </button>
                              <button
                                className="btn"
                                title="Delete"
                                onClick={() => {
                                  setDelId(item?.id);
                                  handleShow();
                                }}
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {data?.length > 0 && (
                <Paginations
                  handlePage={handlePage}
                  page={page}
                  total={total && total}
                />
              )}
            </div>
          </div>
        </div>
        {delId && (
          <DeleteModal
            page="Booking"
            show={show}
            handleShow={handleShow}
            confirmDelete={confirmDelete}
          />
        )}
      </article>
    </>
  );
}

export default Booking;
