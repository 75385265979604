import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AddCarsNoByUser from "../../component/AddCarsNoByUser";
import DeleteModal from "../../component/DeleteModal";
import Paginations from "../../component/Pagination";
import { togglesuccess } from "../login/LoginSlice";
import Papa from 'papaparse';
import {  RequirementUserReducer, RequirementUserListCSVReducer } from "./RequirementSlice";

import RequirementModal from "../../component/RequirementModel";

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

function RequirementList() {
  const dispatch = useDispatch();
  const success = useSelector((store) => store.login.success);
  const [page, setPage] = useState(1);
  const total = useSelector((state) => state.Requirement.total);
  const data = useSelector((state) => state.Requirement.requirement);
  const allUserList = useSelector((state) => state.Requirement.allUserPrefList);

  const [status, setStatus] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [delId, setDelId] = useState("");
  const [modalName, setModalName] = useState("");
  const [noCars, setNoCars] = useState("");

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [focusedInput, setFocusedInput] = useState('');

  let start_date = startDate;
  let end_date = endDate; 

  const handleShow = () => {
    setShow(!show);
  };

  const handlePage = (value) => {
    setPage(value);
  };

  const getlist = useCallback(async () => {
    // dispatch(
    //   RequirementUserReducer({
    //     search: searchValue,
    //     page: page,
    //     limit: 10,
    //     status: status,
    //     //user_type: "admin",
    //   })
    // );

    const params = {
      search: searchValue,
      page: page,
      limit: 10,
      status: status,
    };
    if (start_date) {
      params.startDate = start_date._d.toISOString();
    }
    if (end_date) {
      params.endDate = end_date._d.toISOString();
    }
    dispatch(RequirementUserReducer(params));
  }, [dispatch, searchValue, page, status, start_date, end_date]);


  const getAllList = useCallback(async () => {
    // dispatch(
    //   RequirementUserReducer({
    //     status: status,
    //     //user_type: "admin",
    //   })
    // );
    const params = {
      status: status,
    };
    if (start_date) {
      params.startDate = start_date._d.toISOString();
    }
    if (end_date) {
      params.endDate = end_date._d.toISOString();
    }
    dispatch(RequirementUserReducer(params));
  }, [dispatch, status,start_date, end_date]);

  const getAllCSVList = useCallback(async () => {
    // dispatch(
    //   RequirementUserListCSVReducer({
    //     status: status,
    //     //user_type: "admin",
    //   })
    // );
    const params = {
      status: status,
    };
    if (start_date) {
      params.startDate = start_date._d.toISOString();
    }
    if (end_date) {
      params.endDate = end_date._d.toISOString();
    }
    dispatch(RequirementUserListCSVReducer(params));
  }, [dispatch, status,start_date, end_date]);


  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlist();
    }
    return () => {
      mounted = false;
    };
  }, [getlist]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getAllList();
    }

    return () => {
      mounted = false;
    };
  }, [getAllList]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getAllCSVList();
    }

    return () => {
      mounted = false;
    };
  }, [getAllCSVList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentDate = moment().format('lll');

  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };

  const handleExportCSV = () => {

    const detail_user_pref_list = [];
    for (let i = 0; i < allUserList.length; i++) {
      let detail_pref_list = {};
  
      detail_pref_list.id = allUserList[i].id;
      detail_pref_list.userId = allUserList[i].userId;
      detail_pref_list.first_name = allUserList[i].userDetails.first_name;
      detail_pref_list.last_name = allUserList[i].userDetails.last_name;
      detail_pref_list.gender = allUserList[i].userDetails.gender;
      detail_pref_list.parent = allUserList[i].userDetails.parent;
      detail_pref_list.countryCode = allUserList[i].userDetails.countryCode;
      detail_pref_list.mobile = allUserList[i].userDetails.mobile;
      detail_pref_list.whatsapp_countryCode = allUserList[i].userDetails.whatsapp_countryCode;
      detail_pref_list.whatsapp_no = allUserList[i].userDetails.whatsapp_no;
      detail_pref_list.personal_email = allUserList[i].userDetails.personal_email;
      detail_pref_list.official_email = allUserList[i].userDetails.official_email;
      detail_pref_list.profile_complete_percentage = allUserList[i].userDetails.profile_complete_percentage;
      detail_pref_list.no_of_cars_can_add = allUserList[i].userDetails.no_of_cars_can_add;
      detail_pref_list.canAddOthersCar = allUserList[i].userDetails.canAddOthersCar;
      detail_pref_list.user_type = allUserList[i].userDetails.user_type;
      detail_pref_list.address = allUserList[i].userDetails.address;
      detail_pref_list.longitude = allUserList[i].userDetails.longitude;
      detail_pref_list.latitude = allUserList[i].userDetails.latitude;
      detail_pref_list.profile_pic = allUserList[i].userDetails.image.profile_pic;
      detail_pref_list.number_plates = allUserList[i].number_plates;
      detail_pref_list.body_types = allUserList[i].body_types;
      detail_pref_list.min_price = allUserList[i].min_price;
      detail_pref_list.max_price = allUserList[i].max_price;
      // detail_pref_list.age_of_car = allUserList[i].age_of_car  === "0" ? 'Below 5 Years Old' : allUserList[i].age_of_car ===  "1" ? 'Above 5 Years Old' : 'N/A';
      detail_pref_list.age_of_car = (() => {
        const age = allUserList[i].age_of_car;
        if (!age) return 'N/A';
        if (age.includes("0") && age.includes("1")) return 'Below 5 Years Old, Above 5 Years Old';
        if (age.includes("0")) return 'Below 5 Years Old';
        if (age.includes("1")) return 'Above 5 Years Old';
        return 'N/A';
      })();
      
      detail_pref_list.fuel_types = allUserList[i].fuel_types;
      detail_pref_list.transmissions = allUserList[i].transmissions;
      detail_pref_list.registration_state = allUserList[i].registration_state;
      detail_pref_list.location_preferred = allUserList[i].location_preferred;
      detail_pref_list.loan_required = allUserList[i].loan_required === true ? 'Yes' : allUserList[i].loan_required === false ?  'No' : 'N/A';
      detail_pref_list.how_soon_you_want_to_buy = allUserList[i].how_soon_you_want_to_buy == 0 ? '1-2 W' : allUserList[i].how_soon_you_want_to_buy  == 1 ? '2-4 W' :  allUserList[i].how_soon_you_want_to_buy  == 2 ? '4+ W' : 'N/A';
      detail_pref_list.createdAt = allUserList[i].createdAt;
      detail_pref_list.updatedAt = allUserList[i].updatedAt;
        
      detail_user_pref_list.push(detail_pref_list);
    }
  
  
    const csvData = convertToCSV(detail_user_pref_list);

    // Create a Blob and a link to trigger the download
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `users_preferences_data_${currentDate}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  };

const isLoading = allUserList.length == 0;

const clearDate = () => {

  setEndDate('');
  setStartDate('');
}
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-reverse">
            <div className="col-sm-12 col-md-2 col-lg-2 mb-1">
              <div className="link-active">
                <img src="assets/images/users-menu-select.png" alt="" />
                <span>
                  <p>{total && total}</p>
                  <p>Total Users</p>
                </span>
              </div>
            </div>

            {(() => {
              if (isLoading) {
                return  <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
                          <div className="d-flex align-items-center justify-content-start">
                            <button className="btn link-active " onClick={handleExportCSV} disabled>Export CSV</button>
                          </div>
                        </div>;
              } else {
                return <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
                            <div className="d-flex align-items-center justify-content-start">
                              <button className="btn link-active " onClick={handleExportCSV} >Export CSV</button>
                            </div>
                        </div>;
                      }
            })()} 


            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search  by Name.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      setPage("1");
                    }}
                  />
                </form>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
              <div className="d-flex align-items-center justify-content-start">
              <DateRangePicker
                startDate={startDate} 
                startDateId="your_unique_start_date_id" 
                endDate={endDate} 
                endDateId="your_unique_end_date_id" 
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }} 
                focusedInput={focusedInput} 
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} 
                isOutsideRange={() => false} 
              />
                <div className="clear-date" onClick={clearDate}>
                  <h3>
                    <i className="fas fa-times"></i>
                  </h3>
                </div>
              </div>
          </div>
          </div>
          <hr />

          <div className="col-sm-12 px-0">
            {error && (
              <div
                className={
                  success ? "alert alert-success" : "alert alert-danger"
                }
              >
                {error}
              </div>
            )}
            <div className="tab-content mt-4">
              <div className="table-responsive text-center">
                <table className="table storetable">
                  <thead className="text-center">
                    <tr>
                      <th scope="col">image</th>
                      <th scope="col">first name</th>
                      <th scope="col">last name</th>
                      <th scope="col">email</th>
                      <th scope="col">mobile no</th>
                      <th scope="col">profile percentage</th>
                      <th scope="col">No. of Cars</th>
                      <th scope="col">User type</th>
                      <th scope="col">location</th>
                      <th scope="col">Create At</th>
                      <th scope="col">action</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length > 0 ? (
                      data?.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <img
                              src={
                                item?.userDetails.image?.profile_pic?.includes("http")
                                  ? item?.userDetails.image?.profile_pic
                                  : "assets/images/employe.png"
                              }
                              className="table-img"
                              alt=""
                            />
                          </td>
                          <td>{item?.userDetails.first_name || "NA"}</td>
                          <td>{item?.userDetails.last_name || "NA"}</td>
                          <td className="text-inherit">
                            {item?.userDetails.personal_email || "NA"}
                          </td>
                          <td>
                            {item?.userDetails.mobile
                              ? `${item?.userDetails.countryCode} ${item?.userDetails.mobile}`
                              : "NA"}
                          </td>
                          <td>
                            {`${item?.userDetails.profile_complete_percentage}%` || "NA"}
                          </td>
                          <td>{item?.userDetails.no_of_cars_can_add || "NA"}</td>
                          <td>
                            {item?.userDetails.user_type}
                            {item?.userDetails.user_type && item?.userDetails.canAddOthersCar
                              ? ", "
                              : ""}
                            {item?.userDetails.canAddOthersCar ? "Mechanic" : ""}
                          </td>
                          <td>{item?.userDetails.address || "NA"}</td>
                          <td>
                            {item?.createdAt
                              ? moment(item?.createdAt).format("DD/MM/YYYY")
                              : "NA"}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center action">

                              <button
                                  className="btn" title="View"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    setModalName("requirement Modal");
                                    handleShow();
                                  }}
                                >
                                  <img src="assets/images/view.png" alt="" />
                                </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="12">No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {data?.length > 0 && (
                <Paginations
                  handlePage={handlePage}
                  page={page}
                  total={total && total}
                />
              )}
            </div>
          </div>
        </div>
        {delId && modalName === "requirement Modal" && (
        <RequirementModal show={show} handleShow={handleShow} id={delId} />
      )}
      </article>
    </>
  );
}

export default RequirementList;
