import React from 'react';
import {Link} from 'react-router-dom'
const CarImageDownload = ({ imageUrl, imageName }) => {

  const handleDownload = async () => {
    try {

      const response = await fetch(imageUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Cache-Control': 'no-cache'
        },
        mode: 'cors'
      });
      console.log(response);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const buffer = await response.arrayBuffer();
      const blob = new Blob([buffer], { type: "octet/stream" });
      const url = window.URL.createObjectURL(blob);
      console.log(url);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", imageName);
      document.body.appendChild(link);
      link.click();

      // clear url
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("Failed to download image:", error);
    }


  };

  const buttonStyle = {
    backgroundColor: '#FEC00F',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '12px',
    height: '80px'
  };

  return (
    <>
    <button style={buttonStyle} onClick={handleDownload}>
      <img src="/assets/images/download.png" alt="Download Icon" style={{ width: '0px', height: '30px', marginBottom: '10px' }} />
      Download
    </button>
    </>
    

  );
};

export default CarImageDownload;