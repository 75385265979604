import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../../config/index";

const BASE_URL = SERVER_URL;
//console.log(SERVER_URL);

const initialState = {
  total: 0,
  newTotal: 0,
  approvedTotal: 0,
  rejectedTotal: 0,
  soldTotal: 0,
  carlistings: [],
  newCarList:[],
  acceptedCarList:[],
  rejectedCarList:[],
  soldCarList:[],
  details: {},
  total_offer: 0,
};
export const CarListingsReducer = createAsyncThunk(
  "CarListingsReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/car/getAllCarListForAdmin`,
        {
          params: {
            search: obj.search,
            page: obj.page,
            limit: obj.limit,
            status: obj.status,
            sold_car: obj.SoldCar,
            start_date: obj.startDate,
            end_date: obj.endDate
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const CarDetailReducer = createAsyncThunk(
  "CarDetailReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/car/getCarDetailsForAdmin`,
        {
          params: {
            car_id: obj.id,
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const UpdateCarReducer = createAsyncThunk(
  "UpdateCarReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    // console.log(obj);

    try {
      // let formData = new FormData();
      // for (let key in obj) {
      //   formData.append(key, obj[key]);
      // }

      let status = obj.status.toString();

      let car_id = obj.car_id.toString();

      let data = {status, car_id};

      const response = await axios.patch(
        `${BASE_URL}/car/updateCarStatus`,
        data,
        {
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const DeleteCarReducer = createAsyncThunk(
  "DeleteCarReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");

    // console.log(token);
    try {
      // let formData = new FormData();
      

      // formData.append("car_id", (obj.car_id));

      let car_id = obj.car_id.toString();


      const response = await axios.delete(`${BASE_URL}/car/removeCarByAdmin`, {
        data: {car_id},
        headers: {
          token: token,
        },
      });
      // console.log({formData});
      // console.log("slice data",response?.data)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      console.log({error});
      return error?.message;
    }
  }
);
export const CarlistingSlice = createSlice({
  name: "carlistings",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [CarListingsReducer.fulfilled]: (state, action) => {
      // console.log(action)
      state.carlistings = action?.payload?.data?.car;
      state.total = action?.payload?.data?.total_car;
      state.newTotal = action?.payload?.data?.total_new_car;
      state.newCarList = action?.payload?.data?.total_new_car_list;
      state.approvedTotal = action?.payload?.data?.total_accepted_car;
      state.acceptedCarList = action?.payload?.data?.total_accepted_car_list;
      state.rejectedTotal = action?.payload?.data?.total_rejected_car;
      state.rejectedCarList = action?.payload?.data?.total_rejected_car_list;
      state.soldTotal = action?.payload?.data?.total_sold_car;
      state.soldCarList = action?.payload?.data?.total_sold_car_list;
    },
    [CarDetailReducer.fulfilled]: (state, action) => {
      //console.log(action)
      state.details = action?.payload?.data?.car_details;
      state.total_offer = action?.payload?.data?.total_offer;
    },
  },
});

export const { reducerName } = CarlistingSlice.actions;

export default CarlistingSlice.reducer;
