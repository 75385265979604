import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../../config/index";

const BASE_URL = SERVER_URL;

const initialState = {
  total: 0,
  newTotal: 0,
  approvedTotal: 0,
  rejectedTotal: 0,
  makeoffer: [],
  newOfferList:[],
  approvedOfferList:[],
  rejectedOfferList:[],
  newOfferListData:[],
  approvedOfferListData:[],
  rejectedOfferListData:[],
  details: {},
};
export const MakeOfferReducer = createAsyncThunk(
  "MakeOfferReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/booking/getAllCarOfferListForAdmin`,
        {
          params: {
            search: obj.search,
            page: obj.page,
            limit: obj.limit,
            status: obj.status,
            start_date: obj.startDate,
            end_date: obj.endDate
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("Slice Data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const MakeOfferCSVReducer = createAsyncThunk(
  "MakeOfferCSVReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/booking/getCarOfferListForAdminCSV`,
        {
          params: {
            search: obj.search,
            page: obj.page,
            limit: obj.limit,
            status: obj.status,
            start_date: obj.startDate,
            end_date: obj.endDate
          },
          headers: {
            token: token,
          },
        }
      );
      // console.log("Slice Data",response?.data)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const OfferDetailReducer = createAsyncThunk(
  "OfferDetailReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/booking/getAllCarOfferDetailsForAdmin?`,
        {
          params: {
            offer_id: obj.id,
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const UpdateOfferReducer = createAsyncThunk(
  "UpdateOfferReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      // let formData = new FormData();
      // for (let key in obj) {
      // console.log(obj[key]);

      //   formData.append(key, );
      // }
      
      let status = obj.status.toString();

      let offer_id = obj.offer_id.toString();

      let data = {status, offer_id};
      const response = await axios.patch(
        `${BASE_URL}/booking/approvedRejectOfferByAdmin`,
        data,
        {
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const DeleteOfferReducer = createAsyncThunk(
  "DeleteOfferReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");

    try {
      let formData = new FormData();

      formData.append("offer_id", obj.offer_id);

      const response = await axios.delete(
        `${BASE_URL}/booking/removeOfferByAdmin`,
        {
          data: formData,
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const MakeOfferSlice = createSlice({
  name: "MakeOffer",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [MakeOfferReducer.fulfilled]: (state, action) => {
      //console.log(action)
      state.makeoffer = action?.payload?.data?.offer_list;
      state.total = action?.payload?.data?.total_offer;
      state.newTotal = action?.payload?.data?.total_new_offer;
      state.approvedTotal = action?.payload?.data?.total_accepted_offer;
      state.rejectedTotal = action?.payload?.data?.total_rejected_offer;
      // state.newOfferList = action?.payload?.data?.total_new_offer_list;
      // state.approvedOfferList = action?.payload?.data?.total_approved_offer_list;
      // state.rejectedOfferList = action?.payload?.data?.total_rejected_offer_list;

      state.newOfferListData = action?.payload?.data?.total_new_offer_list_data;
      state.approvedOfferListData = action?.payload?.data?.total_approved_offer_list_data;
      state.rejectedOfferListData = action?.payload?.data?.total_rejected_offer_list_data;
    },
    [OfferDetailReducer.fulfilled]: (state, action) => {
      //console.log(action)
      state.details = action?.payload?.data;
    },
    [MakeOfferCSVReducer.fulfilled]: (state, action) => {
      state.newOfferList = action?.payload?.data?.total_new_offer_list;
      state.approvedOfferList = action?.payload?.data?.total_approved_offer_list;
      state.rejectedOfferList = action?.payload?.data?.total_rejected_offer_list;
    }
  },
});

export const { reducerName } = MakeOfferSlice.actions;

export default MakeOfferSlice.reducer;