import React from "react";
import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CarDetailReducer } from "../features/CarListings/CarListingsSlice";
import { useEffect } from "react";
import moment from "moment";
import currencies from "currency-formatter";

function CarModal({ show, handleShow, id }) {
  const dispatch = useDispatch();
  const detail = useSelector((store) => store.carlistings.details);
  const total_offer = useSelector((store) => store.carlistings.total_offer);
  //console.log(detail);

  const getdetail = useCallback(() => {
    dispatch(
      CarDetailReducer({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleShow();
      }}
      centered
      size="xl"
    >
      <Modal.Body>
        <div className="" role="document">
          <div className="">
            <div className="table-responsive">
              <table className="table storetable text-center">
                <thead className="">
                  <tr>
                    <th scope="col">UID</th>
                    <th scope="col">photo</th>
                    <th scope="col">Regn No</th>
                    <th scope="col">Owner Serial</th>
                    <th scope="col">price</th>
                    <th scope="col">OEM/Make</th>
                    <th scope="col">Model</th>
                    <th scope="col">variant</th>
                    <th scope="col">month/year of regn</th>
                    <th scope="col">Listing Date</th>
                    <th scope="col">No Of Offers</th>
                    <th scope="col">Vehicle Views</th>
                    <th scope="col">Certification</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#{detail?.vehicle_UID}</td>
                    <td>
                      <img
                        src={
                          detail?.images?.[0]?.path?.includes("http")
                            ? detail?.images?.[0]?.path
                            : "assets/images/employe.png"
                        }
                        className="table-img"
                        alt=""
                      />
                    </td>
                    <td>{detail?.regn_number || "N/A"}</td>
                    <td>{detail?.owner_serial || "N/A"}</td>
                    <td>
                      {currencies.format(detail?.price, {
                        code: "INR",
                        symbol: "INR ",
                      })}
                    </td>
                    <td>{detail?.brandDetails?.name || "N/A"}</td>
                    <td>{detail?.modelDetails?.name || "N/A"}</td>
                    <td>{detail?.variantDetails?.name || "N/A"}</td>
                    <td>
                      {moment(detail?.reg_year, "MM/YYYY").format("MMM YYYY")}
                    </td>
                    <td>
                      {detail?.createdAt
                        ? moment(detail?.createdAt).format("ll")
                        : null}
                    </td>
                    <td>{total_offer}</td>
                    <td>{detail?.views}</td>
                    <td>
                      <div className="d-grid">
                        {detail?.isReviewed ? (
                          <img
                            src="assets/images/circle_tick.png"
                            width="18px"
                            alt=""
                            className="m-auto"
                          />
                        ) : (
                          ""
                        )}
                        {detail?.isReviewed ? "Seen by Us" : "N/A"}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CarModal;
