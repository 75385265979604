import { configureStore } from "@reduxjs/toolkit";
import AddQuotationSlice from "../component/AddQuotationSlice";
import UpdateStatusSlice from "../component/UpdateStatusSlice";
import DueDiligenceServicesSlice from "../features/AlliedServices/DueDiligenceServices/DueDiligenceServicesSlice";
import EscrowServiceSlice from "../features/AlliedServices/EscrowService/EscrowServiceSlice";
import FastagServiceSlice from "../features/AlliedServices/FastagService/FastagServiceSlice";
import HelpSupportServiceSlice from "../features/AlliedServices/HelpSupportService/HelpSupportServiceSlice";
import HSRPServiceSlice from "../features/AlliedServices/HSRPService/HSRPServiceSlice";
import InsuranceServicesSlice from "../features/AlliedServices/InsuranceServices/InsuranceServicesSlice";
import ParkSellServicesSlice from "../features/AlliedServices/ParkSellServices/ParkSellServicesSlice";
import PriceConsultationServiceSlice from "../features/AlliedServices/PriceConsultationService/PriceConsultationServiceSlice";
import RefurbSupportServicesSlice from "../features/AlliedServices/RefurbSupportServices/RefurbSupportServicesSlice";
import RepairEstimatesServicesSlice from "../features/AlliedServices/RepairEstimatesServices/RepairEstimatesServicesSlice";
import RSAServicesSlice from "../features/AlliedServices/RSAServices/RSAServicesSlice";
import RTOServicesSlice from "../features/AlliedServices/RTOServices/RTOServicesSlice";
import UsedCarLoanSlice from "../features/AlliedServices/UsedCarLoan/UsedCarLoanSlice";
import ValuatorOnCallServicesSlice from "../features/AlliedServices/ValuatorOnCallServices/ValuatorOnCallServicesSlice";
import WarrantyServicesSlice from "../features/AlliedServices/WarrantyServices/WarrantyServicesSlice";
import BookingSlice from "../features/Booking/BookingSlice";
import CarListingsSlice from "../features/CarListings/CarListingsSlice";
import LoginSlice from "../features/login/LoginSlice";
import MakeOfferSlice from "../features/makeoffer/MakeOfferSlice";
import UserSlice from "../features/users/UserSlice";
import RequirementSlice from "../features/Requirements/RequirementSlice";

export const store = configureStore({
  reducer: {
    carlistings: CarListingsSlice,
    login: LoginSlice,
    MakeOffer: MakeOfferSlice,
    DueDiligence: DueDiligenceServicesSlice,
    EscrowServicelistings: EscrowServiceSlice,
    FastagServicelistings: FastagServiceSlice,
    HelpSupportService: HelpSupportServiceSlice,
    HSRPService: HSRPServiceSlice,
    InsuranceServices: InsuranceServicesSlice,
    ParkSellServices: ParkSellServicesSlice,
    PriceConsultationService: PriceConsultationServiceSlice,
    RefurbSupportServices: RefurbSupportServicesSlice,
    RepairEstimatesServices: RepairEstimatesServicesSlice,
    RSAServices: RSAServicesSlice,
    RTOServices: RTOServicesSlice,
    UsedCarLoan: UsedCarLoanSlice,
    ValuatorOnCallServices: ValuatorOnCallServicesSlice,
    WarrantyServices: WarrantyServicesSlice,
    AddQuotations: AddQuotationSlice,
    UpdateStatus: UpdateStatusSlice,
    Booking: BookingSlice,
    User: UserSlice,
    Requirement: RequirementSlice,
  },
});
