import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";

function Header(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  useEffect(() => {
    $(".humberger__open").on("click", function () {
      $(this).toggleclassName("on");
      $(".header__menu").slideToggle();
    });
  }, []);

  async function logout(e) {
    await localStorage.removeItem("token");
    navigate("/");
  }

  return (
    <div className="col-lg-3 store-sidebar admin-sidebar">
      <div className="shadow-part">
        <span className="navbar-brand bg-none px-3 mx-lg-auto d-lg-block">
          <img src="assets/images/logo.png" className="img-fluid" alt="img" />
        </span>
        <button
          className="btn btn-blue float-right menu-toggle d-lg-none"
          data-target="#sidebar"
          data-toggle="collapse"
        >
          {" "}
          <i className="fa fa-bars"> </i>
        </button>
        <nav className=" collapse d-lg-block" id="sidebar">
          <ul className="list-unstyled mb-0">
            <li>
              <Link
                to="/car-listings"
                className={pathname.includes("car") ? "active" : ""}
              >
                <div>
                  <img
                    src="assets/images/car-listings.png"
                    className="img-fluid sidebar-icon inactive-icon"
                    alt=""
                  />
                  <img
                    src="assets/images/car-listings-selected.png"
                    className="img-fluid sidebar-icon active-icon"
                    alt=""
                  />
                </div>
                <span>car listings </span>
              </Link>
            </li>
            <li>
              <Link
                to="/requirements"
                className={pathname.includes("requirement") ? "active" : ""}
              >
                <div>
                  <img width={25} height={25}
                    src="assets/images/requirement.png"
                    className="img-fluid sidebar-icon inactive-icon"
                    alt=""
                  />
                  <img width={25} height={25}
                    src="assets/images/seleted-requirement.png"
                    className="img-fluid sidebar-icon active-icon"
                    alt=""
                  />
                </div>
                <span>Requirement </span>
              </Link>
            </li>
            <li>
              <Link
                to="/make-offer"
                className={pathname.includes("offer") ? "active" : ""}
              >
                <div>
                  <img
                    src="assets/images/makeOffer.png"
                    className="img-fluid sidebar-icon inactive-icon"
                    alt=""
                  />
                  <img
                    src="assets/images/makeOffer-selected.png"
                    className="img-fluid sidebar-icon active-icon"
                    alt=""
                  />
                </div>
                <span>make a offer </span>
              </Link>
            </li>

            <li className={pathname.includes("service") ? "sb-act" : ""}>
              <a
                href="#"
                className={
                  pathname.includes("service")
                    ? "show-collape-border active"
                    : "show-collape-border collapsed "
                }
                data-target="#Allied-Services"
                data-toggle="collapse"
              >
                <div>
                  <img
                    src="assets/images/AlliedServices-selected.png"
                    className="img-fluid sidebar-icon inactive-icon"
                    alt=""
                  />
                  <img
                    src="assets/images/AlliedServices.png"
                    className="img-fluid sidebar-icon active-icon"
                    alt=""
                  />
                </div>
                <span>Allied Services </span>
              </a>
              <div className="collapse collapse-border" id="Allied-Services">
                <Link
                  to="/RTO-service"
                  className={
                    pathname.includes("RTO-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>RTO-Service</span>
                </Link>
                <Link
                  to="/used-car-loan-service"
                  className={
                    pathname.includes("used-car-loan-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Used Car Loan</span>
                </Link>
                <Link
                  to="/insurance-service"
                  className={
                    pathname.includes("insurance-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Insurance Service</span>
                </Link>
                <Link
                  to="/warranty-service"
                  className={
                    pathname.includes("warranty-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Warranty Service</span>
                </Link>
                <Link
                  to="/RSA-service"
                  className={
                    pathname.includes("RSA-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>RSA Service</span>
                </Link>
                <Link
                  to="/valuator-on-call-service"
                  className={
                    pathname.includes("valuator-on-call-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Valuator On Call</span>
                </Link>
                <Link
                  to="/due-diligence-service"
                  className={
                    pathname.includes("due-diligence-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Due Diligence</span>
                </Link>
                <Link
                  to="/repair-estimates-service"
                  className={
                    pathname.includes("repair-estimates-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Repair Estimates</span>
                </Link>
                <Link
                  to="/park-sell-service"
                  className={
                    pathname.includes("park-sell-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Park & Sell</span>
                </Link>
                <Link
                  to="/refurb-support-service"
                  className={
                    pathname.includes("refurb-support-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Refurb Support</span>
                </Link>
                <Link
                  to="/price-consultation-service"
                  className={
                    pathname.includes("price-consultation-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Price Consultation Service</span>
                </Link>
                <Link
                  to="/hsrp-service"
                  className={
                    pathname.includes("hsrp-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>HSRP</span>
                </Link>
                <Link
                  to="/fastag-service"
                  className={
                    pathname.includes("fastag-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Fastag</span>
                </Link>
                <Link
                  to="/escrow-service"
                  className={
                    pathname.includes("escrow-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Escrow Service</span>
                </Link>
                <Link
                  to="/help-support-service"
                  className={
                    pathname.includes("help-support-service")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>Help & Support</span>
                </Link>
              </div>
            </li>
            <li>
              <Link
                to="/bookings"
                className={pathname.includes("booking") ? "active" : ""}
              >
                <div>
                  <img
                    src="assets/images/makeOffer.png"
                    className="img-fluid sidebar-icon inactive-icon"
                    alt=""
                  />
                  <img
                    src="assets/images/makeOffer-selected.png"
                    className="img-fluid sidebar-icon active-icon"
                    alt=""
                  />
                </div>
                <span>bookings </span>
              </Link>
            </li>
            <li>
              <Link
                to="/users"
                className={pathname.includes("user") ? "active" : ""}
              >
                <div>
                  <img
                    src="assets/images/users-menu.png"
                    className="img-fluid sidebar-icon inactive-icon"
                    alt=""
                  />
                  <img
                    src="assets/images/users-menu-select.png"
                    className="img-fluid sidebar-icon active-icon"
                    alt=""
                  />
                </div>
                <span>users </span>
              </Link>
            </li>
            <li
              className={pathname.includes("change-password") ? "sb-act" : ""}
            >
              <a
                href="#"
                className={
                  pathname.includes("change-password")
                    ? "show-collape-border active"
                    : "show-collape-border collapsed "
                }
                data-target="#setting"
                data-toggle="collapse"
              >
                <div>
                  <img
                    src="assets/images/Settings.png"
                    className="img-fluid sidebar-icon inactive-icon"
                    alt=""
                  />
                  <img
                    src="assets/images/Settings-selected.png"
                    className="img-fluid sidebar-icon active-icon"
                    alt=""
                  />
                </div>
                <span>setting </span>
              </a>
              <div className="collapse collapse-border" id="setting">
                <Link
                  to="/change-password"
                  className={
                    pathname.includes("change-password")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                >
                  <span>change password</span>
                </Link>
                <Link
                  className="dropdown-item cursor-pointer"
                  to={() => {
                    return false;
                  }}
                  onClick={(e) => {
                    logout(e);
                  }}
                >
                  <span>
                    logout <i className="fa fa-sign-out" aria-hidden="true"></i>
                  </span>
                </Link>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
