import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../../config/index";

const BASE_URL = SERVER_URL;

const initialState = {
  total: 0,
  user: [],
  details: {},
  userList : [],
};
export const UserReducer = createAsyncThunk(
  "UserReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/admin/userList`, {
        params: {
          search: obj.search,
          page: obj.page,
          limit: obj.limit,
          start_date: obj.startDate,
          end_date: obj.endDate
          //user_type: obj.user_type,
        },
        headers: {
          token: token,
        },
      });
      // console.log("Slice Data",{response});
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const UserListReducer = createAsyncThunk(
  "UserListReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/admin/allUserList`, {
        params: {
          start_date: obj.startDate,
          end_date: obj.endDate
        },
        headers: {
          token: token,
        },
      });
      // console.log("Slice list Data",{response});
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const UserDetailReducer = createAsyncThunk(
  "UserDetailReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/getUserDetails/${obj.id}`,
        {
          params: {
            //deal_id: obj.id,
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const UpdateUserReducer = createAsyncThunk(
  "UpdateUserReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      const response = await axios.patch(
        `${BASE_URL}/booking/approvedRejectBookingByAdmin`,
        formData,
        {
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const AddNoCarsReducer = createAsyncThunk(
  "AddNoCarsReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      // let formData = new FormData();
      // for (let key in obj) {
      //   formData.append(key, obj[key]);
      //   console.log(key, obj[key]);
      // }
      // console.log(formData);

      let user_id = obj.user_id.toString();
      let no_of_cars_can_add = obj.no_of_cars_can_add.toString();

      let data = {user_id, no_of_cars_can_add};
      const response = await axios.patch(
        `${BASE_URL}/admin/allowUserToAddOtherCar`,
        data,
        {
          headers: {
            token: token,
          },
        }
      );
      // console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);
export const MechanicalPermissionReducer = createAsyncThunk(
  "MechanicalPermissionReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      // let formData = new FormData();
      // for (let key in obj) {
      //   formData.append(key, obj[key]);

      //   console.log(key, obj[key]);
      // }

      let user_id = obj.user_id;
      let canAddOthersCar = obj.canAddOthersCar.toString();

      let data = {user_id, canAddOthersCar};
      const response = await axios.patch(
        `${BASE_URL}/admin/allowUserToAddOtherCar`,
        data,
        {
          headers: {
            token: token,
          },
        }
      );
      // console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const RFDUserReducer = createAsyncThunk(
  "RFDUserReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      const response = await axios.put(
        `${BASE_URL}/booking/approveRejectBooking`,
        formData,
        {
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const BookingDeleteReducer = createAsyncThunk(
  "BookingDeleteReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      let formData = new FormData();
      formData.append("deal_id", obj.deal_id);
      const response = await axios.delete(
        `${BASE_URL}/booking/removeCarDeal`,

        {
          data: formData,
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [UserReducer.fulfilled]: (state, action) => {
      //console.log(action)
      state.user = action?.payload?.data?.user_list;
      state.total = action?.payload?.data?.total_active_user;
    },
    [UserListReducer.fulfilled]: (state, action) => {
      //console.log(action)
      
      state.userList = action?.payload?.data.all_user_list;
    },
    [UserDetailReducer.fulfilled]: (state, action) => {
      //console.log(action)
      state.details = action?.payload?.data;
    },
  },
});

export const { reducerName } = UserSlice.actions;

export default UserSlice.reducer;
