import { Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { forgotPasswordreducer, togglesuccess } from "./LoginSlice";

function ForgotPassword() {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  let success = useSelector((state) => state.login.success);

  return (
    <section className="">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-5 col-md-5 col-lg-6 text-center">
            <div className="logo-bg">
              <div className="mt-2">
                <img
                  src="assets/images/logo.png"
                  className="img-fluid lft-img"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-7 col-md-7 col-lg-6 mr-0 pr-0">
            <div className="form-bg">
              <Formik
                initialValues={{
                  email: "",
                }}
                enableReinitialize={true}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Email must be valid email id.")
                    .required("Required"),
                })}
                onSubmit={(values, { isSubmitting, resetForm }) => {
                  //console.log(values);
                  dispatch(
                    forgotPasswordreducer({
                      email: values.email,
                    })
                  ).then((data) => {
                    // console.log(data);
                    if (data?.payload?.code === 1) {
                      dispatch(togglesuccess(true));
                      setError(data?.payload?.message);
                      setTimeout(() => {
                        setError("");
                        navigate("/otp-verification", {
                          replace: true,
                        });
                      }, 3000);
                    } else {
                      dispatch(togglesuccess(false));
                      setError(data?.payload?.message);
                      setTimeout(() => {
                        setError("");
                      }, 3000);
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFormikState,
                }) => (
                  <form
                    className="form-style"
                    onSubmit={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSubmit();
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-12 text-center mb-4">
                        <h2 className="mdl-ttl">Forgot Password</h2>
                        <img
                          src="assets/images/forgot-password.png"
                          className="img-fluid my-3"
                          alt=""
                        />
                        <p className="mdl-sbttl">
                          Enter your registered email address.
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-12 mb-4">
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              type="email"
                              className="form-control pl-5"
                              placeholder="Email Address"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <img
                              src="assets/images/Email-Address.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                          {errors.email && touched.email && (
                            <div className="text-red"> {errors.email}</div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12 text-center">
                        <button type="submit" className="btn btn-save">
                          Submit
                        </button>
                      </div>
                      {error && (
                        <div
                          className={
                            success
                              ? "col-sm-12 col-md-12 alert alert-success text-center mt-2 text-capitalize"
                              : "col-sm-12 col-md-12 alert alert-danger text-center mt-2 text-capitalize"
                          }
                        >
                          {error}
                        </div>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
