import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Paginations from "../../component/Pagination";
import {
  CarListingsReducer,
  DeleteCarReducer,
  UpdateCarReducer,
} from "./CarListingsSlice";
import moment from "moment";
import currencies from "currency-formatter";
import { togglesuccess } from "../login/LoginSlice";
import DeleteModal from "../../component/DeleteModal";
import UserModal from "../../component/UserModal";
import Papa from 'papaparse';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

function CarListings() {
  const dispatch = useDispatch();
  const success = useSelector((store) => store.login.success);
  const [page, setPage] = useState(1);
  const total = useSelector((state) => state.carlistings.total);
  const newTotal = useSelector((state) => state.carlistings.newTotal);
  const approvedTotal = useSelector((state) => state.carlistings.approvedTotal);
  const rejectedTotal = useSelector((state) => state.carlistings.rejectedTotal);
  const soldTotal = useSelector((state) => state.carlistings.soldTotal);
  const data = useSelector((state) => state.carlistings.carlistings);
  const newCarList = useSelector((state) => state.carlistings.newCarList);
  const approvedCarList = useSelector((state) => state.carlistings.acceptedCarList);
  const rejectedCarList = useSelector((state) => state.carlistings.rejectedCarList);
  const soldCarList = useSelector((state) => state.carlistings.soldCarList);
  const [status, setStatus] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [delId, setDelId] = useState("");
  const [modalName, setModalName] = useState("");
  const [SoldCar, setSoldCar] = useState(0);
  const handleShow = () => {
    setShow(!show);
  };

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [focusedInput, setFocusedInput] = useState('');

  let start_date = startDate;
  let end_date = endDate; 

  const handlePage = (value) => {
    setPage(value);
  };
  const getlist = useCallback(async () => {
    // dispatch(
    //   CarListingsReducer({
    //     search: searchValue,
    //     page: page,
    //     limit: 10,
    //     status: status,
    //     SoldCar: SoldCar,
    //   })
    // );

    const params = {
      search: searchValue,
      page: page,
      limit: 10,
      status: status,
      SoldCar: SoldCar,
    };
    if (start_date) {
      params.startDate = start_date._d.toISOString();
    }
    if (end_date) {
      params.endDate = end_date._d.toISOString();
    }
    dispatch(CarListingsReducer(params));
  }, [dispatch, searchValue, page, status, SoldCar, start_date, end_date]);

  const approvedReject = (data) => {
    //console.log(data);
    dispatch(UpdateCarReducer(data)).then((res) => {
      if (res?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const confirmDelete = () => {
    dispatch(DeleteCarReducer({ car_id: delId })).then((res) => {
      if (res?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlist();
    }
    return () => {
      mounted = false;
    };
  }, [getlist]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeLink, setActiveLink] = useState('new');

  // Function to handle link clicks
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const currentDate = moment().format('lll');

  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };

  const handleExportCSV = () => {
    let activeList;
    switch (activeLink) {
      case 'new':
        activeList = newCarList;
        break;
      case 'approved':
        activeList = approvedCarList;
        break;
      case 'rejected':
        activeList = rejectedCarList;
        break;
      case 'sold cars':
        activeList = soldCarList;
        break;
      default:
        activeList = newCarList;
    }

    let activeListName;

    switch (activeLink) {
      case 'new':
        activeListName = "new_car_list";
        break;
      case 'approved':
        activeListName = "approved_car_list";
        break;
      case 'rejected':
        activeListName = "rejected_car_list";
        break;
      case 'sold cars':
        activeListName = "sold_car_list";
        break;
      default:
        activeListName = "new_car_list";
    }

    const detail_active_car_list = [];
    for (let i = 0; i < activeList.length; i++) {
      let detail_car_list = {};

      detail_car_list.vehicle_UID = activeList[i].vehicle_UID;
      detail_car_list.Photo = JSON.stringify(activeList[i].image);
      detail_car_list.regn_number = activeList[i].regn_number;
      detail_car_list.owner_serial = activeList[i].owner_serial;
      detail_car_list.price = activeList[i].price;
      detail_car_list.OEM_Make = activeList[i]?.brand;
      detail_car_list.model = activeList[i]?.model;
      detail_car_list.variant = activeList[i]?.variant;
      detail_car_list.Month_Year_Of_Regn = activeList[i].reg_year;
      detail_car_list.listing_date = activeList[i].createdAt ? moment(activeList[i]?.createdAt).format('ll') : "N/A";
      detail_car_list.seller_name = activeList[i]?.sellerDetails?.first_name? `${activeList[i]?.sellerDetails?.first_name} ${activeList[i]?.sellerDetails?.last_name}`: "N/A";
      detail_car_list.seller_mobile_no = activeList[i]?.sellerDetails?.mobile ? `${activeList[i]?.sellerDetails?.countryCode} ${activeList[i]?.sellerDetails?.mobile}`: "N/A";
      detail_car_list.car_short_detail_No_Of_Offers = total;
      detail_car_list.car_short_detail_Vehicle_Views = activeList[i]?.views;
      detail_car_list.car_short_detail_Certification = activeList[i]?.isReviewed ? "Seen by Us" : "N/A";
      detail_car_list.fuel = activeList[i]?.fuel_type;
      detail_car_list.Gear_Type = activeList[i]?.transmission;
      detail_car_list.km_meter_reading = activeList[i]?.km_meter_reading;
      detail_car_list.number_plate = activeList[i]?.number_plate;
      detail_car_list.Current_Car_Location = activeList[i]?.address;
      detail_car_list.comprehensive_insurance_validity = activeList[i]?.car_details[0].comprehensive_insurance_validity  || "NA";
      detail_car_list.body_shape = activeList[i]?.car_details[0].body_shape;
      detail_car_list.color = activeList[i]?.car_details[0]?.colourDetails?.name || "NA";
      detail_car_list.accidental_status = activeList[i]?.car_details[0].accidental_status || "NA";
      detail_car_list.createdAt = activeList[i].createdAt ? moment(activeList[i]?.createdAt).format('ll') : "N/A";

      detail_active_car_list.push(detail_car_list);
    }

    
    const csvData = convertToCSV(detail_active_car_list);

    // Create a Blob and a link to trigger the download
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${activeListName}_${currentDate}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  };

  const isLoading = data.length == 0;

  const clearDate = () => {

    setEndDate('');
    setStartDate('');
  }
  return (
    <>
     <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-reverse">
          {/* <div className="col-sm-12 col-md-6 col-lg-6 mb-3"> */}
          <div className="col-sm-12 col-md-2 col-lg-2 mb-1">
            <div className="link-active">
              <img src="assets/images/car-listings-selected.png" alt="" />
              <span>
                <p>{total && total}</p>
                <p>Total Vehicle</p>
              </span>
            </div>
          </div>

          {(() => {
              if (isLoading) {
                return  <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
                          <div className="d-flex align-items-center justify-content-start">
                            <button className="btn link-active " onClick={handleExportCSV} disabled>Export CSV</button>
                          </div>
                        </div>;
              } else {
                return <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
                            <div className="d-flex align-items-center justify-content-start">
                              <button className="btn link-active " onClick={handleExportCSV} >Export CSV</button>
                            </div>
                        </div>;
                      }
            })()}

          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search vehicle by uid/name"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setPage("1");
                  }}
                />
              </form>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
              <div className="d-flex align-items-center justify-content-start">
              <DateRangePicker
                startDate={startDate} 
                startDateId="your_unique_start_date_id" 
                endDate={endDate} 
                endDateId="your_unique_end_date_id" 
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }} 
                focusedInput={focusedInput} 
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} 
                isOutsideRange={() => false} 
              />
                <div className="clear-date" onClick={clearDate}>
                  <h3>
                    <i className="fas fa-times"></i>
                  </h3>
                </div>
              </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-12">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <ul className="nav nav-tabs group-nav" role="tablist">
                  <li className="nav-item">
                    <a
                      className={activeLink === 'new' ? 'nav-link active' : 'nav-link'}
                      data-toggle="tab"
                      href="#new-vehicle"
                      onClick={() => {
                        setStatus(0);
                        setPage(1);
                        setSoldCar(0);
                        handleLinkClick('new');
                      }}
                    >
                      new
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={activeLink === 'approved' ? 'nav-link active' : 'nav-link'}
                      data-toggle="tab"
                      href="#approved"
                      onClick={() => {
                        setStatus(1);
                        setPage(1);
                        setSoldCar(0);
                        handleLinkClick('approved');
                      }}
                    >
                      approved
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={activeLink === 'rejected' ? 'nav-link active' : 'nav-link'}
                      data-toggle="tab"
                      href="#rejected"
                      onClick={() => {
                        setStatus(2);
                        setPage(1);
                        setSoldCar(0);
                        handleLinkClick('rejected');
                      }}
                    >
                      rejected
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={activeLink === 'sold cars' ? 'nav-link active' : 'nav-link'}
                      data-toggle="tab"
                      href="#soldCars"
                      onClick={() => {
                        setStatus(1);
                        setPage(1);
                        setSoldCar(1);
                        handleLinkClick('sold cars');
                      }}
                    >
                      sold cars
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            {error && (
              <div
                className={
                  success ? "alert alert-success" : "alert alert-danger"
                }
              >
                {error}
              </div>
            )}
            <div className="tab-content mt-4">
              <div id="new-vehicle" className="tab-pane active">
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">UID</th>
                        <th scope="col">photo</th>
                        <th scope="col">Regn No</th>
                        <th scope="col">Owner Serial</th>
                        <th scope="col">price</th>
                        <th scope="col">OEM/Make</th>
                        <th scope="col">Model</th>
                        <th scope="col">variant</th>
                        <th scope="col">month/year of regn</th>
                        <th scope="col">Listing Date</th>
                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.length > 0 ? (
                        data?.map((item, i) => (
                          <tr key={i}>
                            <td>#{item?.vehicle_UID}</td>
                            <td>
                              <img
                                src={
                                  item?.image?.path?.includes("http")
                                    ? item?.image?.path
                                    : "assets/images/employe.png"
                                }
                                className="table-img"
                                alt=""
                              />
                            </td>
                            <td>{item?.regn_number || "N/A"}</td>
                            <td>{item?.owner_serial || "N/A"}</td>
                            <td>
                              {currencies.format(item?.price, {
                                code: "INR",
                                symbol: "INR ",
                              })}
                            </td>
                            <td>{item?.brand || "N/A"}</td>
                            <td>{item?.model || "N/A"}</td>
                            <td>{item?.variant || "N/A"}</td>
                            <td>
                              {moment(item?.reg_year, "MM/YYYY").format(
                                "MMM YYYY"
                              )}
                            </td>
                            <td>
                              {item?.createdAt
                                ? moment(item?.createdAt).format("ll")
                                : null}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center action">
                                <button
                                  className="btn"
                                  title="User Short Detail"
                                  onClick={() => {
                                    setDelId(item?.userid);
                                    setModalName("User Modal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src="assets/images/userLine.png"
                                    alt=""
                                  />
                                </button>
                                <button className="btn" title="View">
                                  <Link
                                    to={`/user-detail/${item?.userid}`}
                                    target="_blank"
                                  >
                                    {item?.user_type === "seller" ? (
                                      <img
                                        src="assets/images/sellerBlue.png"
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src="assets/images/add_user_users_icon.png"
                                        alt=""
                                      />
                                    )}
                                  </Link>
                                </button>
                                <button className="btn" title="View">
                                  <Link to={`/car-detail/${item?.id}`}>
                                    <img src="assets/images/view.png" alt="" />
                                  </Link>
                                </button>
                                <button
                                  className="btn"
                                  title="Approve"
                                  onClick={() => {
                                    approvedReject({
                                      status: 1,
                                      car_id: String(item?.id),
                                    });
                                  }}
                                >
                                  <img src="assets/images/approve.png" alt="" />
                                </button>
                                <button
                                  className="btn"
                                  title="Reject"
                                  onClick={() => {
                                    approvedReject({
                                      status: 2,
                                      car_id: String(item?.id),
                                    });
                                  }}
                                >
                                  <img src="assets/images/reject.png" alt="" />
                                </button>
                                <button
                                  className="btn"
                                  title="Delete"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    setModalName("delete");
                                    handleShow();
                                  }}
                                >
                                  <img src="assets/images/delete.png" alt="" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="15">No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {data?.length > 0 && (
                  <Paginations
                    handlePage={handlePage}
                    page={page}
                    total={total && total}
                  />
                )}
              </div>
              <div id="approved" className="tab-pane fade">
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">UID</th>
                        <th scope="col">photo</th>
                        <th scope="col">Regn No</th>
                        <th scope="col">Owner Serial</th>
                        <th scope="col">price</th>
                        <th scope="col">OEM/Make</th>
                        <th scope="col">Model</th>
                        <th scope="col">variant</th>
                        <th scope="col">month/year of regn</th>
                        <th scope="col">Listing Date</th>
                        <th scope="col">No Of Offers</th>
                        <th scope="col">Vehicle Views</th>
                        <th scope="col">Certification</th>
                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.length > 0 ? (
                        data?.map((item, i) => (
                          <tr key={i}>
                            <td>#{item?.vehicle_UID}</td>
                            <td>
                              <img
                                src={
                                  item?.image?.path?.includes("http")
                                    ? item?.image?.path
                                    : "assets/images/employe.png"
                                }
                                className="table-img"
                                alt=""
                              />
                            </td>
                            <td>{item?.regn_number || "N/A"}</td>
                            <td>{item?.owner_serial || "N/A"}</td>
                            <td>
                              {currencies.format(item?.price, {
                                code: "INR",
                                symbol: "INR ",
                              })}
                            </td>
                            <td>{item?.brand || "N/A"}</td>
                            <td>{item?.model || "N/A"}</td>
                            <td>{item?.variant || "N/A"}</td>
                            <td>
                              {moment(item?.reg_year, "MM/YYYY").format(
                                "MMM YYYY"
                              )}
                            </td>
                            <td>
                              {item?.createdAt
                                ? moment(item?.createdAt).format("ll")
                                : null}
                            </td>
                            <td>{item?.total_offer}</td>
                            <td>{item?.views}</td>
                            <td>
                              <div className="d-grid">
                                {item?.isReviewed ? (
                                  <img
                                    src="assets/images/circle_tick.png"
                                    width="18px"
                                    alt=""
                                    className="m-auto"
                                  />
                                ) : (
                                  ""
                                )}
                                {item?.isReviewed ? "Seen by Us" : "N/A"}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center action">
                                <button
                                  className="btn"
                                  title="User Short Detail"
                                  onClick={() => {
                                    setDelId(item?.userid);
                                    setModalName("User Modal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src="assets/images/userLine.png"
                                    alt=""
                                  />
                                </button>
                                <button className="btn" title="View">
                                  <Link
                                    to={`/user-detail/${item?.userid}`}
                                    target="_blank"
                                  >
                                    {item?.user_type === "seller" ? (
                                      <img
                                        src="assets/images/sellerBlue.png"
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src="assets/images/add_user_users_icon.png"
                                        alt=""
                                      />
                                    )}
                                  </Link>
                                </button>
                                <button className="btn" title="View">
                                  <Link to={`/car-detail/${item?.id}`}>
                                    <img src="assets/images/view.png" alt="" />
                                  </Link>
                                </button>
                                <button
                                  className="btn"
                                  title="Reject"
                                  onClick={() => {
                                    approvedReject({
                                      status: 2,
                                      car_id: String(item?.id),
                                    });
                                  }}
                                >
                                  <img src="assets/images/reject.png" alt="" />
                                </button>
                                <button
                                  className="btn"
                                  title="Delete"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    setModalName("delete");
                                    handleShow();
                                  }}
                                >
                                  <img src="assets/images/delete.png" alt="" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="15">No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {data?.length > 0 && (
                  <Paginations
                    handlePage={handlePage}
                    page={page}
                    total={total && total}
                  />
                )}
              </div>
              <div id="rejected" className="tab-pane fade">
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">UID</th>
                        <th scope="col">photo</th>
                        <th scope="col">Regn No</th>
                        <th scope="col">Owner Serial</th>
                        <th scope="col">price</th>
                        <th scope="col">OEM/Make</th>
                        <th scope="col">Model</th>
                        <th scope="col">variant</th>
                        <th scope="col">month/year of regn</th>
                        <th scope="col">Listing Date</th>
                        <th scope="col">No Of Offers</th>
                        <th scope="col">Vehicle Views</th>
                        <th scope="col">Certification</th>
                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.length > 0 ? (
                        data?.map((item, i) => (
                          <tr key={i}>
                            <td>#{item?.vehicle_UID}</td>
                            <td>
                              <img
                                src={
                                  item?.image?.path?.includes("http")
                                    ? item?.image?.path
                                    : "assets/images/employe.png"
                                }
                                className="table-img"
                                alt=""
                              />
                            </td>
                            <td>{item?.regn_number || "N/A"}</td>
                            <td>{item?.owner_serial || "N/A"}</td>
                            <td>
                              {currencies.format(item?.price, {
                                code: "INR",
                                symbol: "INR ",
                              })}
                            </td>
                            <td>{item?.brand || "N/A"}</td>
                            <td>{item?.model || "N/A"}</td>
                            <td>{item?.variant || "N/A"}</td>
                            <td>
                              {moment(item?.reg_year, "MM/YYYY").format(
                                "MMM YYYY"
                              )}
                            </td>
                            <td>
                              {item?.createdAt
                                ? moment(item?.createdAt).format("ll")
                                : null}
                            </td>
                            <td>{item?.total_offer}</td>
                            <td>{item?.views}</td>
                            <td>
                              <div className="d-grid">
                                {item?.isReviewed ? (
                                  <img
                                    src="assets/images/circle_tick.png"
                                    width="18px"
                                    alt=""
                                    className="m-auto"
                                  />
                                ) : (
                                  ""
                                )}
                                {item?.isReviewed ? "Seen by Us" : "N/A"}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center action">
                                <button
                                  className="btn"
                                  title="User Short Detail"
                                  onClick={() => {
                                    setDelId(item?.userid);
                                    setModalName("User Modal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src="assets/images/userLine.png"
                                    alt=""
                                  />
                                </button>
                                <button className="btn" title="View">
                                  <Link
                                    to={`/user-detail/${item?.userid}`}
                                    target="_blank"
                                  >
                                    {item?.user_type === "seller" ? (
                                      <img
                                        src="assets/images/sellerBlue.png"
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src="assets/images/add_user_users_icon.png"
                                        alt=""
                                      />
                                    )}
                                  </Link>
                                </button>
                                <button className="btn" title="View">
                                  <Link to={`/car-detail/${item?.id}`}>
                                    <img src="assets/images/view.png" alt="" />
                                  </Link>
                                </button>
                                <button
                                  className="btn"
                                  title="Approve"
                                  onClick={() => {
                                    approvedReject({
                                      status: 1,
                                      car_id: String(item?.id),
                                    });
                                  }}
                                >
                                  <img src="assets/images/approve.png" alt="" />
                                </button>
                                <button
                                  className="btn"
                                  title="Delete"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    setModalName("delete");
                                    handleShow();
                                  }}
                                >
                                  <img src="assets/images/delete.png" alt="" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="15">No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {data?.length > 0 && (
                  <Paginations
                    handlePage={handlePage}
                    page={page}
                    total={rejectedTotal && rejectedTotal}
                  />
                )}
              </div>
              <div id="soldCars" className="tab-pane fade">
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">UID</th>
                        <th scope="col">photo</th>
                        <th scope="col">Regn No</th>
                        <th scope="col">Owner Serial</th>
                        <th scope="col">price</th>
                        <th scope="col">OEM/Make</th>
                        <th scope="col">Model</th>
                        <th scope="col">variant</th>
                        <th scope="col">month/year of regn</th>
                        <th scope="col">Listing Date</th>
                        <th scope="col">No Of Offers</th>
                        <th scope="col">Vehicle Views</th>
                        <th scope="col">Certification</th>
                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.length > 0 ? (
                        data?.map((item, i) => (
                          <tr>
                            <td>#{item?.vehicle_UID}</td>
                            <td>
                              <img
                                src={
                                  item?.image?.path?.includes("http")
                                    ? item?.image?.path
                                    : "assets/images/employe.png"
                                }
                                className="table-img"
                                alt=""
                              />
                            </td>
                            <td>{item?.regn_number || "N/A"}</td>
                            <td>{item?.owner_serial || "N/A"}</td>
                            <td>
                              {currencies.format(item?.price, {
                                code: "INR",
                                symbol: "INR ",
                              })}
                            </td>
                            <td>{item?.brand || "N/A"}</td>
                            <td>{item?.model || "N/A"}</td>
                            <td>{item?.variant || "N/A"}</td>
                            <td>
                              {moment(item?.reg_year, "MM/YYYY").format(
                                "MMM YYYY"
                              )}
                            </td>
                            <td>
                              {item?.createdAt
                                ? moment(item?.createdAt).format("ll")
                                : null}
                            </td>
                            <td>{item?.total_offer}</td>
                            <td>{item?.views}</td>
                            <td>
                              <div className="d-grid">
                                {item?.isReviewed ? (
                                  <img
                                    src="assets/images/circle_tick.png"
                                    width="18px"
                                    alt=""
                                    className="m-auto"
                                  />
                                ) : (
                                  ""
                                )}
                                {item?.isReviewed ? "Seen by Us" : "N/A"}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center action">
                                <button
                                  className="btn"
                                  title="User Short Detail"
                                  onClick={() => {
                                    setDelId(item?.userid);
                                    setModalName("User Modal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src="assets/images/userLine.png"
                                    alt=""
                                  />
                                </button>
                                <button className="btn" title="View">
                                  <Link
                                    to={`/user-detail/${item?.userid}`}
                                    target="_blank"
                                  >
                                    {item?.user_type === "seller" ? (
                                      <img
                                        src="assets/images/sellerBlue.png"
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src="assets/images/add_user_users_icon.png"
                                        alt=""
                                      />
                                    )}
                                  </Link>
                                </button>
                                <button className="btn" title="View">
                                  <Link to={`/car-detail/${item?.id}`}>
                                    <img src="assets/images/view.png" alt="" />
                                  </Link>
                                </button>
                                {/* <button
                                  className="btn"
                                  title="Approve"
                                  onClick={() => {
                                    approvedReject({
                                      status: 1,
                                      car_id: String(item?.id),
                                    });
                                  }}
                                >
                                  <img src="assets/images/approve.png" alt="" />
                                </button>
                                <button
                                  className="btn"
                                  title="Delete"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    setModalName("delete")
                                    handleShow();
                                  }}
                                >
                                  <img src="assets/images/delete.png" alt="" />
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="15">No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {data?.length > 0 && (
                  <Paginations
                    handlePage={handlePage}
                    page={page}
                    total={total && total}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {delId && modalName === "delete" && (
        <DeleteModal
          page="Car"
          show={show}
          handleShow={handleShow}
          confirmDelete={confirmDelete}
        />
      )}
      {delId && modalName === "User Modal" && (
        <UserModal show={show} handleShow={handleShow} id={delId} />
      )}
    </article>
    </>
   
  );
}

export default CarListings;

