import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddQuotation from "../../../component/AddQuotation";
import ViewImageModal from "../../../component/ViewImageModal";
import { HelpSupportServiceDetailReducer } from "./HelpSupportServiceSlice";

function HelpSupportServiceRequests() {
  const [ReqId, setReqId] = useState("");
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [imgSRC, setimgSRC] = useState("");
  const [imgName, setimgName] = useState("");
  const [img, setimg] = useState({});
  const handleShow = () => {
    setShow(!show);
  };
  const { id } = useParams();
  const dispatch = useDispatch();

  const detail = useSelector((store) => store.HelpSupportService.details);
  //console.log(detail);

  const getdetail = useCallback(() => {
    dispatch(
      HelpSupportServiceDetailReducer({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h2 className="head-title">Help & Support</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-4">
              <h2>User Detail</h2>
            </div>

            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="table-responsive pt-4">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/Full-Name.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          First Name
                        </td>
                        <td>
                          <img
                            src="assets/images/Full-Name.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Last Name
                        </td>
                        <td>
                          <img
                            src="assets/images/mobile-nu.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Mobile Number{" "}
                        </td>
                        <td>
                          <img
                            src="assets/images/Email-Address.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          personal Email
                        </td>
                        <td>
                          <img
                            src="assets/images/Email-Address.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          official Email
                        </td>
                      </tr>
                      <tr>
                        <th>{detail?.userDetails?.first_name || "NA"}</th>
                        <th>{detail?.userDetails?.last_name || "NA"}</th>
                        <th>
                          {detail?.userDetails?.mobile
                            ? `${detail?.userDetails?.countryCode} ${detail?.userDetails?.mobile}`
                            : "NA"}
                        </th>
                        <th>{detail?.userDetails?.personal_email || "NA"}</th>
                        <th>{detail?.userDetails?.official_email || "NA"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">Query Related To</h3>
                    <p>{detail?.queryDetails?.name || "NA"}</p>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">
                      Description Of Issue
                    </h3>
                    <p>{detail?.description || "NA"}</p>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">
                      Relevant Documents
                    </h3>
                    <img
                      src={detail?.document || "assets/images/employe.png"}
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        setimgSRC("document");
                        setimgName(detail?.document);
                        handleShow();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-sm-12">
              <div className="view-btns">
                {detail?.quotation_pdf === null ? (
                  <button
                    className="btn btn-reject btn-view mt-2"
                    onClick={() => {
                      setReqId(detail?.id);
                      setModalName("AddQuotation");
                      handleShow();
                    }}
                  >
                    Add Quotation
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div> */}
          </div>
        </div>
        {show && modalName === "AddQuotation" && (
          <AddQuotation
            show={show}
            handleShow={handleShow}
            ReqId={ReqId}
            getList={getdetail}
          />
        )}
        {detail[imgSRC.split(".")[0]] &&
          imgSRC &&
          modalName === "ViewImage" && (
            <ViewImageModal
              show={show}
              handleShow={handleShow}
              imgSRC={imgSRC}
              image={detail}
              setitem={setimg}
              setimgSRC={setimgSRC}
              imageName={imgName}
            />
          )}
      </article>
    </>
  );
}

export default HelpSupportServiceRequests;
