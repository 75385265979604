import { Routes, Route } from "react-router-dom";
import Header from "../component/Header";
import Home from "../features/login/Login";
import ForgotPassword from "./../features/login/ForgotPassword";
import ResetPassword from "./../features/login/ResetPassword";
import OTPVerification from "./../features/login/OTPVerification";
import PrivateRoute from "./../routes/PrivateRoute";
import CarListings from "../features/CarListings/CarListings";
import ChangePassword from "../features/login/ChangePassword";
import MakeOffer from "../features/makeoffer/MakeOffer";
import CarDetail from "../features/CarListings/CarDetail";
import OfferDetail from "../features/makeoffer/OfferDetail";
import RTOServices from "../features/AlliedServices/RTOServices/RTOServices";
import UsedCarLoan from "../features/AlliedServices/UsedCarLoan/UsedCarLoan";
import InsuranceServices from "../features/AlliedServices/InsuranceServices/InsuranceServices";
import RTOServicesRequests from "../features/AlliedServices/RTOServices/RTOServicesRequests";
import UsedCarLoanRequests from "../features/AlliedServices/UsedCarLoan/UsedCarLoanRequests";
import InsuranceServicesRequests from "../features/AlliedServices/InsuranceServices/InsuranceServicesRequests";
import WarrantyServices from "../features/AlliedServices/WarrantyServices/WarrantyServices";
import WarrantyServicesRequests from "../features/AlliedServices/WarrantyServices/WarrantyServicesRequests";
import RSAServices from "../features/AlliedServices/RSAServices/RSAServices";
import RSAServicesRequests from "../features/AlliedServices/RSAServices/RSAServicesRequests";
import ValuatorOnCallServices from "../features/AlliedServices/ValuatorOnCallServices/ValuatorOnCallServices";
import ValuatorOnCallRequests from "../features/AlliedServices/ValuatorOnCallServices/ValuatorOnCallRequests";
import DueDiligenceServices from "../features/AlliedServices/DueDiligenceServices/DueDiligenceServices";
import DueDiligenceRequests from "../features/AlliedServices/DueDiligenceServices/DueDiligenceRequests";
import RepairEstimatesServices from "../features/AlliedServices/RepairEstimatesServices/RepairEstimatesServices";
import RepairEstimatesServicesEequests from "../features/AlliedServices/RepairEstimatesServices/RepairEstimatesServicesEequests";
import ParkSellServices from "../features/AlliedServices/ParkSellServices/ParkSellServices";
import RefurbSupportServices from "../features/AlliedServices/RefurbSupportServices/RefurbSupportServices";
import RefurbSupportServicesRequests from "../features/AlliedServices/RefurbSupportServices/RefurbSupportServicesRequests";
import PriceConsultationServiceRequests from "../features/AlliedServices/PriceConsultationService/PriceConsultationServiceRequests";
import PriceConsultationService from "../features/AlliedServices/PriceConsultationService/PriceConsultationService";
import HSRPService from "../features/AlliedServices/HSRPService/HSRPService";
import HSRPServiceRequests from "../features/AlliedServices/HSRPService/HSRPServiceRequests";
import FastagService from "../features/AlliedServices/FastagService/FastagService";
import FastagServiceRequests from "../features/AlliedServices/FastagService/FastagServiceRequests";
import EscrowService from "../features/AlliedServices/EscrowService/EscrowService";
import EscrowServiceRequests from "../features/AlliedServices/EscrowService/EscrowServiceRequests";
import HelpSupportService from "../features/AlliedServices/HelpSupportService/HelpSupportService";
import HelpSupportServiceRequests from "../features/AlliedServices/HelpSupportService/HelpSupportServiceRequests";
import ParkSellServicesRequests from "../features/AlliedServices/ParkSellServices/ParkSellServicesRequests";
import Booking from "../features/Booking/Booking";
import BookingDetail from "../features/Booking/BookingDetail";
import UserList from "../features/users/UserList";
import Userdetail from "../features/users/Userdetail";
import RequirementList from "../features/Requirements/RequirementList";

const Routers = [
  {
    path: "/car-listings",
    element: CarListings,
  },
  {
    path: "/car-detail/:id",
    element: CarDetail,
  },
  {
    path: "/requirements",
    element: RequirementList,
  },
  {
    path: "/make-offer",
    element: MakeOffer,
  },
  {
    path: "/offer-detail/:id",
    element: OfferDetail,
  },
  {
    path: "/RTO-service/",
    element: RTOServices,
  },
  {
    path: "/RTO-service-requests/:id",
    element: RTOServicesRequests,
  },
  {
    path: "/used-car-loan-service/",
    element: UsedCarLoan,
  },
  {
    path: "/used-car-loan-service-requests/:id",
    element: UsedCarLoanRequests,
  },
  {
    path: "/insurance-service/",
    element: InsuranceServices,
  },
  {
    path: "/insurance-service-requests/:id",
    element: InsuranceServicesRequests,
  },
  {
    path: "/warranty-service/",
    element: WarrantyServices,
  },
  {
    path: "/warranty-service-requests/:id",
    element: WarrantyServicesRequests,
  },
  {
    path: "/RSA-service/",
    element: RSAServices,
  },
  {
    path: "/RSA-service-requests/:id",
    element: RSAServicesRequests,
  },
  {
    path: "/valuator-on-call-service/",
    element: ValuatorOnCallServices,
  },
  {
    path: "/valuator-on-call-service-requests/:id",
    element: ValuatorOnCallRequests,
  },
  {
    path: "/due-diligence-service/",
    element: DueDiligenceServices,
  },
  {
    path: "/due-diligence-service-requests/:id",
    element: DueDiligenceRequests,
  },
  {
    path: "/repair-estimates-service/",
    element: RepairEstimatesServices,
  },
  {
    path: "/repair-estimates-service-requests/:id",
    element: RepairEstimatesServicesEequests,
  },
  {
    path: "/park-sell-service/",
    element: ParkSellServices,
  },
  {
    path: "/park-sell-service-requests/:id",
    element: ParkSellServicesRequests,
  },
  {
    path: "/refurb-support-service/",
    element: RefurbSupportServices,
  },
  {
    path: "/refurb-support-service-requests/:id",
    element: RefurbSupportServicesRequests,
  },
  {
    path: "/price-consultation-service/",
    element: PriceConsultationService,
  },
  {
    path: "/price-consultation-service-requests/:id",
    element: PriceConsultationServiceRequests,
  },
  {
    path: "/hsrp-service/",
    element: HSRPService,
  },
  {
    path: "/hsrp-service-requests/:id",
    element: HSRPServiceRequests,
  },
  {
    path: "/fastag-service/",
    element: FastagService,
  },
  {
    path: "/fastag-service-requests/:id",
    element: FastagServiceRequests,
  },
  {
    path: "/escrow-service/",
    element: EscrowService,
  },
  {
    path: "/escrow-service-requests/:id",
    element: EscrowServiceRequests,
  },
  {
    path: "/help-support-service/",
    element: HelpSupportService,
  },
  {
    path: "/help-support-service-requests/:id",
    element: HelpSupportServiceRequests,
  },
  {
    path: "/bookings",
    element: Booking,
  },
  {
    path: "/booking-detail/:id",
    element: BookingDetail,
  },
  {
    path: "/users",
    element: UserList,
  },
  {
    path: "/user-detail/:id",
    element: Userdetail,
  },
  {
    path: "/change-password",
    element: ChangePassword,
  },
];
export default Routers;

export function RenderRoutes({ routers }) {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Home />
            </>
          }
        />
        <Route
          path="forgot-password"
          element={
            <>
              <ForgotPassword />
            </>
          }
        />
        <Route
          path="reset-password"
          element={
            <>
              <ResetPassword />
            </>
          }
        />
        <Route
          path="otp-verification"
          element={
            <>
              <OTPVerification />
            </>
          }
        />

        {routers.map((route, i) => {
          return (
            <Route
              key={i}
              path={route.path}
              element={
                <PrivateRoute>
                  <div className="admin-panel">
                    <div className="container-fluid px-0">
                      <div className="row no-gutters">
                        <Header />
                        <route.element />
                      </div>
                    </div>
                  </div>
                </PrivateRoute>
              }
            ></Route>
          );
        })}

        <Route
          path="*"
          element={
            <>
              <Home />
            </>
          }
        />
      </Routes>
    </>
  );
}
