import React from "react";
import { Button, Modal } from "react-bootstrap";

function ConfirmationModal({ page, show, handleShow, confirmRFD }) {
  return (
    <Modal show={show} onHide={handleShow} centered>
      <Modal.Header>
        <Modal.Title>Confirm {page}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Do you want to Confirm this {page} ?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleShow}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            confirmRFD();
            handleShow();
          }}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
