import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AddCarsNoByUser from "../../component/AddCarsNoByUser";
import DeleteModal from "../../component/DeleteModal";
import Paginations from "../../component/Pagination";
import { togglesuccess } from "../login/LoginSlice";
import { MechanicalPermissionReducer, UserReducer, UserListReducer } from "./UserSlice";
import Papa from 'papaparse';


import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

function UserList() {
  const dispatch = useDispatch();
  const success = useSelector((store) => store.login.success);
  const [page, setPage] = useState(1);
  const total = useSelector((state) => state.User.total);
  const data = useSelector((state) => state.User.user);
  const allUserList = useSelector((state) => state.User.userList);

  const [status, setStatus] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [delId, setDelId] = useState("");
  const [modalName, setModalName] = useState("");
  const [noCars, setNoCars] = useState("");

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [focusedInput, setFocusedInput] = useState('');

  let start_date = startDate;
  let end_date = endDate; 

  const handleShow = () => {
    setShow(!show);
  };

  const handlePage = (value) => {
    setPage(value);
  };

  const getlist = useCallback(async () => {
    // dispatch(
    //   UserReducer({
    //     search: searchValue,
    //     page: page,
    //     limit: 10,
    //     status: status,
    //     //user_type: "admin",
    //   })
    // );

    const params = {
      search: searchValue,
      page: page,
      limit: 10,
      status: status,
    };
    if (start_date) {
      params.startDate = start_date._d.toISOString();
    }
    if (end_date) {
      params.endDate = end_date._d.toISOString();
    }
    dispatch(UserReducer(params));
  }, [dispatch, searchValue, page, status, start_date, end_date]);


  const getAllList = useCallback(async () => {
    // dispatch(
    //   UserListReducer({
    //     status: status,
    //     //user_type: "admin",
    //   })
    // );

    const params = {
      status: status,
    };
    if (start_date) {
      params.startDate = start_date._d.toISOString();
    }
    if (end_date) {
      params.endDate = end_date._d.toISOString();
    }
    dispatch(UserListReducer(params));
  }, [dispatch, status, start_date, end_date]);

 
  const MechanicalPermission = (data) => {
    //console.log(data);
    dispatch(MechanicalPermissionReducer(data)).then((res) => {
      if (res?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const confirmDelete = () => {
    // dispatch(BookingDeleteReducer({ deal_id: delId })).then((res) => {
    //   if (res?.payload?.code === 1) {
    //     dispatch(togglesuccess(true));
    //     setError(res?.payload?.message);
    //     getlist();
    //     setTimeout(() => {
    //       setError("");
    //     }, 2000);
    //   } else {
    //     dispatch(togglesuccess(false));
    //     setError(res?.payload?.message);
    //     getlist();
    //     setTimeout(() => {
    //       setError("");
    //     }, 2000);
    //   }
    // });
  };

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlist();
    }
    return () => {
      mounted = false;
    };
  }, [getlist]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getAllList();
    }

    return () => {
      mounted = false;
    };
  }, [getAllList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentDate = moment().format('lll');

  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };

  const handleExportCSV = () => {
    const csvData = convertToCSV(allUserList);

    // Create a Blob and a link to trigger the download
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `users_data_${currentDate}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  };

const isLoading = allUserList.length == 0;

const clearDate = () => {

  setEndDate('');
  setStartDate('');
}

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-reverse">
            <div className="col-sm-12 col-md-2 col-lg-2 mb-1">
              <div className="link-active">
                <img src="assets/images/users-menu-select.png" alt="" />
                <span>
                  <p>{total && total}</p>
                  <p>Total Users</p>
                </span>
              </div>
            </div>

            {(() => {
              if (isLoading) {
                return  <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
                          <div className="d-flex align-items-center justify-content-start">
                            <button className="btn link-active " onClick={handleExportCSV} disabled>Export CSV</button>
                          </div>
                        </div>;
              } else {
                return <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
                            <div className="d-flex align-items-center justify-content-start">
                              <button className="btn link-active " onClick={handleExportCSV} >Export CSV</button>
                            </div>
                        </div>;
                      }
            })()}


            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search  by Name.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      setPage("1");
                    }}
                  />
                </form>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
              <div className="d-flex align-items-center justify-content-start">
              <DateRangePicker
                startDate={startDate} 
                startDateId="your_unique_start_date_id" 
                endDate={endDate} 
                endDateId="your_unique_end_date_id" 
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }} 
                focusedInput={focusedInput} 
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} 
                isOutsideRange={() => false} 
              />
                <div className="clear-date" onClick={clearDate}>
                  <h3>
                    <i className="fas fa-times"></i>
                  </h3>
                </div>
              </div>
          </div>
          </div>
          <hr />

          <div className="col-sm-12 px-0">
            {error && (
              <div
                className={
                  success ? "alert alert-success" : "alert alert-danger"
                }
              >
                {error}
              </div>
            )}
            <div className="tab-content mt-4">
              <div className="table-responsive text-center">
                <table className="table storetable">
                  <thead className="text-center">
                    <tr>
                      <th scope="col">image</th>
                      <th scope="col">first name</th>
                      <th scope="col">last name</th>
                      <th scope="col">email</th>
                      <th scope="col">mobile no</th>
                      <th scope="col">profile percentage</th>
                      <th scope="col">No. of Cars</th>
                      <th scope="col">User type</th>
                      <th scope="col">location</th>
                      <th scope="col">Create At</th>
                      <th scope="col">action</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length > 0 ? (
                      data?.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <img
                              src={
                                item?.image?.profile_pic?.includes("http")
                                  ? item?.image?.profile_pic
                                  : "assets/images/employe.png"
                              }
                              className="table-img"
                              alt=""
                            />
                          </td>
                          <td>{item?.first_name || "NA"}</td>
                          <td>{item?.last_name || "NA"}</td>
                          <td className="text-inherit">
                            {item?.personal_email || "NA"}
                          </td>
                          <td>
                            {item?.mobile
                              ? `${item?.countryCode} ${item?.mobile}`
                              : "NA"}
                          </td>
                          <td>
                            {`${item?.profile_complete_percentage}%` || "NA"}
                          </td>
                          <td>{item?.no_of_cars_can_add || "NA"}</td>
                          <td>
                            {item?.user_type}
                            {item?.user_type && item?.canAddOthersCar
                              ? ", "
                              : ""}
                            {item?.canAddOthersCar ? "Mechanic" : ""}
                          </td>
                          <td>{item?.address || "NA"}</td>
                          <td>
                            {item?.createdAt
                              ? moment(item?.createdAt).format("DD/MM/YYYY")
                              : "NA"}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center action">
                              {!item?.vehicleId ? (
                                !item?.canAddOthersCar ? (
                                  <button
                                    className={
                                      !item?.vehicleId
                                        ? !item?.canAddOthersCar
                                          ? "btn disable"
                                          : "btn disable"
                                        : "btn"
                                    }
                                    title="Car View"
                                  >
                                    <img
                                      src="assets/images/carViewGray.png"
                                      alt=""
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className={
                                      !item?.vehicleId
                                        ? !item?.canAddOthersCar
                                          ? "btn disable"
                                          : "btn disable"
                                        : "btn"
                                    }
                                    title="Car View"
                                  >
                                    <img
                                      src="assets/images/carViewGray.png"
                                      alt=""
                                    />
                                  </button>
                                )
                              ) : (
                                <button className="btn" title="Car View">
                                  <Link to={`/car-detail/${item?.vehicleId}`}>
                                    <img
                                      src="assets/images/carView.png"
                                      alt=""
                                    />
                                  </Link>
                                </button>
                              )}

                              <button className="btn" title="View">
                                <Link to={`/user-detail/${item?.id}`}>
                                  <img src="assets/images/view.png" alt="" />
                                </Link>
                              </button>
                              <button
                                className="btn"
                                title="Add Cars"
                                onClick={() => {
                                  setDelId(item?.id);
                                  setModalName("NoOfCars");
                                  setNoCars(item?.no_of_cars_can_add);
                                  handleShow();
                                }}
                              >
                                <img
                                  src="assets/images/add_user_users_icon.png"
                                  alt=""
                                />
                              </button>
                              <button
                                className="btn"
                                title="Mechanical Permission"
                                onClick={() => {
                                  MechanicalPermission({
                                    user_id: item?.id,
                                    canAddOthersCar:
                                      item?.canAddOthersCar === true
                                        ? false
                                        : true,
                                  });
                                }}
                              >
                                <img
                                  src={
                                    item?.canAddOthersCar === true
                                      ? "assets/images/no.png"
                                      : "assets/images/approve.png"
                                  }
                                  alt=""
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="12">No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            
              {data?.length > 0 && (
                <Paginations
                  handlePage={handlePage}
                  page={page}
                  total={total && total}
                />
              )}
            </div>
          </div>
        </div>
        {modalName === "NoOfCars" && delId && (
          <AddCarsNoByUser
            getList={getlist}
            show={show}
            handleShow={handleShow}
            ReqId={delId}
            noCars={noCars}
          />
        )}
      </article>
    </>
  );
}

export default UserList;
