import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const isAuthorized = localStorage.getItem("token")
  //const isAuthorized = true;
  if (isAuthorized) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
}

export default PrivateRoute;
