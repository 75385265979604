import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import {
  AddQuotationReducer,
  S3BucketCredentialReducer,
} from "./AddQuotationSlice";
import { togglesuccess } from "../features/login/LoginSlice";
import S3FileUpload from "react-s3";

function AddQuotation({ show, handleShow, ReqId, getList }) {
  const dispatch = useDispatch();
  const success = useSelector((store) => store.login.success);
  const [error, setError] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const detail = useSelector((store) => store.AddQuotations.S3BucketCredential);
  //console.log(detail);
  const config = {
    bucketName: "p2p-carz",
    region: "ap-south-1",
    accessKeyId: "AKIA4VX32GJ7QVCVVBVZ",
    secretAccessKey: "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",
  };

  const initialValues = {
    request_id: ReqId,
    cost: "",
    quotation_pdf: "",
  };
  //console.log("request id", initialValues.quotation_pdf);
  const adddata = (values) => {
    dispatch(
      AddQuotationReducer({
        request_id: ReqId,
        cost: values.cost,
        quotation_pdf: values.quotation_pdf,
      })
    ).then((data) => {
      // console.log(data);
      if (data?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(data?.payload?.message);
        setTimeout(() => {
          setError("");
          handleShow();
          getList();
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(data?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 1000);
      }
    });
  };

  const getdetail = useCallback(() => {
    dispatch(S3BucketCredentialReducer({}));
  }, [dispatch]);

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);

  return (
    <Modal show={show} onHide={handleShow}>
      <Modal.Header>
        <h5 className="modal-title">Add Quotation</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleShow}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              cost: Yup.string().required("Required"),
            })}
            enableReinitialize
            onSubmit={(values, { isSubmitting, resetForm }) => {
              //console.log(values);
              adddata(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFormikState,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Attached Quotation</label>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file__input"
                      id="field-upload"
                      name="quotation_pdf"
                      accept=".pdf"
                      value={values.imgname}
                      onChange={(event) => {
                        setIsActive(true);
                        S3FileUpload.uploadFile(event.target.files[0], config)
                          .then((data) => {
                            setFormikState((prevState) => {
                              return {
                                ...prevState,
                                values: {
                                  ...prevState.values,
                                  // imageName: imgname,
                                  quotation_pdf: data.location,
                                },
                              };
                            });
                            setTime(100);
                            setIsActive(true);
                            setTimeout(() => {
                              setTime(0);
                              setIsActive(false);
                            }, 3000);
                            //console.log(data.location);
                          })
                          .catch((err) => console.error(err));
                      }}
                    />
                    <label
                      className="custom-file__label"
                      htmlFor="field-upload"
                    >
                      <img src="assets/images/pdf-icon.png" alt="" />
                    </label>
                    {time > 0 && isActive && (
                      <Progress type="line" width={100} percent={time} />
                    )}
                  </div>
                  {errors.quotation_pdf && touched.quotation_pdf
                    ? errors.quotation_pdf
                    : null}
                </div>

                <div className="mb-4">
                  <label className="form-label">Cost</label>
                  <input
                    type="number"
                    className="form-control"
                    name="cost"
                    value={values.cost}
                    onChange={handleChange}
                    onBlur={handleBlur("cost")}
                    disabled={!values.quotation_pdf}
                  />
                  {errors.cost && touched.cost ? errors.cost : null}
                </div>
                <div className="text-center my-2">
                  <button type="submit" className="btn btn-accept btn-view ">
                    Submit
                  </button>
                </div>
                {error && (
                  <div
                    className={
                      success
                        ? "alert alert-success text-center"
                        : "alert alert-danger text-center"
                    }
                  >
                    {error}
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddQuotation;
