import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DeleteModal from "../../component/DeleteModal";
import Paginations from "../../component/Pagination";
import {
  DeleteOfferReducer,
  MakeOfferReducer,
  UpdateOfferReducer,
  MakeOfferCSVReducer
} from "./MakeOfferSlice";
import currencies from "currency-formatter";
import { togglesuccess } from "../login/LoginSlice";
import moment from "moment";
import CarModal from "../../component/CarModal";
import Papa from 'papaparse';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

function MakeOffer() {
  const dispatch = useDispatch();
  const success = useSelector((store) => store.login.success);
  const [page, setPage] = useState(1);
  const total = useSelector((state) => state.MakeOffer.total);
  const unapprovedTotal = useSelector((state) => state.MakeOffer.newTotal);
  const approvedTotal = useSelector((state) => state.MakeOffer.approvedTotal);
  const rejectedTotal = useSelector((state) => state.MakeOffer.rejectedTotal);
  const data = useSelector((state) => state.MakeOffer.makeoffer);
  const unapprovedOfferList = useSelector((state) => state.MakeOffer.newOfferList);
  const approvedOfferList = useSelector((state) => state.MakeOffer.approvedOfferList);
  const rejectedOfferList = useSelector((state) => state.MakeOffer.rejectedOfferList);
  const unapprovedOfferListData = useSelector((state) => state.MakeOffer.newOfferListData);
  const approvedOfferListData = useSelector((state) => state.MakeOffer.approvedOfferListData);
  const rejectedOfferListData = useSelector((state) => state.MakeOffer.rejectedOfferListData);

  const [status, setStatus] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [delId, setDelId] = useState("");
  const [modalName, setModalName] = useState("");

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [focusedInput, setFocusedInput] = useState('');

  let start_date = startDate;
  let end_date = endDate; 

  const handleShow = () => {
    setShow(!show);
  };

  const handlePage = (value) => {
    setPage(value);
  };

  const getOfferCsvlist = useCallback(async () => {
    const params = {
      search: searchValue,
      page: page,
      limit: 10,
      status: status,
    };
  
    if (start_date) {
      params.startDate = start_date._d.toISOString();
    }
  
    if (end_date) {
      params.endDate = end_date._d.toISOString();
    }
  
    dispatch(MakeOfferCSVReducer(params));
  }, [dispatch, searchValue, page, status, start_date, end_date]);

  const getlist = useCallback(async () => {
    const params = {
      search: searchValue,
      page: page,
      limit: 10,
      status: status,
    };
  
    if (start_date) {
      params.startDate = start_date.toISOString();
    }
  
    if (end_date) {
      params.endDate = end_date.toISOString();
    }
  
    dispatch(MakeOfferReducer(params));
  }, [dispatch, searchValue, page, status, start_date, end_date]);

  const approvedReject = (data) => {
    //console.log(data);
    dispatch(UpdateOfferReducer(data)).then((res) => {
      if (res?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const confirmDelete = () => {
    dispatch(DeleteOfferReducer({ offer_id: delId })).then((res) => {
      if (res?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(res?.payload?.message);
        getlist();
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlist();
      getOfferCsvlist();
    }
    return () => {
      mounted = false;
    };
  }, [getlist, getOfferCsvlist]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeLink, setActiveLink] = useState('unapproved');

  const [activeList, setActiveList] = useState(unapprovedOfferList); // Initialize with a default value

useEffect(() => {
  switch (activeLink) {
    case 'unapproved':
      setActiveList(unapprovedOfferList);
      break;
    case 'approved':
      setActiveList(approvedOfferList);
      break;
    case 'rejected':
      setActiveList(rejectedOfferList);
      break;
    default:
      setActiveList(unapprovedOfferList);
  }
}, [activeLink, unapprovedOfferList, approvedOfferList, rejectedOfferList]);



  // Function to handle link clicks
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const currentDate = moment().format('lll');

  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;

  };

  const handleExportCSV = async () => {   
  // let activeList;
  // switch (activeLink) {
  //   case 'unapproved':
  //     activeList = unapprovedOfferList;
  //     break;
  //   case 'approved':
  //     activeList = approvedOfferList;
  //     break;
  //   case 'rejected':
  //     activeList = rejectedOfferList;
  //     break;
    
  //   default:
  //     activeList = unapprovedOfferList;
  // }

  let activeListName;

  switch (activeLink) {
    case 'unapproved':
      activeListName = "unapproved_offer_list";
      break;
    case 'approved':
      activeListName = "approved_offer_list";
      break;
    case 'rejected':
      activeListName = "rejected_offer_list";
      break;
    default:
      activeListName = "unapproved_offer_list";
  }

  const detail_active_offer_list = [];
  for (let i = 0; i < activeList.length; i++) {
    let detail_offer_list = {};

  
      detail_offer_list.name = activeList[i].carDetails?.modelDetails?.name;
      detail_offer_list.price = activeList[i].carDetails?.price;
      detail_offer_list.initial_price = activeList[i]?.initial_price; 
      detail_offer_list.final_price = activeList[i].final_price;
      detail_offer_list.offer_date = activeList[i]?.updatedAt ? moment(activeList[i]?.updatedAt).format("ll") : "N/A";
      detail_offer_list.payment_method = activeList[i]?.payment_method;
      detail_offer_list.buyer_name = activeList[i]?.buyerDetails?.first_name? `${activeList[i]?.buyerDetails?.first_name} ${activeList[i]?.buyerDetails?.last_name}`: "N/A";
      detail_offer_list.buyer_mobile_no = activeList[i]?.buyerDetails?.mobile? `${activeList[i]?.buyerDetails?.countryCode} ${activeList[i]?.buyerDetails?.mobile}`: "N/A";
      detail_offer_list.seller_name = activeList[i]?.sellerDetails?.first_name? `${activeList[i]?.sellerDetails?.first_name} ${activeList[i]?.sellerDetails?.last_name}`: "N/A";
      detail_offer_list.seller_mobile_no = activeList[i]?.sellerDetails?.mobile ? `${activeList[i]?.sellerDetails?.countryCode} ${activeList[i]?.sellerDetails?.mobile}`: "N/A";
      detail_offer_list.status = activeList[i]?.status === "0" ? "Fresh": activeList[i]?.status === "1" ? "Revised": "N/A";
      detail_offer_list.car_short_detail_vehicle_UID = activeList[i].carDetails?.vehicle_UID;
      detail_offer_list.car_short_detail_regn_number  = activeList[i].carDetails?.regn_number;
      detail_offer_list.car_short_detail_owner_serial  = activeList[i].carDetails?.owner_serial;
      detail_offer_list.car_short_detail_OEM_Make = activeList[i].carDetails?.brandDetails?.name;
      detail_offer_list.car_short_detail_Model = activeList[i].carDetails?.modelDetails?.name;
      detail_offer_list.car_short_detail_Variant = activeList[i].carDetails?.variantDetails?.name;
      detail_offer_list.car_short_detail_Month_Year_Of_Regn = activeList[i].carDetails?.reg_year ? moment(activeList[i].carDetails?.reg_year, "MM/YYYY").format("MMM YYYY") : "N/A";
      detail_offer_list.car_short_detail_Listing_Date = activeList[i]?.carDetails.createdAt ? moment(activeList[i]?.carDetails.createdAt).format("ll"): "N/A";
      detail_offer_list.car_short_detail_No_Of_Offers = total;
      detail_offer_list.car_short_detail_Vehicle_Views = activeList[i].carDetails?.views;
      detail_offer_list.car_short_detail_Certification = activeList[i].carDetails?.isReviewed ? "Seen by Us" : "N/A";
      detail_offer_list.createdAt = activeList[i].updatedAt ? moment(activeList[i]?.updatedAt).format('ll') : "N/A";

    detail_active_offer_list.push(detail_offer_list);
  }

    const csvData = convertToCSV(detail_active_offer_list);

    // Create a Blob and a link to trigger the download
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${activeListName}_${currentDate}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  };

  

  let activeTotal;
    switch (activeLink) {
      case 'unapproved':
        activeTotal = unapprovedTotal;
        break;
      case 'approved':
        activeTotal = approvedTotal;
        break;
      case 'rejected':
        activeTotal = rejectedTotal;
        break;
      
      default:
        activeTotal = unapprovedTotal;
    }

    let isLoading = activeList.length === 0;
    const clearDate = () => {

      setEndDate('');
      setStartDate('');
    }
    
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-2 col-lg-2 mb-1">
            <div className="link-active">
              <img src="assets/images/makeOffer-selected.png" alt="" />
              <span>
                <p>{activeTotal && activeTotal}</p>
                <p>Total Offers</p>
              </span>
            </div>
          </div>

          {(() => {
              if (isLoading) {
                return  <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
                          <div className="d-flex align-items-center justify-content-start">
                            <button className="btn link-active " onClick={handleExportCSV} disabled>Export CSV</button>
                          </div>
                        </div>;
              } else {
                return <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
                            <div className="d-flex align-items-center justify-content-start">
                              <button className="btn link-active " onClick={handleExportCSV} >Export CSV</button>
                            </div>
                        </div>;
                      }
            })()}


          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search offer by name"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setPage("1");
                  }}
                />
              </form>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 mb-2">
              <div className="d-flex align-items-center justify-content-start">
              <DateRangePicker
                startDate={startDate} 
                startDateId="your_unique_start_date_id" 
                endDate={endDate} 
                endDateId="your_unique_end_date_id" 
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }} 
                focusedInput={focusedInput} 
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} 
                isOutsideRange={() => false} 
              />
                <div className="clear-date" onClick={clearDate}>
                  <h3>
                    <i className="fas fa-times"></i>
                  </h3>
                </div>

              </div>
          </div>

        </div>
        <hr />
        <div className="row">
          <div className="col-sm-12">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <ul className="nav nav-tabs group-nav" role="tablist">
                  <li className="nav-item">
                    <a
                      className={activeLink === 'unapproved' ? 'nav-link active' : 'nav-link'}
                      data-toggle="tab"
                      href="#unapproved-offer"
                      onClick={() => {
                        setStatus(0);
                        setPage(1);
                        handleLinkClick('unapproved');
                      }}
                    >
                      unapproved
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={activeLink === 'approved' ? 'nav-link active' : 'nav-link'}
                      data-toggle="tab"
                      href="#approved"
                      onClick={() => {
                        setStatus(1);
                        setPage(1);
                        handleLinkClick('approved');
                      }}
                    >
                      approved
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={activeLink === 'rejected' ? 'nav-link active' : 'nav-link'}
                      data-toggle="tab"
                      href="#rejected"
                      onClick={() => {
                        setStatus(2);
                        setPage(1);
                        handleLinkClick('rejected');
                      }}
                    >
                      rejected
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            {error && (
              <div
                className={
                  success ? "alert alert-success" : "alert alert-danger"
                }
              >
                {error}
              </div>
            )}
            <div className="tab-content mt-4">
              <div id="unapproved-offer" className="tab-pane active">
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">S.No.</th>
                        {/* <th scope="col">photo</th> */}
                        <th scope="col">vehicle Name</th>
                        <th scope="col">price</th>
                        <th scope="col">offer price</th>
                        <th scope="col">Offer Date</th>
                        <th scope="col">payment method</th>
                        <th scope="col">Buyer Name </th>
                        <th scope="col">Buyer Mobile No </th>
                        <th scope="col">Seller Name </th>
                        <th scope="col">Seller Mobile No</th>
                        <th scope="col">status</th>
                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {unapprovedOfferListData && unapprovedOfferListData?.length > 0 ? (
                        unapprovedOfferListData?.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {page?.toString() === "1"
                                ? i + 1
                                : (page - 1) * 10 + i + 1}
                            </td>
                            {/* <td>
                              <img
                                src={
                                  item?.carDetails?.image?.path?.includes(
                                    "http"
                                  )
                                    ? item?.carDetails?.image?.path
                                    : "assets/images/employe.png"
                                }
                                className="table-img"
                                alt=""
                              />
                            </td> */}
                            <td>
                              {item?.carDetails?.modelDetails?.name || "N/A"}
                            </td>
                            <td>
                              {currencies.format(item?.carDetails?.price, {
                                code: "INR",
                                symbol: "INR ",
                              })}
                            </td>
                            <td>
                              {currencies.format(item?.initial_price, {
                                code: "INR",
                                symbol: "INR ",
                              })}{" "}
                              -{" "}
                              {currencies.format(item?.final_price, {
                                code: "INR",
                                symbol: "INR ",
                              })}
                            </td>

                            <td>
                              {item?.updatedAt
                                ? moment(item?.updatedAt).format("ll")
                                : "N/A"}
                            </td>
                            <td>{item?.payment_method || "N/A"}</td>
                            <td>
                              {item?.buyerDetails?.first_name
                                ? `${item?.buyerDetails?.first_name} ${item?.buyerDetails?.last_name}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.buyerDetails?.mobile
                                ? `${item?.buyerDetails?.countryCode} ${item?.buyerDetails?.mobile}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.sellerDetails?.first_name
                                ? `${item?.sellerDetails?.first_name} ${item?.sellerDetails?.last_name}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.sellerDetails?.mobile
                                ? `${item?.sellerDetails?.countryCode} ${item?.sellerDetails?.mobile}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.status === "0"
                                ? "Fresh"
                                : item?.status === "1"
                                ? "Revised"
                                : "N/A"}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center action">
                                <button
                                  className="btn"
                                  title="Car Short Detail"
                                  onClick={() => {
                                    setDelId(item?.car_id);
                                    setModalName("car Modal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src="assets/images/carViewLine.png"
                                    alt=""
                                  />
                                </button>
                                <button className="btn" title="Car Detail">
                                  <Link
                                    to={`/car-detail/${item?.car_id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src="assets/images/carView.png"
                                      alt=""
                                    />
                                  </Link>
                                </button>
                                <button className="btn" title="Buyer">
                                  <Link
                                    to={`/user-detail/${item?.buyerDetails?.id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src="assets/images/add_user_users_icon.png"
                                      alt=""
                                    />
                                  </Link>
                                </button>
                                <button className="btn" title="Seller">
                                  <Link
                                    to={`/user-detail/${item?.sellerDetails?.id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src="assets/images/sellerBlue.png"
                                      alt=""
                                    />
                                  </Link>
                                </button>
                                <button className="btn" title="View">
                                  <Link to={`/offer-detail/${item?.id}`}>
                                    <img src="assets/images/view.png" alt="" />
                                  </Link>
                                </button>
                                <button
                                  className="btn"
                                  title="Approve"
                                  onClick={() => {
                                    approvedReject({
                                      status: 1,
                                      offer_id: item?.id,
                                    });
                                  }}
                                >
                                  <img src="assets/images/approve.png" alt="" />
                                </button>
                                <button
                                  className="btn"
                                  title="Reject"
                                  onClick={() => {
                                    approvedReject({
                                      status: 2,
                                      offer_id: item?.id,
                                    });
                                  }}
                                >
                                  <img src="assets/images/reject.png" alt="" />
                                </button>
                                {/* <button
                                  className="btn"
                                  title="Delete"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    handleShow();
                                  }}
                                >
                                  <img src="assets/images/delete.png" alt="" />
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="15">No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {unapprovedOfferListData?.length > 0 && (
                  <Paginations
                    handlePage={handlePage}
                    page={page}
                    total={activeTotal && activeTotal}
                  />
                )}
              </div>
              <div id="approved" className="tab-pane fade">
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">S.No.</th>
                        {/* <th scope="col">photo</th> */}
                        <th scope="col">vehicle Name</th>
                        <th scope="col">price</th>
                        <th scope="col">offer price</th>
                        <th scope="col">Offer Date</th>
                        <th scope="col">payment method</th>
                        <th scope="col">Buyer Name </th>
                        <th scope="col">Buyer Mobile No </th>
                        <th scope="col">Seller Name </th>
                        <th scope="col">Seller Mobile No</th>
                        <th scope="col">status</th>
                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {approvedOfferListData && approvedOfferListData?.length > 0 ? (
                        approvedOfferListData?.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {page?.toString() === "1"
                                ? i + 1
                                : (page - 1) * 10 + i + 1}
                            </td>
                            {/* <td>
                              <img
                                src={
                                  item?.carDetails?.image?.path?.includes(
                                    "http"
                                  )
                                    ? item?.carDetails?.image?.path
                                    : "assets/images/employe.png"
                                }
                                className="table-img"
                                alt=""
                              />
                            </td> */}
                            <td>
                              {item?.carDetails?.modelDetails?.name || "N/A"}
                            </td>
                            <td>
                              {currencies.format(item?.carDetails?.price, {
                                code: "INR",
                                symbol: "INR ",
                              })}
                            </td>
                            <td>
                              {currencies.format(item?.initial_price, {
                                code: "INR",
                                symbol: "INR ",
                              })}{" "}
                              -{" "}
                              {currencies.format(item?.final_price, {
                                code: "INR",
                                symbol: "INR ",
                              })}
                            </td>

                            <td>
                              {item?.createdAt
                                ? moment(item?.createdAt).format("ll")
                                : "N/A"}
                            </td>
                            <td>{item?.payment_method || "N/A"}</td>
                            <td>
                              {item?.buyerDetails?.first_name
                                ? `${item?.buyerDetails?.first_name} ${item?.buyerDetails?.last_name}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.buyerDetails?.mobile
                                ? `${item?.buyerDetails?.countryCode} ${item?.buyerDetails?.mobile}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.sellerDetails?.first_name
                                ? `${item?.sellerDetails?.first_name} ${item?.sellerDetails?.last_name}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.sellerDetails?.mobile
                                ? `${item?.sellerDetails?.countryCode} ${item?.sellerDetails?.mobile}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.status === "0"
                                ? "Fresh"
                                : item?.status === "1"
                                ? "Revised"
                                : "N/A"}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center action">
                                <button
                                  className="btn"
                                  title="Car Short Detail"
                                  onClick={() => {
                                    setDelId(item?.car_id);
                                    setModalName("car Modal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src="assets/images/carViewLine.png"
                                    alt=""
                                  />
                                </button>
                                <button className="btn" title="Car Detail">
                                  <Link
                                    to={`/car-detail/${item?.car_id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src="assets/images/carView.png"
                                      alt=""
                                    />
                                  </Link>
                                </button>
                                <button className="btn" title="Buyer">
                                  <Link
                                    to={`/user-detail/${item?.buyerDetails?.id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src="assets/images/add_user_users_icon.png"
                                      alt=""
                                    />
                                  </Link>
                                </button>
                                <button className="btn" title="Seller">
                                  <Link
                                    to={`/user-detail/${item?.sellerDetails?.id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src="assets/images/sellerBlue.png"
                                      alt=""
                                    />
                                  </Link>
                                </button>
                                <button className="btn" title="View">
                                  <Link to={`/offer-detail/${item?.id}`}>
                                    <img src="assets/images/view.png" alt="" />
                                  </Link>
                                </button>
                                {/* <button
                                  className="btn"
                                  title="Approve"
                                  onClick={() => {
                                    approvedReject({
                                      status: 1,
                                      offer_id: item?.id,
                                    });
                                  }}
                                >
                                  <img src="assets/images/approve.png" alt="" />
                                </button>
                                <button
                                  className="btn"
                                  title="Reject"
                                  onClick={() => {
                                    approvedReject({
                                      status: 2,
                                      offer_id: item?.id,
                                    });
                                  }}
                                >
                                  <img src="assets/images/reject.png" alt="" />
                                </button> */}
                                {/* <button
                                  className="btn"
                                  title="Delete"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    handleShow();
                                  }}
                                >
                                  <img src="assets/images/delete.png" alt="" />
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="15">No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {approvedOfferListData?.length > 0 && (
                  <Paginations
                    handlePage={handlePage}
                    page={page}
                    total={activeTotal && activeTotal}
                  />
                )}
              </div>
              <div id="rejected" className="tab-pane fade">
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">S.No.</th>
                        {/* <th scope="col">photo</th> */}
                        <th scope="col">vehicle Name</th>
                        <th scope="col">price</th>
                        <th scope="col">offer price</th>
                        <th scope="col">Offer Date</th>
                        <th scope="col">payment method</th>
                        <th scope="col">Buyer Name </th>
                        <th scope="col">Buyer Mobile No </th>
                        <th scope="col">Seller Name </th>
                        <th scope="col">Seller Mobile No</th>
                        <th scope="col">status</th>
                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {rejectedOfferListData && rejectedOfferListData?.length > 0 ? (
                        rejectedOfferListData?.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {page?.toString() === "1"
                                ? i + 1
                                : (page - 1) * 10 + i + 1}
                            </td>
                            {/* <td>
                              <img
                                src={
                                  item?.carDetails?.image?.path?.includes(
                                    "http"
                                  )
                                    ? item?.carDetails?.image?.path
                                    : "assets/images/employe.png"
                                }
                                className="table-img"
                                alt=""
                              />
                            </td> */}
                            <td>
                              {item?.carDetails?.modelDetails?.name || "N/A"}
                            </td>
                            <td>
                              {currencies.format(item?.carDetails?.price, {
                                code: "INR",
                                symbol: "INR ",
                              })}
                            </td>
                            <td>
                              {currencies.format(item?.initial_price, {
                                code: "INR",
                                symbol: "INR ",
                              })}{" "}
                              -{" "}
                              {currencies.format(item?.final_price, {
                                code: "INR",
                                symbol: "INR ",
                              })}
                            </td>

                            <td>
                              {item?.createdAt
                                ? moment(item?.createdAt).format("ll")
                                : "N/A"}
                            </td>
                            <td>{item?.payment_method || "N/A"}</td>
                            <td>
                              {item?.buyerDetails?.first_name
                                ? `${item?.buyerDetails?.first_name} ${item?.buyerDetails?.last_name}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.buyerDetails?.mobile
                                ? `${item?.buyerDetails?.countryCode} ${item?.buyerDetails?.mobile}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.sellerDetails?.first_name
                                ? `${item?.sellerDetails?.first_name} ${item?.sellerDetails?.last_name}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.sellerDetails?.mobile
                                ? `${item?.sellerDetails?.countryCode} ${item?.sellerDetails?.mobile}`
                                : "N/A"}{" "}
                            </td>
                            <td>
                              {item?.status === "0"
                                ? "Fresh"
                                : item?.status === "1"
                                ? "Revised"
                                : "N/A"}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center action">
                                <button
                                  className="btn"
                                  title="Car Short Detail"
                                  onClick={() => {
                                    setDelId(item?.car_id);
                                    setModalName("car Modal");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src="assets/images/carViewLine.png"
                                    alt=""
                                  />
                                </button>
                                <button className="btn" title="Car Detail">
                                  <Link
                                    to={`/car-detail/${item?.car_id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src="assets/images/carView.png"
                                      alt=""
                                    />
                                  </Link>
                                </button>
                                <button className="btn" title="Buyer">
                                  <Link
                                    to={`/user-detail/${item?.buyerDetails?.id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src="assets/images/add_user_users_icon.png"
                                      alt=""
                                    />
                                  </Link>
                                </button>
                                <button className="btn" title="Seller">
                                  <Link
                                    to={`/user-detail/${item?.sellerDetails?.id}`}
                                    target="_blank"
                                  >
                                    <img
                                      src="assets/images/sellerBlue.png"
                                      alt=""
                                    />
                                  </Link>
                                </button>
                                <button className="btn" title="View">
                                  <Link to={`/offer-detail/${item?.id}`}>
                                    <img src="assets/images/view.png" alt="" />
                                  </Link>
                                </button>
                                {/* <button
                                  className="btn"
                                  title="Approve"
                                  onClick={() => {
                                    approvedReject({
                                      status: 1,
                                      offer_id: item?.id,
                                    });
                                  }}
                                >
                                  <img src="assets/images/approve.png" alt="" />
                                </button> */}
                                {/* <button
                                  className="btn"
                                  title="Reject"
                                  onClick={() => {
                                    approvedReject({
                                      status: 2,
                                      offer_id: item?.id,
                                    });
                                  }}
                                >
                                  <img src="assets/images/reject.png" alt="" />
                                </button> */}
                                {/* <button
                                  className="btn"
                                  title="Delete"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    handleShow();
                                  }}
                                >
                                  <img src="assets/images/delete.png" alt="" />
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="15">No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {rejectedOfferListData?.length > 0 && (
                  <Paginations
                    handlePage={handlePage}
                    page={page}
                    total={activeTotal && activeTotal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {delId && modalName === "delete" && (
        <DeleteModal
          page="Offer"
          show={show}
          handleShow={handleShow}
          confirmDelete={confirmDelete}
        />
      )}
      {delId && modalName === "car Modal" && (
        <CarModal show={show} handleShow={handleShow} id={delId} />
      )}
    </article>
  );
}

export default MakeOffer;