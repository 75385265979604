import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { ResetPasswordreducer, togglesuccess } from "./LoginSlice";
import { useDispatch, useSelector } from "react-redux";

function ResetPassword() {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  let success = useSelector((state) => state.login.success);

  return (
    <section className="">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-5 col-md-5 col-lg-6 text-center">
            <div className="logo-bg">
              <div className="mt-2">
                <img
                  src="assets/images/logo.png"
                  className="img-fluid lft-img"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-7 col-md-7 col-lg-6 mr-0 pr-0">
            <div className="form-bg">
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                  show: false,
                  fieldName: "",
                }}
                validationSchema={Yup.object({
                  password: Yup.string().required("Required"),
                  confirmPassword: Yup.string().oneOf(
                    [Yup.ref("password"), null],
                    "Passwords must match"
                  ),
                })}
                onSubmit={(values, { isSubmitting, resetForm }) => {
                  //console.log(values);
                  dispatch(
                    ResetPasswordreducer({
                      password: values.password,
                    })
                  ).then((data) => {
                    // console.log(data);
                    if (data?.payload?.code === 1) {
                      dispatch(togglesuccess(true));
                      setError(data?.payload?.message);
                      setTimeout(() => {
                        setError("");
                        navigate("/", {
                          replace: true,
                        });
                      }, 3000);
                    } else {
                      dispatch(togglesuccess(false));
                      setError(data?.payload?.message);
                      setTimeout(() => {
                        setError("");
                      }, 3000);
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFormikState,
                }) => (
                  <form className="form-style" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 text-center mb-4">
                        <h2 className="mdl-ttl">Reset Password</h2>
                        <img
                          src="assets/images/reset.png"
                          className="img-fluid my-3"
                          alt=""
                        />
                        <p className="mdl-sbttl">Please reset your password</p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              type={
                                values.toggle && values.fieldName === "password"
                                  ? "text"
                                  : "password"
                              }
                              className="form-control pl-5"
                              placeholder="New Password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <img
                              src="assets/images/password.png"
                              className="input-img"
                              alt=""
                            />
                            <span
                              className={
                                values.toggle && values.fieldName === "password"
                                  ? "pass-hide field-icon toggle-password cursor-pointer"
                                  : "pass-view field-icon toggle-password cursor-pointer"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      toggle: !prevState.values.toggle,
                                      fieldName: "password",
                                    },
                                  };
                                });
                              }}
                            ></span>
                          </div>
                          {errors.password && touched.password && (
                            <div className="text-red"> {errors.password}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                        <div className="form-group">
                          <div className="input-container">
                            <input
                              type={
                                values.toggle &&
                                values.fieldName === "confirmPassword"
                                  ? "text"
                                  : "password"
                              }
                              name="confirmPassword"
                              className="form-control pl-5"
                              placeholder="Confirm New Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                            />
                            <img
                              src="assets/images/password.png"
                              className="input-img"
                              alt=""
                            />
                            <span
                              className={
                                values.toggle &&
                                values.fieldName === "confirmPassword"
                                  ? "pass-hide field-icon toggle-password cursor-pointer"
                                  : "pass-view field-icon toggle-password cursor-pointer"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      toggle: !prevState.values.toggle,
                                      fieldName: "confirmPassword",
                                    },
                                  };
                                });
                              }}
                            ></span>
                          </div>
                          {errors.confirmPassword &&
                            touched.confirmPassword && (
                              <div className="text-red">
                                {" "}
                                {errors.confirmPassword}
                              </div>
                            )}
                        </div>
                      </div>
                      {error && (
                        <div
                          className={
                            success
                              ? "col-sm-12 col-md-12 alert alert-success text-center mt-2 text-capitalize"
                              : "col-sm-12 col-md-12 alert alert-danger text-center mt-2 text-capitalize"
                          }
                        >
                          {error}
                        </div>
                      )}
                      <div className="col-sm-12 col-md-12 text-center">
                        <button type="submit" className="btn btn-save">
                          Done
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
