import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../config/index";

const BASE_URL = SERVER_URL;

const initialState = {
  S3BucketCredential: {},
};
export const AddQuotationReducer = createAsyncThunk(
  "AddQuotationReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      // let formData = new FormData();
      // for (let key in obj) {
      //   formData.append(key, obj[key]);
      // }

      let request_id = obj.request_id.toString();
      let cost = obj.cost.toString();
      let quotation_pdf = obj.quotation_pdf.toString();

      let data = {request_id, cost, quotation_pdf};
      const response = await axios.post(
        `${BASE_URL}/alliedService/addQuotation`,
        data,
        {
          headers: {
            token: token,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const S3BucketCredentialReducer = createAsyncThunk(
  "S3BucketCredentialReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/user/getS3BucketCredential`,
        {
          params: {
            search: obj.search,
            page: obj.page,
            limit: obj.limit,
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const AddQuotationSlice = createSlice({
  name: "AddQuotations",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [S3BucketCredentialReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.S3BucketCredential = action?.payload?.data;
    },
  },
});

export const { reducerName } = AddQuotationSlice.actions;

export default AddQuotationSlice.reducer;
