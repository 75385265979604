import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AddQuotation from "../../../component/AddQuotation";
import Paginations from "../../../component/Pagination";
import UpdateStatus from "../../../component/UpdateStatus";
import { RepairEstimatesServicesListingsReducer } from "./RepairEstimatesServicesSlice";

function RepairEstimatesServices() {
  const dispatch = useDispatch();
  const total = useSelector((state) => state.RepairEstimatesServices.total);
  const data = useSelector(
    (state) => state.RepairEstimatesServices.RepairEstimatesServices
  );
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [ReqId, setReqId] = useState("");
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const handleShow = () => {
    setShow(!show);
  };

  const handlePage = (value) => {
    setPage(value);
  };
  const getlist = useCallback(async () => {
    dispatch(
      RepairEstimatesServicesListingsReducer({
        search: searchValue,
        limit: 10,
        page: page,
        service_id: 8,
      })
    );
  }, [dispatch, searchValue, page]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlist();
    }
    return () => {
      mounted = false;
    };
  }, [getlist]);
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="link-active">
                <img src="assets/images/AlliedServices.png" alt="" />
                <span>
                  <p>{total && total}</p>
                  <p>Total Requests</p>
                </span>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Requests by vehicle Regn No"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      setPage("1");
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-12">
              <div className="table-responsive text-center">
                <table className="table storetable">
                  <thead className="text-center">
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">user name</th>
                      <th scope="col">user phone</th>
                      <th scope="col">vehicle Regn No</th>
                      <th scope="col">Make</th>
                      <th scope="col">Modal</th>
                      <th scope="col">fuel</th>
                      <th scope="col">location</th>
                      <th scope="col">amount</th>
                      <th scope="col">status</th>
                      <th scope="col">action</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length > 0 ? (
                      data?.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            {item?.userDetails?.first_name
                              ? `${item?.userDetails?.first_name} ${item?.userDetails?.last_name}`
                              : "NA"}
                          </td>
                          <td>
                            {item?.userDetails?.mobile
                              ? `${item?.userDetails?.countryCode} ${item?.userDetails?.mobile}`
                              : "NA"}
                          </td>
                          <td>{item?.vehicle_regn_no || "NA"}</td>
                          <td>{item?.make || "NA"}</td>
                          <td>{item?.model || "NA"}</td>
                          <td>{item?.fuel || "NA"}</td>
                          <td>{item?.vehicle_location || "NA"}</td>
                          <td>
                            {item?.paymentDetails === null ? (
                              <img src="assets/images/no.png" alt="" />
                            ) : (
                              item?.paymentDetails?.amount
                            )}
                          </td>
                          <td>
                            <span
                              className={
                                item?.admin_response === "0"
                                  ? "cursor-pointer StatusBtn pendingStatus"
                                  : item?.admin_response === "1"
                                  ? "cursor-pointer StatusBtn canceledStatus"
                                  : item?.admin_response === "2"
                                  ? "cursor-pointer StatusBtn approvedStatus"
                                  : "cursor-pointer"
                              }
                              title="Update Status"
                              onClick={() => {
                                setReqId(item?.id);
                                setModalName("UpdateStatus");
                                handleShow();
                              }}
                            >
                              {item?.admin_response === "0"
                                ? "Pending"
                                : item?.admin_response === "1"
                                ? "Processing"
                                : item?.admin_response === "2"
                                ? "Delivered"
                                : "Select Status"}
                            </span>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center action">
                              <button className="btn" title="View">
                                <Link
                                  to={`/repair-estimates-service-requests/${item?.id}`}
                                >
                                  <img src="assets/images/view.png" alt="" />
                                </Link>
                              </button>
                              {/* {item?.quotation_pdf === null ? (
                                <button
                                  className="btn"
                                  title="Quotation"
                                  onClick={() => {
                                    setReqId(item?.id);
                                    setModalName("AddQuotation");
                                    handleShow();
                                  }}
                                >
                                  <img
                                    src="assets/images/Quotation.png"
                                    alt=""
                                  />
                                </button>
                              ) : (
                                 <a
                                  href={item?.quotation_pdf}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <button
                                    className="btn "
                                    title="Quotation Added"
                                  >
                                    <img src="assets/images/yes.png" alt="" />
                                  </button>
                                </a>
                              )} */}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="11">No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {data?.length > 0 && (
            <Paginations
              handlePage={handlePage}
              page={page}
              total={total && total}
            />
          )}
        </div>
        {show && modalName === "AddQuotation" && (
          <AddQuotation
            show={show}
            handleShow={handleShow}
            ReqId={ReqId}
            getList={getlist}
          />
        )}
        {show && modalName === "UpdateStatus" && (
          <UpdateStatus
            show={show}
            handleShow={handleShow}
            ReqId={ReqId}
            getList={getlist}
          />
        )}
      </article>
    </>
  );
}

export default RepairEstimatesServices;
