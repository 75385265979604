import React from "react";
import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import currencies from "currency-formatter";
import { UserDetailReducer } from "../features/users/UserSlice";

function UserModal({ show, handleShow, id }) {
  const dispatch = useDispatch();
  const detail = useSelector((store) => store.User.details);
  //console.log(detail);

  const getdetail = useCallback(() => {
    dispatch(
      UserDetailReducer({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);
  return (
    <Modal
      show={show}
      onHide={() => {
        handleShow();
      }}
      centered
      size="xl"
    >
      <Modal.Body>
        <div className="" role="document">
          <div className="">
            <div className="table-responsive">
              <table className="table storetable text-center">
                <thead className="">
                  <tr>
                    <th scope="col">image</th>
                    <th scope="col">first name</th>
                    <th scope="col">last name</th>
                    <th scope="col">email</th>
                    <th scope="col">mobile no</th>
                    <th scope="col">profile percentage</th>
                    <th scope="col">No. of Cars</th>
                    <th scope="col">User type</th>
                    <th scope="col">location</th>
                    <th scope="col">Create At</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        src={
                          detail?.professional_social_details?.profile_pic?.includes(
                            "http"
                          )
                            ? detail?.professional_social_details?.profile_pic
                            : "assets/images/employe.png"
                        }
                        className="table-img"
                        alt=""
                      />
                    </td>
                    <td>
                      {detail?.personal_contact_details?.first_name || "NA"}
                    </td>
                    <td>
                      {detail?.personal_contact_details?.last_name || "NA"}
                    </td>
                    <td className="text-inherit">
                      {detail?.personal_email || "NA"}
                    </td>
                    <td>
                      {detail?.personal_contact_details?.mobile
                        ? `${detail?.personal_contact_details?.countryCode} ${detail?.personal_contact_details?.mobile}`
                        : "NA"}
                    </td>
                    <td>
                      {`${detail?.personal_contact_details?.profile_complete_percentage}%` ||
                        "NA"}
                    </td>
                    <td>
                      {detail?.personal_contact_details?.no_of_cars_can_add ||
                        "NA"}
                    </td>
                    <td>
                      {detail?.personal_contact_details?.user_type}
                      {detail?.personal_contact_details?.user_type &&
                      detail?.personal_contact_details?.canAddOthersCar
                        ? ", "
                        : ""}
                      {detail?.personal_contact_details?.canAddOthersCar
                        ? "Mechanic"
                        : ""}
                    </td>
                    <td>{detail?.personal_contact_details?.address || "NA"}</td>
                    <td>
                      {detail?.personal_contact_details?.createdAt
                        ? moment(
                            detail?.personal_contact_details?.createdAt
                          ).format("DD/MM/YYYY")
                        : "NA"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserModal;
