import React from 'react';

const DownloadButton = ({ imageUrl, imageName }) => {

  
  const handleDownload = async () => {
    try {

      const response = await fetch(imageUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Cache-Control': 'no-cache'
        },
        mode: 'cors'
      });
      console.log(response);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const buffer = await response.arrayBuffer();
      const blob = new Blob([buffer], { type: "octet/stream" });
      const url = window.URL.createObjectURL(blob);
      console.log(url);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", imageName);
      document.body.appendChild(link);
      link.click();

      // clear url
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("Failed to download image:", error);
    }


  };

const buttonStyle = {
  backgroundColor: '#FEC00F', /* Green background */
  border: 'none',
  color: 'white', /* White text */
  padding: '10px 20px', /* Padding */
  textAlign: 'center', /* Center text */
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px', /* Font size */
  margin: '4px 2px', /* Margin */
  cursor: 'pointer', /* Pointer cursor on hover */
  borderRadius: '12px' /* Rounded corners */
};

  return (
    <button style={buttonStyle} onClick={handleDownload}>
    <img src="\assets\images\download.png" alt="Download Icon" style={{ width: '20px', marginRight: '10px' }} />
    Download
  </button>
  );
};

export default DownloadButton;
