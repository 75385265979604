import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { togglesuccess } from "../features/login/LoginSlice";
import { UpdateStatusReducer } from "./UpdateStatusSlice";

function UpdateStatus({ show, handleShow, ReqId, getList }) {
  const dispatch = useDispatch();
  const success = useSelector((store) => store.login.success);
  const [error, setError] = useState("");

  const initialValues = {
    request_id: ReqId,
    status: "",
  };

  const UpdateStatus = (values) => {
    //console.log(values);
    dispatch(
      UpdateStatusReducer({
        request_id: ReqId,
        status: values.status,
      })
    ).then((data) => {
      // console.log(data);
      if (data?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(data?.payload?.message);
        setTimeout(() => {
          setError("");
          handleShow();
          getList();
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(data?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 1000);
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleShow}>
        <Modal.Header>
          <h5 className="modal-title">Update Status</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleShow}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                status: Yup.string().required("Required"),
              })}
              enableReinitialize
              onSubmit={(values, { isSubmitting, resetForm }) => {
                //console.log(values);
                UpdateStatus(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFormikState,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="form-label">Status</label>
                    <select
                      className="form-control"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur("status")}
                    >
                      <option value="">Select Status</option>
                      <option value="0">Pending</option>
                      <option value="1">Processing</option>
                      <option value="2">Delivered</option>
                    </select>
                    {errors.status && touched.status ? errors.status : null}
                  </div>
                  <div className="text-center my-2">
                    <button type="submit" className="btn btn-accept btn-view ">
                      Submit
                    </button>
                  </div>
                  {error && (
                    <div
                      className={
                        success
                          ? "alert alert-success text-center"
                          : "alert alert-danger text-center"
                      }
                    >
                      {error}
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpdateStatus;
