import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { AddNoCarsReducer } from "../features/users/UserSlice";
import { togglesuccess } from "../features/login/LoginSlice";

function AddCarsNoByUser({ show, handleShow, ReqId, getList, noCars }) {
  const dispatch = useDispatch();
  const success = useSelector((store) => store.login.success);
  const [error, setError] = useState("");

  const initialValues = {
    request_id: ReqId,
    noCars: noCars ? noCars : "",
  };

  const UpdateStatus = (values) => {
    //console.log(values);
    dispatch(
      AddNoCarsReducer({
        user_id: ReqId,
        no_of_cars_can_add: values.noCars,
      })
    ).then((data) => {
      // console.log(data);
      if (data?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(data?.payload?.message);
        setTimeout(() => {
          setError("");
          handleShow();
          getList();
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(data?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 1000);
      }
    });
  };
  return (
    <>
      <Modal show={show} onHide={handleShow}>
        <Modal.Header>
          <h5 className="modal-title">Add No of Cars</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleShow}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                noCars: Yup.number()
                  .min(1, "Minimum Value is 1")
                  .required("Required"),
              })}
              enableReinitialize
              onSubmit={(values, { isSubmitting, resetForm }) => {
                //console.log(values);
                UpdateStatus(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFormikState,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="form-label">Enter no of Cars</label>
                    <input
                      className="form-control"
                      name="noCars"
                      value={values.noCars}
                      onChange={handleChange}
                      onBlur={handleBlur("noCars")}
                    />
                    {errors.noCars && touched.noCars ? errors.noCars : null}
                  </div>
                  <div className="text-center my-2">
                    <button type="submit" className="btn btn-accept btn-view ">
                      Submit
                    </button>
                  </div>
                  {error && (
                    <div
                      className={
                        success
                          ? "alert alert-success text-center"
                          : "alert alert-danger text-center"
                      }
                    >
                      {error}
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddCarsNoByUser;
