import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../../../config/index";

const BASE_URL = SERVER_URL;

//console.log(SERVER_URL);

const initialState = {
  total: 0,
  RTOServices: [],
  details: {},
};
export const RTOServicesListingsReducer = createAsyncThunk(
  "RTOServicesListingsReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/alliedService/getAllAlliedServiceRequest`,
        {
          params: {
            search: obj.search,
            page: obj.page,
            limit: obj.limit,
            service_id: obj.service_id,
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const RTOServicesDetailReducer = createAsyncThunk(
  "RTOServicesDetailReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/alliedService/getAlliedServiceRequestDetails`,
        {
          params: {
            request_id: obj.id,
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);
export const RTOServiceslistingSlice = createSlice({
  name: "RTOServiceslistings",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [RTOServicesListingsReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.RTOServices = action?.payload?.data?.items;
      state.total = action?.payload?.data?.total;
    },
    [RTOServicesDetailReducer.fulfilled]: (state, action) => {
      //console.log(action);
      state.details = action?.payload?.data?.request;
    },
  },
});

export const { reducerName } = RTOServiceslistingSlice.actions;

export default RTOServiceslistingSlice.reducer;
