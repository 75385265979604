import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../../config/index";

const BASE_URL = SERVER_URL;

const initialState = {
  total: 0,
  requirement: [],
  details: {},
  allUserPrefList : [],
};
export const RequirementUserReducer = createAsyncThunk(
  "RequirementUserReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/admin/requirementUserList`, {
        params: {
          search: obj.search,
          page: obj.page,
          limit: obj.limit,
          start_date: obj.startDate,
          end_date: obj.endDate
          //user_type: obj.user_type,
        },
        headers: {
          token: token,
        },
      });
      // console.log("Slice Data",{response});
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const RequirementUserListCSVReducer = createAsyncThunk(
  "RequirementUserListCSVReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/admin/requirementUserListCSV`, {
        params: {
          start_date: obj.startDate,
          end_date: obj.endDate
        },
        headers: {
          token: token,
        },
      });
      // console.log("Slice list Data",{response});
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const UserRequirementDetailReducer = createAsyncThunk(
  "UserRequirementDetailReducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/getUserRequirementDetails`,
        {
          params: {
            pref_id: obj.id,
          },
          headers: {
            token: token,
          },
        }
      );
      //console.log("slice data",response)
      // await localStorage.setItem("token", response?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);



export const RequirementSlice = createSlice({
  name: "Requirement",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [RequirementUserReducer.fulfilled]: (state, action) => {
      //console.log(action)
      state.requirement = action?.payload?.data?.user_list;
      state.total = action?.payload?.data?.total_user;
    },
    [RequirementUserListCSVReducer.fulfilled]: (state, action) => {
      //console.log(action)
      
      state.allUserPrefList = action?.payload?.data.all_user_pref_list;
    },
    [UserRequirementDetailReducer.fulfilled]: (state, action) => {
      //console.log(action)
      state.details = action?.payload?.data.user_pref_details;
    },
  },
});

export const { reducerName } = RequirementSlice.actions;

export default RequirementSlice.reducer;
