import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../../config/index";

const BASE_URL = SERVER_URL;
//console.log(SERVER_URL);

const initialState = {
  email: "",
  success: false,
};
export const loginreducer = createAsyncThunk(
  "loginreducer",
  async (obj, { getState }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/login`, {
        params: {
          email: obj.email,
          password: obj.password,
        },
      });

      await localStorage.setItem("token", response?.data?.data?.token);
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);

export const forgotPasswordreducer = createAsyncThunk(
  "forgotPasswordreducer",
  async (obj, { getState }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/forgotPassword`, {
        params: {
          email: obj.email,
        },
      });
      //console.log(response)

      // await localStorage.setItem("token", response?.data?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);
export const OTPVerificationdreducer = createAsyncThunk(
  "OTPVerificationdreducer",
  async (obj, { getState }) => {
    //console.log(getState().login.email)

    // const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/admin/verifyOtp`, {
        params: {
          code: obj.code,
          email: getState().login.email,
        }
      });
      //console.log(response)
      await localStorage.setItem("token", response?.data?.data?.token);
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);
export const ResetPasswordreducer = createAsyncThunk(
  "ResetPasswordreducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      const response = await axios.put(`${BASE_URL}/admin/resetPassword`, obj, {
        headers: {
          token: token,
        },
      });
      //console.log(response)
      await localStorage.setItem("token", response?.data?.data?.token);
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);
export const ChangePasswordreducer = createAsyncThunk(
  "ChangePasswordreducer",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");
    try {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      const response = await axios.put(
        `${BASE_URL}/admin/changePassword`,
        formData,
        {
          headers: {
            token: token,
          },
        }
      );
      //console.log(response)
      await localStorage.setItem("token", response?.data?.data?.token);
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);
export const ResendOTPReducer = createAsyncThunk(
  "ResendOTPReducer",
  async (obj, { getState }) => {
    // const token = await localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/admin/resendOtp`, {
        params: {
          email: getState().login.email,
        },
      });
      //console.log(response)
      //  await localStorage.setItem("token", response?.data?.data?.token)
      return response?.data;
    } catch (error) {
      return error?.message;
    }
  }
);
export const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    togglesuccess: (state, action) => {
      //console.log(action);
      state.success = action?.payload;
    },
  },
  extraReducers: {
    [forgotPasswordreducer.fulfilled]: (state, action) => {
      // console.log(action)
      if (action?.payload?.code === 1) {
        state.email = action?.meta?.arg?.email;
      }
    },
  },
});

export const { togglesuccess } = LoginSlice.actions;

export default LoginSlice.reducer;
